import React, {useEffect, useState} from 'react';
import { Box, IconButton, Tooltip, MenuItem } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from "prop-types";
import DeleteFolderModal from "../../modal/pixDeleteFolderModal";
import CustomPopover, {usePopover} from "../custom-popover";
import Iconify from "../iconify";
import Image from "../image";
import {useBoolean} from "../../hooks/use-boolean";

const ImageInSharing = ({
                          setSizeId,
                          image,
                          idx,
                          sharingMeta,
                          setIndex,
                          handleDownload,
                          setFilesLiked,
                          filesLiked,
                          fData,
                          allowLike = false, // default value for allowLike
                          // allowDownload = []  // array od formats, which are allowd

                        }) => {
  const popover = usePopover();
  const [liked, setLiked] = useState(false);
// console.log(`${sharingMeta.s3_endpoint_preview}t_${image.filename}`)
  const toggleLiked = (fileid) => {
    setFilesLiked(prevFilesLiked => {
      if (prevFilesLiked.includes(fileid)) {
        return prevFilesLiked.filter(id => id !== fileid); // Remove fileid if it's already liked
      }
        return [...prevFilesLiked, fileid]; // Add fileid if it's not liked

    });
  };
/*
  useEffect(() => {
    console.log('useeffect imagin',sharingMeta)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/
  const isLiked = filesLiked.includes(image.fileid);
  return (

    <Box sx={{ position: 'relative' }}>
      <Image
        key={image.fileid}
        alt={image.ratio}
        src={`${sharingMeta.s3_endpoint_preview}t_${image.filename}`}
        sx={{ borderRadius: 2, cursor: 'pointer' }}
        onClick={() => setIndex(idx)}
        ratio="1/1"
      />
      {allowLike&&
      <IconButton
        aria-label="delete"
        onClick={() => toggleLiked(image.fileid) }
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          color: 'white'
        }}
      >
        <Tooltip title={isLiked ? "Unlike" : "Like"}>
          {isLiked ? (
            <Iconify icon="weui:like-filled" sx={{ color: "#ff5e5e" }} />
          ) : (
            <Iconify icon="weui:like-outlined" />
          )}
        </Tooltip>
      </IconButton>}
      {!!sharingMeta.allowDownload?.length&&
      <IconButton
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
        style={{
          position: 'absolute',
          bottom: 5,
          right: 5,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          color: 'white'
        }}
      >
        <Tooltip title="Stiahnuť...">
          <Iconify icon="material-symbols:download" />
        </Tooltip>
      </IconButton>}
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 160 }}>
        {sharingMeta.allowDownload.includes(2)&&(parseInt(image.filesize_1318, 10) > 0) && (
          <MenuItem
            onClick={() => {
              popover.onClose();
              // setSizeId(2);
              handleDownload(image.fileid,2);
            }}
            title={fData(image.filesize_1318)}
          >
            <DownloadIcon />
            {image.res_1318}
          </MenuItem>
        )}
        {sharingMeta.allowDownload.includes(3)&&(parseInt(image.filesize_a4, 10) > 0) && (
          <MenuItem
            onClick={() => {
              popover.onClose();
              // setSizeId(3);
              handleDownload(image.fileid,3);
            }}
            title={fData(image.filesize_a4)}
          >
            <DownloadIcon />
            {image.res_a4}
          </MenuItem>
        )}
        {sharingMeta.allowDownload.includes(4)&&(parseInt(image.filesize_full, 10) > 0) && (
          <MenuItem
            onClick={() => {
              popover.onClose();
              // setSizeId(4);
              handleDownload(image.fileid,4);
            }}
            title={fData(image.filesize_full)}
          >
            <DownloadIcon />
            {image.res_full}
          </MenuItem>
        )}
      </CustomPopover>
    </Box>
  );
};

export default ImageInSharing;
ImageInSharing.propTypes = {

  image: PropTypes.object,
  idx: PropTypes.number,
  sharingMeta: PropTypes.object,
  setIndex: PropTypes.func,
  handleDownload: PropTypes.func,
  fData: PropTypes.func,
  setSizeId: PropTypes.func,
  setFilesLiked: PropTypes.func,
  filesLiked: PropTypes.array,
  allowDownload: PropTypes.array,
  allowLike: PropTypes.bool
};
