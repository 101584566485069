import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {useAxStore} from "../store/axStore";
import {RHFTextField} from "../components/hook-form";
import Iconify from "../components/iconify";
import {useSnackbar} from "../components/snackbar";
import {useBoolean} from "../hooks/use-boolean";
import axios from "../utils/axios";

const ChangeUserPasswModal = ({open, setOpen,userid}) => {

  const axStore=useAxStore();

  const { enqueueSnackbar } = useSnackbar();

  const password = useBoolean(false);
  const [showPassword,setShowPassword] = useState(false);
  const [passw1,setPassw1] = useState('');
  const [passw2,setPassw2] = useState('');
  const [passwMessage1,setPasswMessage1] = useState('');
  const [passwMessage2,setPasswMessage2] = useState('');
  const [passwMessage3,setPasswMessage3] = useState('');

  const paswword1IsStrong = useBoolean(false);
  const paswword2IsStrong = useBoolean(false);
  const paswwordsAreTheSame = useBoolean(false);
  const paswwordsAreOk = useBoolean(false);
  const passwordIsChanging = useBoolean(false);

  const handleSubmit = async () => {
  if(!passwordIsChanging.value) {
    passwordIsChanging.onTrue()
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {

      const postValues = new FormData();
      postValues.append('newPassw', passw1);
      postValues.append('uid', userid); // user id to whom are we going to change the passw
      postValues.append('action', 'change_password'); // user id to whom are we going to change the passw


      axios.post('https://api.administrix.pro/v1/post/',
        postValues, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.status === 1) {

                enqueueSnackbar("Výborne, heslo užívateľa sme zmenili", {variant: `success`, autoHideDuration: 6000});
                setPasswMessage3("Výborne, heslo užívateľa sme zmenili");
              } else {
                enqueueSnackbar("Bohužiaľ, užívateľovi sa nepodarilo zmeniť heslo.", {variant: `error`, autoHideDuration: 6000});
                setPasswMessage3("Bohužiaľ, užívateľovi sa nepodarilo zmeniť heslo.");
              }


          } else if (response.data.error) {
//            const {error} = response.data;
            enqueueSnackbar(`Chyba pri zmene hesla dát: ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});
            console.log('picxa hir')
          }

        }))
        .catch(error => {
          console.log("any changing passw  fetch api error");

        })

    }
    passwordIsChanging.onFalse()
  }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(()=> {

    if(passw1===passw2) {
      if (paswword1IsStrong.value && paswword2IsStrong.value) {
        paswwordsAreOk.onTrue()
        setPasswMessage3(' Výborne,môžete zmeniť heslo.');
      } else {
        setPasswMessage3('');
        paswwordsAreOk.onFalse()
      }
    }else {
      setPasswMessage3(' Heslá nie sú totožné.');
      paswwordsAreOk.onFalse();
      paswwordsAreTheSame.onFalse()
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passw1, passw2])

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange1 = (event) => {
    setPassw1(event.target.value);
    const pass1=event.target.value;


    if(isStrongPassword(pass1)){
      setPasswMessage1('Heslo je silné.');
      paswword1IsStrong.onTrue()
    }else{
      setPasswMessage1('Heslo nespĺňa požadované kritériá.');
      paswword1IsStrong.onFalse()
    }

  };
  const handleChange2 = (event) => {
    setPassw2(event.target.value);

    const pass2=event.target.value;
    if(isStrongPassword(pass2)){
      setPasswMessage2('Heslo je silné.');
      paswword2IsStrong.onTrue()
    }else{
      setPasswMessage2('Heslo nespĺňa požadované kritériá.');
      paswword2IsStrong.onFalse()
    }
  };

  function isStrongPassword(passwordValue) {
    // Define regex patterns for different character types
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    // const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Check length
    if (passwordValue.length < 8) {
      return false; // Password is too short
    }

    // Check for presence of required character types
    if (!uppercaseRegex.test(passwordValue) ||
      !lowercaseRegex.test(password) ||
      !numberRegex.test(passwordValue))  {
      return false; // Missing required character types
    }
    // Passed all checks
    return true;
  }

  return (

    <Dialog open={open}  maxWidth="sm" fullWidth>
        <DialogTitle>
          Zmena užívateľského hesla
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>

            <p>Zadajte nové heslo a potom ho pre kontrolu zadajte ešte raz.<br/><br/>
              <strong>Heslo musí obsahovať:</strong>
              <ul>
                <li>minimálne <strong>8 znakov</strong>,</li>
                <li>aspoň jedno <strong>veľké písmeno</strong>,</li>
                <li>aspoň jedno <strong>malé písmeno</strong>,</li>
                <li>aspoň jednu <strong>číslicu</strong>.</li>
              </ul>
              <strong>Odporúčame pridať:</strong>
              <ul>
              <li><a href="https://www.owasp.org/index.php/Password_special_characters" target="_blank" rel="noreferrer">aspoň jeden špeciálny znak</a> .</li>
              </ul>
          </p>


          <Grid sm={12}>
            <FormControl sx={{m: 1, width: "100%"}} variant="outlined" sm>
              <InputLabel htmlFor="outlined-adornment-password">Heslo</InputLabel>
              <OutlinedInput
                id="passw1"
                name="passw1"
                value={passw1}
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange1}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Heslo"
              />
              <FormHelperText id="filled-weight-helper-text" style={{color:paswword1IsStrong.value ? '#0f6e0c' : '#FF0000'}}>{passwMessage1}</FormHelperText>
            </FormControl>
</Grid>
  <Grid sm={12}>
<FormControl sx={{ m: 1,width:"100%" }} variant="outlined" >
              <InputLabel htmlFor="outlined-adornment-password">Opakujte heslo ešte raz</InputLabel>
              <OutlinedInput
                id="passw2"
                name="passw2"
                value={passw2}
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange2}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Opakujte heslo ešte raz"
              />
            <FormHelperText id="filled-weight-helper-text" style={{color:paswword2IsStrong.value ? '#0f6e0c' : '#FF0000'}}>{passwMessage2}</FormHelperText>
            </FormControl>

    <Typography id="filled-weight-helper-text" style={{width:"100%",margin:"10px",textAlign:"center",color:paswwordsAreOk.value ? '#0f6e0c' : '#FF0000'}}>{passwMessage3}</Typography>
  </Grid>
          </DialogContentText>
        </DialogContent>
      <DialogActions>


        {paswwordsAreOk.value&&<LoadingButton
          /* type="submit" */
           onClick={handleSubmit}
          variant="contained"
          size="large"
          // loading={isSubmitting}
          startIcon={<PersonAddIcon/>}
          sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
        >
          Zmeň heslo
        </LoadingButton>
        }


        <Button variant="outlined" onClick={()=>setOpen(false)}>
          Zatvor
        </Button>
      </DialogActions>
      </Dialog>
  );
};
ChangeUserPasswModal.propTypes = {
  open: PropTypes.bool,
  userid:PropTypes.number,
  setOpen:PropTypes.func

};
export default ChangeUserPasswModal;
