import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// hooks
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import UserQuickEditForm from "../../sections/user/user-quick-edit-form";
import {useBoolean} from "../../hooks/use-boolean";
import DepartmentQuickEditForm from "../../sections/department/department-quick-edit-form";
import {useAxStore} from "../../store/axStore";
import AxPixmeQuickEditForm from "../../sections/department/axpixme-quick-edit-form";

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Úvodná strana',
    linkTo: '/dashboard',
    fromDepRole:3 // soldier
  },
  {
    label: 'Profil',
    linkTo: paths.dashboard.user.profile,
    fromDepRole:1 // site admin
  },
  {
    label: 'Faktúry',
    linkTo: paths.dashboard.user.account,
    fromDepRole:1 // site admin

   },

];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const axStore=useAxStore()
  const user=axStore.axCurrentUser

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      localStorage.removeItem('axjwt');
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const runOnClose = () => {

  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  // *****user states
  const[userToEdit,setUserToEdit]=useState({})
  const[userIdToEdit,setUserIdToEdit]=useState(0)
  const[userOldIdToEdit,setUserOldIdToEdit]=useState(0)
  const userQuickEditModal = useBoolean(false);

  const { t, onChangeLang } = useTranslation()
  /*  departmen quick edit modal */
  const[depToEdit,setDepToEdit]=useState({})
  const[depIdToEdit,setDepIdToEdit]=useState(0)
  const[depOldIdToEdit,setDepOldIdToEdit]=useState(0)
  const depQuickEditModal = useBoolean(false)

  /* administrix setting modal */
  const isOpenAxSettingModal=useBoolean(false);
  const handleCloseAxSettModal = () => {

    isOpenAxSettingModal.onFalse()

  }
// console.log('curuser:',user)

  return (
    <>
      <UserQuickEditForm userIdToEdit={userIdToEdit} currentUserAsProp={userToEdit} setUserOldIdToEdit={setUserOldIdToEdit} setUserIdToEdit={setUserIdToEdit} userOldIdToEdit={userOldIdToEdit} open={userQuickEditModal.value} onClose={userQuickEditModal.onFalse} runOnClose={runOnClose} isAxUser  />

      <DepartmentQuickEditForm depIdToEdit={parseInt(depIdToEdit,10)} currentDepAsProp={depToEdit} setDepOldIdToEdit={setDepOldIdToEdit} setDepIdToEdit={setDepIdToEdit} depOldIdToEdit={depOldIdToEdit} open={depQuickEditModal.value} onClose={depQuickEditModal.onFalse}  />

      <AxPixmeQuickEditForm open={isOpenAxSettingModal.value} onClose={handleCloseAxSettModal} />

      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={axStore.systemCurrentConfig.s3.s3_endpoint_system+user.avatar_url}
          alt={user.full_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary',fontSize:"0.6em" }} noWrap>
            {(user?.ax_role===0)&&(<>Ultraadmin</>)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
        <MenuItem onClick={() => {setUserIdToEdit(user.id);userQuickEditModal.setValue(true)}}>
          {t('my_profile')}
        </MenuItem>

        {((user.ax_role===0)||(user.department_role===1))&&<MenuItem onClick={() => {setDepIdToEdit(axStore.axCurrentDepartment.id);depQuickEditModal.setValue(true)}}>
          {t('department_settings')}
        </MenuItem>}

        {(user.ax_role<2)&&<MenuItem onClick={() => {isOpenAxSettingModal.onTrue()}}>
           aX / piXme settings
        </MenuItem>}


        </Stack>


        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('logout')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
