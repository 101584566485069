import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useForm,useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import {_roles, _tags, USER_STATUS_OPTIONS} from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {RHFSelect, RHFTextField, RHFAutocomplete, RHFEditor, RHFUpload, RHFSwitch, RHFCheckbox} from 'src/components/hook-form';
import {AccordionDetails, Autocomplete, CircularProgress, LinearProgress, TableCell, TextField, Tooltip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from '@mui/icons-material/Link';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import Zoom from "@mui/material/Zoom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DangerousIcon from '@mui/icons-material/Dangerous';
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';
import Card from "@mui/material/Card";
import {QueuePlayNext} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {Select} from "@mui/base";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import {useAxStore} from "../../store/axStore";
import axios from "../../utils/axios";
import {useResponsive} from "../../hooks/use-responsive";
import ConfirmDepartmentDelete from "../department/ConfirmDepartmentDelete";
import ConfirmCompanyDelete from "./ConfirmCompanyDelete";
import TextMaxLine from "../../components/text-max-line";

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'zakladne-udaje',
    label: 'Základné údaje',
    icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
  },
  {
    value: 'adresy-kontakty',
    label: 'Adresy a kontakty',
    icon: <Iconify icon="solar:money-bag-bold" width={24} />,
  },
  {
    value: 'faktury',
    label: 'Faktúry',
    icon: <Iconify icon="solar:money-bag-bold" width={24} />,
  },
  {
    value: 'posli-email',
    label: 'Pošli email',
    icon: <Iconify icon="material-symbols:mail" width={24} />,
  },{
    value: 'dokumenty',
    label: 'Dokumenty',
    icon: <Iconify icon="solar:documents-bold" width={24} />,
  },
];
// ----------------------------------------------------------------------

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k**i).toFixed(dm))  } ${  sizes[i]}`;
}
function ConvertToNiceDate(mysql_timestamp) {
  // console.log('ConvertToNiceDate working with ',mysql_timestamp);
  if((mysql_timestamp!=="0000-00-00 00:00:00")&&(mysql_timestamp!=="0000-00-00")) {
    const optionsDate = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
    };
    const optionsDay = {
      weekday: 'long'
    };

    const dateFormatter = new Intl.DateTimeFormat('sk-SK', optionsDate);
    const timeFormatter = new Intl.DateTimeFormat('sk-SK', optionsTime);
    const dayFormatter = new Intl.DateTimeFormat('sk-SK', optionsDay);
    const timestamp_created = new Date(mysql_timestamp);
    // return dateFormatter.format(timestamp_created);

    // eslint-disable-next-line no-unreachable
    const niceDateObject={
      niceDate:dateFormatter.format(timestamp_created),
      niceTime: timeFormatter.format(timestamp_created),
      niceday: dayFormatter.format(timestamp_created)
    }
    return niceDateObject
  }return false;
} // returns an object with date, time, day_in_week_by_name

export default function CompanyQuickEditForm({ compIdToEdit, setCompIdToEdit, open, onClose,currentCompAsProp, setCompOldIdToEdit, compOldIdToEdit,loadComps,companyOfDepId,isDepartmentMainCompany  }) {
  //  isDepartmentMainCompany: 1=mycompany, 2 partners company
  const mdUp = useResponsive('up', 'md');
  const [paymentOverview,setPaymentOverview] = useState({})

  const [newCompCheckboxLabel,setNewCompCheckboxLabel]=useState('')
  const [newCompIcoLabel,setNewCompIcoLabel]=useState('')
  const [newNoCompNameLabel,setNewNoCompNameLabel]=useState('')
  const [newEmptyCompButtonLabel,setNewEmptyCompButtonLabel]=useState('')
  const [newEmptyNonCompButtonLabel,setNewEmptyNonCompButtonLabel]=useState('')

  const [invoiceDates,setInvoiceDates] = useState([])
  const [deleteCompConfirmModalIsOpen,setDeleteCompConfirmModalIsOpen] = useState(false)
  const [currentComp,setCurrentComp] = useState({})
  const [compsLoading,setCompsLoading] = useState(false)
  const [isInvoiceDatesCounting,setIsInvoiceDatesCounting] = useState(true)
  const [currentCompAllLoadedData,setCurrentCompAllLoadedData] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState(false)
  const [linkingToCompany, setLinkingToCompany] = useState(false)
  const [companies, setCompanies] = useState([])
  const [companyContacts, setCompanyContacts] = useState([])
  const [companyAddresses, setCompanyAddresses] = useState([])
  const [companyInvoices, setCompanyInvoices] = useState([])
  const [isInvEmailSwitchStates, setIsInvEmailSwitchStates] = useState([])
  const [isDeletingContact, setIsDeletingContact] = useState(false)
  const [isInsertingContact, setIsInsertingContact] = useState(false)
  const [isSettingToInvCont, setIsSettingToInvCont] = useState(false)
  const [isGettingCompInfoByIco, setIsGettingCompInfoByIco] = useState(false)
  const [newCompanyDataFromApi, setNewCompanyDataFromApi] = useState({})
  const [lastGCIFAmessage, setLastGCIFAmessage] = useState('')
  const [newContactType, setNewContactType] = useState(1)
  const [newContactValue, setNewContactValue] = useState('')
  let contactsInvChecked=[]

  const [dialogWidth, setDialogWidth] = useState("md")

  const axStore=useAxStore()
  const autocomplete=true
  const [curCompSelectbox,setCurCompSelectbox]=useState('');
  const [isSwitchingComp,setIsSwitchingComp]=useState(false);
  const [isSavingCompData,setIsSavingCompData]=useState(false);
  const [compNameSwitchingTo,setCompNameSwitchingTo]=useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);



  const currentYearForSel = new Date().getFullYear();

  // Generate an array of 10 options
  const yearOptions = Array.from({ length: 10+(currentYearForSel-2018) }, (_, index) => {
    const year = 2017 + index;
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  });
  const [currentTab, setCurrentTab] = useState('zakladne-udaje');
  const handleChangeContactType = (event) => {
    setNewContactType(event.target.value)
  }
  const handleChangeContactValue = (event) => {
    setNewContactValue(event.target.value)
  }
  const handleCloseModal = () => {
    // enqueueSnackbar('closing')
    if(loadComps)loadComps()
    onClose()
  }
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const onError = (errors, e) => {
    enqueueSnackbar("Chyba pri validácii, chck console",{ variant: `error`,autoHideDuration: 6000 });
    console.log('submit validation error:',errors, e)
  }

  const switchToComp = (value) => {  // value has id and web label
// console.log('switching to ',value.id)
    // alert(`vybrali ste ${value.label} a idem smerovat do ${value.id}`);
    setIsSwitchingComp(true)
    setCompNameSwitchingTo(value.label)

    setCompIdToEdit(parseInt(value.id,10))
    loadCompDataToEdit(parseInt(value.id,10))
  }
  const handleOptionSelectedCompClick = (event,value, reason) => {
     /* console.log("onchange comp from company modal edit")
     console.log(event)
     console.log(value)
     console.log(reason) */
    if (reason === 'selectOption') switchToComp(value)
  };

  const loadCompsToAxStore = () => {
    setCompsLoading(true)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_companies'
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if (data.companies) {
              axStore.setAxCompanies(data.companies)
            }
          }else
          if (response.data.error) {
            const {error} = response.data;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }

          setCompsLoading(false)

        }))
        .catch(error => {
          setCompsLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
    }





  }

  const NewUserSchema = Yup.object().shape({
    depname: Yup.string().when('compIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.required('Názov departmentu je povinná položka'),
      otherwise: schema => schema.notRequired()
    }),
    /* description: Yup.string().when('compIdToEdit', {
       is: depId => depId !== "0",
      // is: true,
      then: schema=>schema.required('Popis departmentu je povinná položka'),
      otherwise: schema => schema.optional()
    }), */
    website_canonic_url: Yup.string().when('compIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: schema => schema.notRequired()
    }),
    /* website_canonic_url: Yup.string().when('compIdToEdit', {
      is: depId => depId > 0,
      then: Yup.string().url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: Yup.string().nullable()
    }) */
    // name: Yup.string().required('Názov departmentu je povinná položka'),
    // website_canonic_url: Yup.string().url('Táto položká musí byť v tvare webovej adresy, napr. https://pixme.sk').nullable(),
    // description: Yup.string().required('Name is required'),
    /* email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    role: Yup.string().required('Role is required'), */
  });

  const defaultValues = useMemo(
    () =>({
      /* -------------- NOVA FIRMA  ----------------*/
        name_new:'',
        description_new:'',
        ico_new:'',
        is_company_partner_new:1,
       /* -------------- ZAKLADNE NASTAVENIA  ----------------*/
        compname: currentComp && currentComp?.company?.name ? currentComp?.company?.name : '',
      is_vat_payer: !!(currentComp && currentComp.company?.is_vat_payer && currentComp.company?.is_vat_payer === "1"),
      street: currentComp && currentComp?.company?.address?.street ? currentComp?.company?.address?.street : '',
      reg_number: currentComp && currentComp?.company?.address?.reg_number ? currentComp?.company?.address?.reg_number : '',
      street_number: currentComp && currentComp?.company?.address?.street_number ? currentComp?.company?.address?.street_number : '',

      postcode: currentComp && currentComp?.company?.address?.postcode ? currentComp?.company?.address?.postcode : '',
      municipality: currentComp && currentComp?.company?.address?.municipality ? currentComp?.company?.address?.municipality : '',
      country: currentComp && currentComp?.company?.address?.country ? currentComp?.company?.address?.country : '',
      country_code: currentComp && currentComp?.company?.address?.country_code ? currentComp?.company?.address?.country_code : '',

      cin: currentComp && currentComp?.company?.cin ? currentComp?.company?.cin : '',
      tin: currentComp && currentComp?.company?.tin ? currentComp?.company?.tin : '',
      vatin: currentComp && currentComp?.company?.vatin ? currentComp?.company?.vatin : '',
      vatin_paragraph: currentComp && currentComp?.company?.vatin_paragraph ? currentComp?.company?.vatin_paragraph : '',
      registration_office: currentComp && currentComp?.company?.registration_office ? currentComp?.company?.registration_office : '',
      registration_number: currentComp && currentComp?.company?.registration_number ? currentComp?.company?.registration_number : '',
      logo: currentComp && currentComp?.company?.logo ? currentComp?.company?.logo : '',
      signature: currentComp && currentComp?.company?.signature ? currentComp?.company?.signature : '',
      admin_note: currentComp && currentComp?.company?.admin_note ? currentComp?.company?.admin_note : '',

    }),
// eslint-disable-next-line react-hooks/exhaustive-deps
    // [currentComp === null ? null : currentComp.id]
// eslint-disable-next-line react-hooks/exhaustive-deps
     [currentComp]
  );



  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    dirtyFields,
    setIsDirty,
    setValue,
    getValues,
    watch,
    control,
    formState: { isSubmitting, isDirty,touchedFields },
  } = methods;

  const isVatPayer = getValues('is_vat_payer');
  const isVatPayerLabel = isVatPayer
    ? 'Je platca DPH'
    : 'Nie je platca DPH';



  const handleCheck = (event) => {

    const contid=event.target.id.substring(4)
    // let contid=parseFloat(event.target.id.substring(4),10)

    setIsInvEmailSwitchStates((prevState) => ({
      ...prevState,
      [contid]: event.target.checked,
    }));
    changeContactToSendInv(contid,event.target.checked)
    // setChecked(event.target.checked);
  };
function readAllAxCompanies(){

    setIsGettingCompanies(true)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_companies',
        // 'depid': depId
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);


            // this is, if i want to convert id to number:
            /*
            data.companies.forEach(company => {
              company.id = parseInt(company.id, 10);
            });
             */

            setCompanies(data.companies)
            console.log('companies:',data.companies)

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní firiem: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          setIsGettingCompanies(false)
        }))
        .catch(error => {
           console.log("any get_companies fetch api error");
          // console.log(error);
          // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setIsGettingCompanies(false)
        })
    }
  }
function deleteCompanyContact(compid,contid){
  if(!isDeletingContact&&compid&&contid) {
    setIsDeletingContact(true)
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {
      const postValues = new FormData();
      postValues.append('action', 'delete_company_contact');
      postValues.append('contid', contid);
      axios.post('https://api.administrix.pro/v1/post/',
        postValues, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === 1) {
              enqueueSnackbar("Kontakt sme úspešne odstránili.", {variant: `success`, autoHideDuration: 6000});
              readCompanyContactsAndAddresses(compid)
            } else {
              enqueueSnackbar("Kontakt sa nepodarilo odstrániť", {variant: `warning`, autoHideDuration: 6000});
              console.log(response.data)
            }
          } else if (response.data.error) {
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri odstraňovaní kontaktu: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
          }
          setIsDeletingContact(false)

        }))
        .catch(error => {
          enqueueSnackbar(`Chyba pri odstraňovaní kontaktu.`, {variant: `error`, autoHideDuration: 6000});


          // console.log("any deleting comp contact fetch api error");
          setIsDeletingContact(false)
        })
    }
  }}
function insertNewCompanyContact(){
//  enqueueSnackbar('inserting new contact')
  if(!isInsertingContact) {
    setIsInsertingContact(true)
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {
      const postValues = new FormData();
      postValues.append('action', 'insert_company_contact');
      postValues.append('cont_type', newContactType);
      postValues.append('cont_value', newContactValue);
      postValues.append('compid', compIdToEdit);
      axios.post('https://api.administrix.pro/v1/post/',
        postValues, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.newContId ) {
              enqueueSnackbar("Kontakt sme úspešne pridali.", {variant: `success`, autoHideDuration: 6000});
              readCompanyContactsAndAddresses(compIdToEdit)
            } else {
              enqueueSnackbar("Kontakt sa nepodarilo pridať", {variant: `warning`, autoHideDuration: 6000});
              console.log(response.data)
            }
          } else if (response.data.error) {
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri pridávaní kontaktu: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
          }
          setIsInsertingContact(false)

        }))
        .catch(error => {
          enqueueSnackbar(`Chyba pri pridávaní kontaktu.`, {variant: `error`, autoHideDuration: 6000});


          // console.log("any deleting comp contact fetch api error");
          setIsInsertingContact(false)
        })
    }
  }}
function changeContactToSendInv(contid,setToChecked){
  if(!isSettingToInvCont&&contid) {
    setIsSettingToInvCont(true)
    const jwt = localStorage.getItem('axjwt');
    if (jwt) {
      const postValues = new FormData();
      postValues.append('action', 'change_contact_to_send_inv');
      postValues.append('set_to_checked', setToChecked);
      postValues.append('contid', contid);
      postValues.append('depid', currentComp.id);
      axios.post('https://api.administrix.pro/v1/post/',
        postValues, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === 1) {
              enqueueSnackbar("Kontakt sme úspešne nastavili.", {variant: `success`, autoHideDuration: 6000});

            } else {
              enqueueSnackbar("Kontakt sa nepodarilo nastaviť, chck console", {variant: `warning`, autoHideDuration: 6000});
              console.log(response.data)
            }
          } else if (response.data.error) {
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri nastavovaní fakturačného kontaktu: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
          }
          setIsSettingToInvCont(false)
          readCompanyContactsAndAddresses(compIdToEdit)

        }))
        .catch(error => {
       enqueueSnackbar(`Chyba pri nastavovaní fakturačného kontaktu.`, {variant: `error`, autoHideDuration: 6000});
          // console.log("any setToInv contact fetch api error");
          readCompanyContactsAndAddresses(compIdToEdit)
          setIsSettingToInvCont(false)
        })
    }
  }}
function readCompanyContactsAndAddresses(compid){
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_company_contacts_and_addresses',
         'compid': compid
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            setCompanyContacts(data.contacts)
            contactsInvChecked = data.contacts.reduce((acc, contact) => {
              acc[contact.id] = (contact.is_for_invoice_sending === "1");
              return acc;
            }, {});
            console.log("contactsInvChecked",contactsInvChecked)
            setIsInvEmailSwitchStates(contactsInvChecked)
            /*
            setIsInvEmailSwitchStates((prevAssocArray) => ({
              ...prevAssocArray,
              [row.id]: (row.is_for_invoice_sending === "1"),
            })); */
            setCompanyAddresses(data.addresses)

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní kontaktov: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }

        }))
        .catch(error => {
          enqueueSnackbar("Chyba pri načitavaní firemných kontaktov");
//           console.log("any get_company contacts fetch api error");
        })
    }
  }
function readCompanyInvoices(cin){
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_invoices_by_cin',
         'cin': cin
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            setCompanyInvoices(data)

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní faktur: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }

        }))
        .catch(error => {
          enqueueSnackbar("Chyba pri načitavaní firemných faktúr");
//           console.log("any get_company contacts fetch api error");
        })
    }
  }
// handleLinkToCompany
  function handleLinkToCompany(){
  console.log('clicked handleLinkToCompany - message from fc inside')
    setLinkingToCompany(true)

    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      const postValues = new FormData();
      postValues.append('action', 'link_company_to_dep');
      postValues.append('depid', currentComp.id);
      postValues.append('compid', selectedCompany.id);

      axios.post('https://api.administrix.pro/v1/post/',
        postValues,{
          headers: {'Authorization': `Bearer ${  jwt}`}
        } )
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if(data.success===1) {
              enqueueSnackbar("Firmu sme úspešne priradili.", {variant: `success`,autoHideDuration: 6000});
              loadCompDataToEdit(data.depid)
            }else {
              enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`,autoHideDuration: 6000});
              console.log(response.data)
            }
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri priraďovaní firmy k departmentu: ${error.title}`,{ variant: `error` ,autoHideDuration: 6000});
          }
          setLinkingToCompany(false)
        }))
        .catch(error => {
           console.log("any linkingcompany fetch api error");
          // console.log(error);
          // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setLinkingToCompany(false)
        })
    }
  }


  function postCompDataToSaveToServer(comp_data_to_save,submit_buton_type=null){
    setIsSavingCompData(true)
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      const postValues = new FormData();
      postValues.append('action', 'save_company_data');
      if(compIdToEdit===0) {
        postValues.append('compid', "0");
        postValues.append('name_new', getValues('name_new'));
        postValues.append('ico_new', getValues('ico_new'));
        postValues.append('is_company_partner_new', getValues('is_company_partner_new'));
        if(submit_buton_type==='submit_button_partner_from_api'){
          postValues.append('company_from_api',JSON.stringify(newCompanyDataFromApi))
        }

      }
      else {
        postValues.append('compid', currentComp.company.id);

        Object.entries(comp_data_to_save).forEach(([key, value]) => {
          postValues.append(key, value);
        });
      }
      axios.post('https://api.administrix.pro/v1/post/',
        postValues,{
          headers: {'Authorization': `Bearer ${  jwt}`}
        } )
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if(compIdToEdit===0) {
              if(data.newCompId){
                enqueueSnackbar("Výborne, vytvorili sme novú firmu", {variant: `success`, autoHideDuration: 6000});
                setCompIdToEdit(parseInt(data.newCompId,10))
                loadCompsToAxStore();
              }
            }
            else if (data.success === 1) {
                enqueueSnackbar("Údaje o firme sme úspešne uložili.", {variant: `success`, autoHideDuration: 6000});
                reset(comp_data_to_save)
                // loadCompDataToEdit(dep_data_to_save.depid)
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri ukladaní dát: ${error.title}`,{ variant: `error` ,autoHideDuration: 6000});
          }
          setIsSavingCompData(false)
        }))
        .catch(error => {
           console.log("any saving dep data fetch api error");
          setIsSavingCompData(false)
        })
    }
  }

const loadCompDataToEdit=(compid)=>{
  setCurrentCompAllLoadedData(false)
  if(compid>0){
    console.log("loadCompDataToEdit runned")
    setDialogWidth('xl')
    setIsLoading(true);
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_comp_edit_data',
        'compid': compid,
        'is_department_main_company': isDepartmentMainCompany,
        'company_of_dep_id': companyOfDepId // if not setted, automatically gets from users dep
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            // console.log('data get_dep_edit_data response:')
          // console.log(response)
          if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
          data.created_at_nice=ConvertToNiceDate(data.company.created_at)
          data.updated_at_nice=ConvertToNiceDate(data.company.updated_at)
          // setInvoiceDates([])
          setCurrentComp(data)
          setCurrentCompAllLoadedData(true)


          setCompOldIdToEdit(parseInt(data.id,10))
            if(data.company){
              /* console.log('set selected company:',{"id":data.company.id,"label":data.company.name,"municipality":data.company.municipality,"street":data.company.street,"street_number":data.company.street_number}) */
              /* setSelectedCompany({"id":parseInt(data.website_company.id,10),"label":data.website_company.name,"municipality":data.website_company.municipality,"street":data.website_company.street,"street_number":data.website_company.street_number}) */
              setSelectedCompany({"id":data.company.id,"label":data.company.name,"municipality":data.company.address?.municipality,"street":data.company.address?.street,"street_number":data.company.address?.street_number})

            }else{

              setSelectedCompany(null);

            }

          // if(data.companies)setCompanies(data.companies)

          setDialogWidth('xl')

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní dát o firme: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          setIsLoading(false);
          setIsSwitchingComp(false)
        }))
        .catch(error => {
          console.log("any get_dep_edit_data fetch api error");
          console.log(error);
          setIsLoading(false);
          setIsSwitchingComp(false)
          setDialogWidth('xl')
        })
    }
  }else{console.log("loadCompDataToEdit skiped")}
}
function priceDifference(periodicity=1){
  let renderedDifference=''
  let colorClss=''
  let diffSign='';
    if(paymentOverview.priceDifferencePerMonth>0) {
      diffSign = '+'
      colorClss='green'
    }
    if(paymentOverview.priceDifferencePerMonth<0) {

      colorClss='red'
    }
  renderedDifference=`<strong style="color:${colorClss}">${diffSign + (paymentOverview.priceDifferencePerMonth*periodicity).toFixed(2)}€</strong>`

  return renderedDifference

}

const watched_values = useWatch({ control,name:['is_vat_payer','ico_new','is_company_partner_new'] });


useEffect(()=> {
   // console.log("compIdToEdit:",compIdToEdit)
   if(compIdToEdit)readCompanyContactsAndAddresses(compIdToEdit)

  // eslint-disable-next-line react-hooks/exhaustive-deps
},[compIdToEdit])


useEffect(()=> {
  // console.log("payment_is_active",getValues('payment_is_active'))
  // reset(defaultValues);
  // console.log('ico_new',watched_values[1])
  // if(isPaymentActive)recalculatePaymentOverview()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[watched_values])



useEffect(()=> {
  console.log('useeffect selectedCompany')
  if(compIdToEdit===0)setDialogWidth('md');else setDialogWidth('xl')
  if(compIdToEdit) {
    console.log('company changed:', selectedCompany)
/*    if (currentComp.website_company && selectedCompany) {
      if (selectedCompany.id !== currentComp.website_company.id) console.log('vybrata nova firma'); else console.log('vybrata firma co uz bola priradena')
    } else console.log('vybrata nova firma') */
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCompany]);

/* ------- NASTAV FORMULAR HODNOTAMI, KED SA NACITAJU Z DB -------- */

useEffect(()=> {
  console.log('useeffect defaultValues')
    if(currentComp&&Object.keys(currentComp).length>0) {
       console.log("reseting form with default values:", defaultValues);
       reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[defaultValues,currentComp])
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[defaultValues])



  useEffect(()=> {
    console.log("currentComp useeffect = ",currentComp)

    if (open&&!isGettingCompanies && companies.length === 0) {
      console.log("dep modal read comapnies")
      readAllAxCompanies()
    }
    if(currentComp?.company?.cin)readCompanyInvoices(currentComp.company.cin)
//     recalculatePaymentOverview();
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentComp,open])



  useEffect(()=> {
// console.log("Object.values(axStore.axDepartments):",Object.values(axStore.axDepartments))
    // toto sa spusti akonahle je nastavena hodnota pre currentComp, ale chcem to len pri prvom otvoreni, aby sa dotiahli data z API
    console.log('useEffect open companyquickedit')
    console.log('compIdToEdit=', compIdToEdit)
    console.log('previous compid compOldIdToEdit=', compOldIdToEdit)


if(compIdToEdit===0){
    if(isDepartmentMainCompany===1){ // my company
      setNewCompCheckboxLabel('Fakturačný subjekt je firma')
      setNewCompIcoLabel('Ičo fakturačného subjektu')
      setNewNoCompNameLabel('Názov/meno fakturačného subjektu')
      setNewEmptyCompButtonLabel('Vytvor prázdny subjekt')
      setNewEmptyNonCompButtonLabel('Vytvor prázdny subjekt')
    }else{
      setNewCompCheckboxLabel('Nový fakturačný partner je firma')
      setNewCompIcoLabel('IČO nového fakturačného partnera / firmy. ')
      setNewNoCompNameLabel('Názov/meno nového fakturačného partnera / firmy. ')
      setNewEmptyCompButtonLabel('Vytvor novú prázdnu firmu')
      setNewEmptyNonCompButtonLabel('Vytvor nového partnera v databáze')
    }

}else if (open) {
    console.log('open modal useeffect')
    console.log('change compIdToEdit useeffect')
    console.log('previous compid compOldIdToEdit=', compOldIdToEdit)
    console.log('compIdToEdit=', compIdToEdit)
    console.log('isDepartmentMainCompany=', isDepartmentMainCompany)

  if ((!isLoading) && (parseInt(compOldIdToEdit, 10) !== parseInt(compIdToEdit, 10))) loadCompDataToEdit(compIdToEdit);
  /*
    if (isDepartmentMainCompany === 1) { // my company
      console.log('mycompany')
    } else {
      // reset(defaultValues);
      //   if ((!currentCompAllLoadedData)&&(!isLoading)&&(parseInt(depOldIdToEdit, 10) !== parseInt(compIdToEdit, 10))) loadCompDataToEdit(compIdToEdit);
      // eslint-disable-next-line no-lonely-if
      if ((!isLoading) && (parseInt(compOldIdToEdit, 10) !== parseInt(compIdToEdit, 10))) loadCompDataToEdit(compIdToEdit);

    }
    */
  }
  // },[currentComp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open,compIdToEdit]);

  // const onSubmit = handleSubmit(async (data,event) => {
  const onSubmit =  (data,event) => {
    // enqueueSnackbar('Saving department data');
     const clickedButton = event.nativeEvent.submitter;
     // console.log('clicked submit button ',clickedButton.name)
     // console.log('going to save company data',data)
    // console.log('submited button event: ',event)
    // console.log('submited button data: ',data)
      postCompDataToSaveToServer(data,clickedButton.name)

  };
  // });
function getCompanyDetailByCin(cin=null){
  setIsGettingCompInfoByIco(true)
  let headers={}
  const jwt = localStorage.getItem('axjwt');
  if(jwt) {
    headers = {
      'Authorization': `Bearer ${jwt}`
    }

    let params = {
      'ico': cin,
      'action':'get_company_info_from_ruz_by_ico'
    }

    axios.get('https://api.administrix.pro/v1/get/', {
      headers,
      params
    })
      .then((response => {
        if (response.data.data) {
          let data = response.data.data;
          let meta = response.data.meta;
          if(data.company) {
            // console.log('compnay found with ruz id:', data.company.ruz_id)
            setNewCompanyDataFromApi(data.company)
            setLastGCIFAmessage('');

          }else{
            // console.log('company not found:')
            // **** try to find in rpo

            params = {
              'ico': cin,
              'action':'get_company_info_from_rpo_by_ico'
            }

            axios.get('https://api.administrix.pro/v1/get/', {
              headers,
              params
            })
              // eslint-disable-next-line no-shadow
              .then((responserpo => {
                if (responserpo.data.data) {
                   data = responserpo.data.data;
                   meta = responserpo.data.meta;
                   console.log('rpo data:',data)
                   console.log('rpo company:',data.company)
                  if(data.company) {
                    // console.log('compnay found with ruz id:', data.company.ruz_id)
                    setNewCompanyDataFromApi(data.company)
                    setLastGCIFAmessage('');

                  }else{
                    // console.log('company not found:')
                    setNewCompanyDataFromApi({})
                    setLastGCIFAmessage(`firmu s IČO ${cin} sme nenašli...`)
                  }
                }
                setIsGettingCompInfoByIco(false)
              }))
              .catch(error => {
                console.log("any registerrpo fetch api error");
                setLastGCIFAmessage('pri hľadaní sa vyskytla chyba');
                setNewCompanyDataFromApi({})
                setIsGettingCompInfoByIco(false)
              })

          }
        }
        setIsGettingCompInfoByIco(false)
      }))
      .catch(error => {
        console.log("any registeruz fetch api error");
        setLastGCIFAmessage('pri hľadaní sa vyskytla chyba');
        setNewCompanyDataFromApi({})
        setIsGettingCompInfoByIco(false)
      })
  }

}

  const renderAddNewCompany = (
    <>
    <DialogTitle>
      {(isDepartmentMainCompany===1)?'Vytvoriť fakturačné údaje pre tento department':'Nový fakturačný partner/firma'}

    </DialogTitle>
    <DialogContent>
      <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingLeft: "20px",paddingTop:1}}>
        <Stack spacing={2}>
          <RHFCheckbox name="is_company_partner_new" label={newCompCheckboxLabel}/>
          {
            !watched_values[2]&&
          <RHFTextField name="name_new" label={newNoCompNameLabel}/>}


          {watched_values[2]&&
<RHFTextField name="ico_new" label={newCompIcoLabel}
              // onBlur={console.log('iconew on blur',watched_values[1])}
              onBlur={() => {
                // let new_ico=watched_values[1].replace(/[^0-9]/g, '');
                const new_ico=getValues('ico_new').replace(/[^0-9]/g, '');
                setValue('ico_new', new_ico)
                setLastGCIFAmessage('IČO musí obsahovať čísla')
                if(new_ico) {

                  getCompanyDetailByCin(new_ico)
                }
                // console.log('iconew on blur',watched_values[1])
                // return { value: selectValue };
              }}
/>}

        </Stack>
      </Grid>

    </DialogContent>

      </>
  )


  const getOptionLabelCompanies= (option) => (`${option.label}, ${option.street} ${option.street_number}, ${option.municipality}. ID:${option.id}`);
  const datetimeSlovakFormatOptions = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };


  const renderEditCompany = (
    (currentComp&&compIdToEdit!==0)?(<>
      <DialogTitle>Nastavenie fakturačného partnera/firmy</DialogTitle>
      <DialogContent>
!!!POZOR TATO KOMPONENTA ESTE NIE JE VYLADENA AK SA JEDNA O FIRMY INEHO DEPARTMENTU AKO R65 - treba doriesit z akeho departmentu sa nacitava firma, do ktoreho sa uklada a z ktoreho sa nacitava zoznam departmentov
        !!!
      <Grid container spacing={2} sx={{paddingTop:1}}>
    <Grid xs={12} sm={7} md={8} lg={9} xl={10} >
      {(companies&&Object.keys(companies).length > 1) && (<Autocomplete
        autoComplete={autocomplete}
        value={selectedCompany||null}
        disablePortal
        id="companies_autocomplete"
        name="companies_autocomplete"
        size="small"
        isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}

        getOptionLabel={getOptionLabelCompanies}
        /* options={Object.values(companies)} */
        options={companies || []}
        /* onChange={(event, newValue) => {setSelectedCompany(newValue);handleOptionSelectedCompClick()}} */
         onChange={handleOptionSelectedCompClick}
        renderInput={(params) => <TextField {...params} label="Vyberte firmu, ktorú chcete upravovať:"/>}
      />)}
    </Grid>
        <Grid xs={12} sm={5}  md={4} lg={3} xl={2} >
          <Button
            fullWidth
            color="success"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={()=>{setCurrentCompAllLoadedData(false);setCurrentComp(null);setCompIdToEdit(0)}}
          >
            Nová firma
          </Button>
        </Grid>
    <Grid xs={12} lg={3}>
      <Paper elevation={4} sx={{margin: 2, padding: 2, fontSize: "0.7em"}}>
<Grid container>


        {isLoading && (<Grid><Typography align="center" sx={{fontSize: "0.6em"}}>získavam údaje o webe {compNameSwitchingTo}...</Typography><LinearProgress/></Grid>)}
        {currentCompAllLoadedData && (<>
        <Grid sm={12}  lg={12}>
          <Typography variant="h6" gutterBottom>
            {currentComp.company.name}
          </Typography>
          <ConfirmCompanyDelete compId={currentComp.company.id} compName={currentComp.company.name} handleCloseModal={handleCloseModal} setDeleteCompConfirmModalIsOpen={setDeleteCompConfirmModalIsOpen} deleteCompConfirmModalIsOpen={deleteCompConfirmModalIsOpen} />
          <Typography variant="body2" gutterBottom>
            Firma ID: <strong>{currentComp.company.id}</strong>
          </Typography>
          <Tooltip TransitionComponent={Zoom} title={`Department bol vytvorený užívateľom ${currentComp.company.created_by_name} dňa ${currentComp.created_at_nice.niceDate} o ${currentComp.created_at_nice.niceTime}`}>
            <Typography variant="body2" gutterBottom>
              Vytvorenie firmy v DB:
              <strong> {currentComp.created_at_nice.niceDate}</strong>
            </Typography>
          </Tooltip>
          {currentComp.updated_at_nice && (
            <Tooltip TransitionComponent={Zoom} title={`Department bol aktualizovaný užívateľom ${currentComp.updated_by_name} dňa ${currentComp.updated_at_nice.niceDate} o ${currentComp.updated_at_nice.niceTime}`}>
              {
                (currentComp.updated_at_nice.year>1970)&&(
                <Typography variant="body2" gutterBottom>
                  Posledný update firmy: <strong>{currentComp.updated_at_nice.niceDate}</strong>
                </Typography>
                )
              }
            </Tooltip>
          )
          }


          <Typography variant="body2" gutterBottom>
            Kontakty: <strong> </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Užívatelia: <strong> </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            TODO: <strong> </strong>
          </Typography>
        </Grid>
          <Grid sm={12}   lg={12}>


            {/* <Typography variant="body2" gutterBottom>
              Pravidelná fakturácia: <strong>{currentComp?.payment?.is_active ? 'Áno' : 'Nie'} </strong>
            </Typography> */}

            {companyInvoices.invoices&&(<>

              {(parseInt(companyInvoices.invoices_stat.whole_count,10)>0)&&(<>
            <Typography variant="body2" gutterBottom>
            Uhradené všetky faktúry: <strong>
            {
              (parseInt(companyInvoices.invoices_stat.unpayed_count,10)===0)?(<span style={{color:"#52a200"}}>Áno</span>):(<span style={{color:"#d50000"}}>NIE</span>)
            }

          </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Celkovo faktúr: <strong>{companyInvoices.invoices_stat.whole_count}</strong> v cene <strong> {parseFloat(companyInvoices.invoices_stat.whole_sum).toFixed(2)} €</strong>
          </Typography>
          {
            (parseInt(companyInvoices.invoices_stat.unpayed_count,10)>0)&&(
          <Typography variant="body2" gutterBottom>
            Celkovo neuhradených: <strong>{companyInvoices.invoices_stat.unpayed_count}</strong> v cene <strong> {parseFloat(companyInvoices.invoices_stat.unpayed_sum).toFixed(2)} €</strong>
          </Typography>
            )}


              </>)}
</>)}

        </Grid>
        </>)}
  {currentComp&&<Button
    fullWidth
    color="error"
    variant="contained"
    startIcon={<Iconify icon="material-symbols:delete-outline" />}
    onClick={()=>{setDeleteCompConfirmModalIsOpen(true)}}
  >
    Odstráň firmu
  </Button>}

</Grid>
      </Paper>
    </Grid>


    <Grid xs={12} lg={9}>

      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="Nastavenia departmentu"
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label}/>
            ))}
          </Tabs>
        </Box>

        {currentTab === 'adresy-kontakty' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>

          {(companyContacts?.length>0)&&(<TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Typ kontaktu</TableCell>
                  <TableCell >Kontakt</TableCell>
                  <TableCell >Posielať faktúru</TableCell>
                  <TableCell align="center" >Zmazať</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyContacts.map((row) =>(
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.type_text}
                    </TableCell>
                    <TableCell title={`id: ${row.id}`}>{row.value}</TableCell>
                    <TableCell >
                      {row.type === 1 &&
                      <Switch
                        checked={row.is_for_invoice_sending}
                        onChange={handleCheck}
                        inputProps={{'aria-label': 'controlled'}}
                        id={`swis${row.id}`}
                      />
                      }
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Odstrániť kontakt">
                      <DeleteForeverIcon onClick={()=>deleteCompanyContact(compIdToEdit,row.id)} sx={{color:"red",cursor:"pointer"}} />
                      </Tooltip>
                      </TableCell>

                  </TableRow>
                )


                )}
              </TableBody>
            </Table>
          </TableContainer>)}

          <Grid container sx={{backgroundColor:"#fafafa",padding:"10px",borderRadius:"10px",marginTop:"20px"}}>


            <Grid xs={12}>Pripojiť k firme / partnerovi nový kontakt</Grid>
            <Grid xs={12} md={3}>

              <TextField
                id="outlined-select-currency"
                select
                label="Vyberte typ kontaktu"
                defaultValue="EUR"
                fullWidth
                value={newContactType}
                onChange={handleChangeContactType}
                sx={{backgroundColor:"white"}}
                small
              >
                <MenuItem value="1">E-mail</MenuItem>
                <MenuItem value="2">Telefón</MenuItem>

              </TextField>


            </Grid>
            <Grid xs={12} md={5}>
              <TextField id="input_contact_value" label="Hodnota" variant="outlined" value={newContactValue} fullWidth onChange={handleChangeContactValue}                 sx={{backgroundColor:"white"}}
                         small/>
            </Grid>
            <Grid xs={12} md={4}>
              <Button
                fullWidth
                color="success"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={()=>insertNewCompanyContact()}
              >
                Vložiť nový kontakt
              </Button>
            </Grid>
          </Grid>


          {/*
          {currentComp.company?.address?.map((address) => (
            <div key={address.id}>
              <p>Company ID: {address.company_id}</p>
              <p>Country: {address.country}</p>
              <p>Country Code: {address.country_code}</p>
              <p>ID: {address.id}</p>
              <p>Is Delivery Address: {address.is_delivery_address}</p>
              <p>Is Invoice Address: {address.is_invoice_address}</p>
              <p>Is Main Address: {address.is_main_address}</p>
              <p>Is Secondary Address: {address.is_secondary_address}</p>
              <p>Municipal Code: {address.muni_code}</p>
              <p>Municipality: {address.municipality}</p>
              <p>Postcode: {address.postcode}</p>
              <p>Registration Number: {address.reg_number}</p>
              <p>Street: {address.street}</p>
              <p>Street2: {address.street2}</p>
              <p>Street Number: {address.street_number}</p>
              <p>User ID: {address.user_id}</p>
              <p>Village ID: {address.village_id}</p>
              <hr />
            </div>
          ))}

          */}

        </Grid>
        }

        {currentTab === 'faktury' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>

          {(companyInvoices?.invoices.length>0)&&(<TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dátum vystavenia</TableCell>
                  <TableCell >Názov</TableCell>
                  <TableCell align="center">Suma & VS</TableCell>
                  <TableCell >Komu</TableCell>
                  <TableCell align="center">Uhradená</TableCell>
                  <TableCell align="center">Dátum úhrady</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {companyInvoices.invoices.map((row) =>(
                    <TableRow
                      key={row.invoice_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {ConvertToNiceDate(row.created_at).niceDate}
                      </TableCell>
                      <TableCell ><a href={`https://www.administrix.pro/inv/showinvoice.php?f=${row.invoice_id}&s=${row.department_id}&tkn=${row.token}`} target="_BLANK"  rel="noreferrer">{row.name}</a></TableCell>
                      <TableCell align="center">{parseFloat(row.invoice_price).toFixed(2)} €<br />{row.variable_symbol}</TableCell>
                      <TableCell >{row.partner_company_name},<br /> {row.street} {row.street_number}, {row.postcode} {row.municipality}</TableCell>
                      <TableCell align="center" >{row.invoice_status==="6"?(<Tooltip title="Uhradená ;)"><CheckIcon sx={{color:"green"}}/></Tooltip>):(<Tooltip title={`Neuhradená - splatnosť ${ConvertToNiceDate(row.due_date).niceDate}`}><DangerousIcon sx={{color:"red"}}/></Tooltip>)}</TableCell>
                      <TableCell align="center">
                        {row.invoice_status==="6"?(ConvertToNiceDate(row.payment_realized_date).niceDate):'-'}
                        </TableCell>

                    </TableRow>
                  )


                )}
              </TableBody>
            </Table>
          </TableContainer>)}



        </Grid>
        }

        {currentTab === 'zakladne-udaje' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>

            <Grid container spacing={2}>
              <Grid xs={12}><RHFTextField name="compname" label="Názov firmy"/></Grid>
              <Grid xs={12}><Typography variant="button">Fakturačná adresa</Typography></Grid>
              <Grid xs={12} sm={6}><RHFTextField name="street" label="Ulica" /></Grid>
              <Grid xs={12} sm={3}><RHFTextField name="reg_number" label="Súpisné číslo" /></Grid>
              <Grid xs={12} sm={3}><RHFTextField name="street_number" label="Popisné číslo" /></Grid>

              <Grid xs={12} sm={3}><RHFTextField name="postcode" label="PSČ" /></Grid>
              <Grid xs={12} sm={5}><RHFTextField name="municipality" label="Obec" /></Grid>
              <Grid xs={12} sm={2}><RHFTextField name="country" label="Štát" /></Grid>
              <Grid xs={12} sm={2}><RHFTextField name="country_code" label="Kód krajiny" /></Grid>

              <Grid xs={12}><Typography variant="button">Fakturačné údaje</Typography></Grid>
              <Grid xs={12} sm={6}><RHFTextField name="cin" label="IČO" /></Grid>
              <Grid xs={12} sm={6}><RHFTextField name="tin" label="DIČ" /></Grid>
              <Grid xs={12}><RHFSwitch name="is_vat_payer" label={isVatPayerLabel}/></Grid>
              {
                isVatPayer&&(<>
                <Grid xs={12} sm={6}><RHFTextField name="vatin" label="IČ DPH"/></Grid>
                <Grid xs={12} sm={6}><RHFTextField name="vatin_paragraph" label="Podľa paragrafu" /></Grid>
                </>)
              }

              <Grid xs={12} sm={6}><RHFTextField name="registration_office" label="Zápis v registri na súde" /></Grid>
              <Grid xs={12} sm={6}><RHFTextField name="registration_number" label="Číslo zápisu" /></Grid>

              <Grid xs={12} sm={6}><RHFTextField name="logo" label="Logo (id suboru)" /></Grid>
              <Grid xs={12} sm={6}><RHFTextField name="signature" label="Podpis/Pečiatka (id suboru)" /></Grid>

              <Grid xs={12} sm={12}><RHFTextField name="admin_note" label="Poznámka admina - neverejné" /></Grid>




            </Grid>


        </Grid>}



        {currentTab === 'adresy-kontakt' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} container>

          <Grid xs={12}   lg={6} xl={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia databázy
              </Typography>
                <RHFSwitch name="db_visible" label="Databáza je aktívna"/>
              {getValues('db_visible')&&(<>
              <RHFTextField name="db_server" label="DB Server" size="small"/>
              <RHFTextField name="db_user" label="DB User/login" size="small"/>
              <RHFTextField name="db_password" label="DB Password" size="small"/>
              <RHFTextField name="db_name" label="DB Name" size="small"/>
              <RHFTextField name="dsn_local" label="DSN LOCAL" size="small"/>
              <RHFTextField name="phpmyadmin" label="PHP MyAdmin" size="small"/>
              <RHFTextField name="db_port" label="DB Port" size="small"/>
              </>
              )}
            </Stack>
          </Grid>
          <Grid xs={12}  sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia ftp
              </Typography>
               <RHFSwitch name="ftp_visible" label="FTP je aktívne"/>
              {getValues('ftp_visible')&&(<>
              <RHFTextField name="ftp_server" label="FTP Server" size="small"/>
              <RHFTextField name="ftp_name" label="FTP login" size="small"/>
              <RHFTextField name="ftp_password" label="FTP password" size="small"/>
              <RHFTextField name="ftp_dir" label="FTP dir" size="small"/>
              </>)}
            </Stack>
          </Grid>

        </Grid>}


      </Box>


    </Grid>

    <Grid>


      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      />
    </Grid>
  </Grid>
      </DialogContent>
  </>):null

      )

  return (
    <Dialog
      fullWidth
      maxWidth={dialogWidth}
      open={open}
      onClose={handleCloseModal}
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit, onError)}

      >
          {(compIdToEdit!==0)?renderEditCompany:renderAddNewCompany  }
          {/* console.log("cely komponente render, compIdToEdit:",compIdToEdit,typeof compIdToEdit) */}
          {/* renderEditDepartment */}
        <Typography variant="caption" display="block" sx={{textAlign:"center",color:"red"}}>
          {lastGCIFAmessage&&<>{lastGCIFAmessage} <br /></>}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{textAlign:"center"}}>

          {isGettingCompInfoByIco&&(<>zisťujem informácie o firme...</>)}
          {(Object.keys(newCompanyDataFromApi).length>0)&&(
            <>
              <strong>Zistená firma s IČO: {newCompanyDataFromApi.ico}</strong>:<br />{newCompanyDataFromApi.name}, {`${newCompanyDataFromApi.full_street}, ${newCompanyDataFromApi.postcode} ${newCompanyDataFromApi.city}`}, DIČ: {newCompanyDataFromApi.dic}
              </>
            )}
        </Typography>

        <DialogActions>


          {(isDirty && compIdToEdit===0 && !watched_values[2]) && <LoadingButton
            type="submit"
            name="submit_button_new_partner"
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<QueuePlayNext/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            {newEmptyNonCompButtonLabel}
          </LoadingButton>
          }

          {(isDirty && compIdToEdit===0 && watched_values[2]&& watched_values[1]&&(Object.keys(newCompanyDataFromApi).length === 0)) && <LoadingButton
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<ManageSearchIcon/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            Zisti firmu podľa IČO
          </LoadingButton>
          }

          {(isDirty && compIdToEdit===0 && watched_values[2]&& watched_values[1]&&(Object.keys(newCompanyDataFromApi).length >0)) && <LoadingButton
            name="submit_button_partner_from_api"
            variant="contained"
            type="submit"
            size="large"
            loading={isSubmitting}
            startIcon={<DomainAddIcon/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            Vlož firmu {newCompanyDataFromApi.name} do databázy
          </LoadingButton>
          }
          {(compIdToEdit===0 && watched_values[2] )&& <LoadingButton
            type="submit"
            name="submit_button_new_partner"
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<AddToQueueIcon/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            {newEmptyCompButtonLabel}
          </LoadingButton>
          }

          {(isDirty&&compIdToEdit>0) && <LoadingButton
            name="submit_button_save_changes"
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<SaveIcon/>}
            sx={{ml: 2, backgroundColor: '#cc0d0d', color: '#fff'}}
          >
            Ulož zmeny
          </LoadingButton>
          }

          <Button variant="outlined" onClick={handleCloseModal}>
            Zatvor
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

CompanyQuickEditForm.propTypes = {
  compIdToEdit: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setCompOldIdToEdit:PropTypes.func,
  setCompIdToEdit:PropTypes.func,
  isDepartmentMainCompany:PropTypes.number,
  loadComps:PropTypes.func,
  companyOfDepId:PropTypes.number, // from which department(companyOfDepId is id of the tep) get the company of given compid
  compOldIdToEdit:PropTypes.number,
  currentCompAsProp: PropTypes.object,
};
