
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoIcon from '@mui/icons-material/Info';
import {useCallback, useEffect, useState} from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChecklistIcon from '@mui/icons-material/Checklist';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";
import axios from "axios";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhotoIcon from '@mui/icons-material/Photo';


import {Autocomplete, CircularProgress, Tooltip} from '@mui/material'
// import PixmeUpload  from "../../../../../src/components/pixme/pixmeUpload";
// import {useUploadStore} from "../../../../../src/store/pixmeUploadStore";
// import {useLangStore} from "@/store/pixmeLangStore";
import PropTypes from "prop-types";
import Image from 'src/components/image';
import {enqueueSnackbar} from "notistack";
import DoneIcon from '@mui/icons-material/Done';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import SellIcon from '@mui/icons-material/Sell';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import CheckIcon from "@mui/icons-material/Check";
import {useLocation} from "react-router";

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {useAxStore} from "../../store/axStore";
import PixmeUpload from "../pixme/pixmeUpload";
import {useUploadStore} from "../../store/pixmeUploadStore";
import {fData, fNumber} from "../../utils/format-number";
import {fTimestamp} from "../../utils/format-time";
// import {useRouter} from "../../routes/hooks";

function LinearProgressWithLabel({value}) {
  // console.log('LinearProgressWithLabel got value ',value)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


export default function UploadDialog({isOpenUploadDialog,setIsOpenUploadDialog}) {
  const axStore=useAxStore()
  const user=axStore.axCurrentUser
  const location = useLocation();
  const navigate = useNavigate();

  const [isVisibleSettings, setIsVisibleSettings] = useState(false);

  const handleToggleSettings = () => {
    setIsVisibleSettings((prev) => !prev);
  };

  const { t, onChangeLang } = useTranslation()
  // const router = useRouter();
    const lang = {}
    const uploadStore = useUploadStore();
    const isUploading = useUploadStore((state) => state.isUploading)
    const uploadingProcess = useUploadStore((state) => state.wholeUploadProcess)
    const [errorMess, setErrorMess] = useState('');
    const [fid, setFid] = useState(null);
    // const router=useRouter()
    const piXmeUploadApiUrl = ''
    const [isUploadedFilesQueueProcessing, setIsUploadedFilesQueueProcessing] = useState(false);

    const [expectedUploadTime, setExpectedUploadTime] = useState(0);
    const [remainingBytes, setRemainingBytes] = useState(0);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [allowAiRecognition, setAllowAiRecognition] = useState(false);
    const [doAiRecognition, setDoAiRecognition] = useState(true);
    const [adminConcurrency, setAdminConcurrency] = useState(5);
    const [wholeUploadFileSize, setWholeUploadFileSize] = useState(0);
    const [alreadyUploadedCount, setAlreadyUploadedCount] = useState(0);
    const [uploadStartTime, setUploadStartTime] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [licence, setLicence] = useState(0);

    const [toGal, setToGall] = useState("new");
    const [toGalName, setToGallName] = useState("new");
    const defaultGallVal={label: t('upld.new_folder'),id:"new"}
    const [gallValue, setGallValue] = useState(defaultGallVal);
    const [newGalleryName, setNewGalleryName] = useState('');

    const [toAuthor, setToAuthor] = useState("none");
    const [toAuthorName, setToAuthorName] = useState("");
    const defaultAuthorOptions=[{label:"- nenastaviť autora -",id:"none"},{label:"Nový autor",id:"new"}]
    const defaultAuthorVal={label:t('upld.dont_set_author'),id:"none"}
    const [authorValue, setAuthorValue] = useState(defaultAuthorVal);
    const [newAuthorName, setNewAuthorName] = useState('');
    const [selectedWatermark, setSelectedWatermark] = useState(0);
    const [getTagsFromFileAdobeKewords, setGetTagsFromFileAdobeKewords] = useState(false);
    const [getTagsFromFilename, setGetTagsFromFilename] = useState(false);


    const [selectedWatermarkName, setSelectedWatermarkName] = useState(t('upld.no_watermark').toLowerCase());
    const[gallListAutocompleteOptions,setGallListAutocompleteOptions]=useState( [{label:t('upld.new_folder'),id:"new"}])
    const[authorListAutocompleteOptions,setAuthorListAutocompleteOptions]=useState( defaultAuthorOptions)
    const[galleriesToSelect,setGalleriesToSelect]=useState( [])
    const[watermarksToSelect,setWatermarksToSelect]=useState( [])
  const [selectedLicenceText, setSelectedLicenceText] = useState(t('upld.not_setting').toLowerCase());

    const [expandedFilesInfo, setExpandedFilesInfo] = useState(false);

    const handleShowMoreToggle = () => {
      setExpandedFilesInfo(!expandedFilesInfo);
    };

   const handleCancelUploadAndStartNew = () => {

     uploadStore.setIsUploading("ready")
     setExpandedFilesInfo(false)
     // setToGall("new")
     setGallValue(defaultGallVal)
     setSelectedWatermark(0)
     setNewGalleryName('')
     setFilesToUpload([])
     setAuthorValue(defaultAuthorVal)
    };

    const getExtension = str => str?.split('.').pop().toLowerCase();
    /* function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / k**i).toFixed(dm))  } ${  sizes[i]}`;
    } */

  const addExecutionTime = (id, executionTime) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.execution_time = executionTime
          return newFile;
        }
        return file;
      })
    );
  };


  const updateFileUploadedBytes = (id, bts) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.updBytes = bts
          return newFile;
        }
        return file;
      })
);

  };


  const updateFileStatus = (id, newStatus) => {
    setFilesToUpload((prevFiles) =>


      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.status = newStatus
          return newFile;
        }
        return file;
      })


      /* prevFiles.map((file) =>
        file.id === id ? { ...file, status: newStatus } : file
      ) */
    );
    // const totalCount=countCompletedFiles(filesToUpload)

  };


  const eraseFile = (id) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.filter((file) => file.id !== id)
    );
  };
  const renderFilePreview = (id) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.preview = URL.createObjectURL(file);
          return newFile;
        }
        return file;
      })
    );
  };
  const updateFilePreview = (id,url) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.preview = url;
          return newFile;
        }
        return file;
      })
    );
  };

  const updateFileAiTagsSk = (id,aitagssk) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.aiTagsSk = aitagssk;
          return newFile;
        }
        return file;
      })
    );
  };  const updateFileAiTagsEn = (id,aitagsEn) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => {
        if (file.id === id) {
          const newFile = file;
          newFile.aiTagsEn = aitagsEn;
          return newFile;
        }
        return file;
      })
    );
  };
/*
  const renderFilePreviews = () => {
    console.log('renderFilePreviews')
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    );
  };

  const renderFilePreviews = () => {
    console.log('renderFilePreviews');
    setFilesToUpload((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        preview: file.preview || URL.createObjectURL(file)
      }))
    );
  };
*/

  const updateFileProgress = (id, newProgress) => {
    setFilesToUpload((prevFiles) =>
        prevFiles.map((file) => {
          if (file.id === id) {
            const newFile = file;
            newFile.progress = newProgress
            return newFile;
          }
          return file;
        })
    );
  };

    const changeToGallery = (event) => {
        setToGall(event.target.value)
        // setMediaSpecification(1)
        // console.log("changing to gall "+event.target.value)
        // console.log(event)

    };
    const changeConcurrency = (event) => {
        setAdminConcurrency(event.target.value)
    };
    const changeGalleryNewName = (event) => {
        setNewGalleryName(event.target.value)
    };
    const changeAuthorNewName = (event) => {
        setNewAuthorName(event.target.value)
    };
    const changeWatermark = (event) => {

      const selectedId = event.target.value;
      const tselectedWatermark = watermarksToSelect.find(option => option.id === selectedId);
      const watermarkName = tselectedWatermark ? tselectedWatermark.name : t('upld.no_watermark').toLowerCase();

      // console.log('Selected Watermark Name:', watermarkName);

      setSelectedWatermark(selectedId);
      setSelectedWatermarkName(watermarkName);
    };

    const handleLicenceChange = (event) => {
        setLicence(event.target.value)
      const textMap = {
        0: t('upld.not_setting'),
        1: t('upld.non_exclusive_licence'),
        2: t('upld.exclusive_licence'),
        3: t('upld.other_licence'),
      };
      setSelectedLicenceText(textMap[event.target.value].toLowerCase());
    };
    const handleDoAiChange = (event) => {
        setDoAiRecognition(event.target.checked)
    };
    const handleTagFromFilenameChange = (event) => {
        setGetTagsFromFilename(event.target.checked)
    };
    const handleTagFromFileChange = (event) => {
        setGetTagsFromFileAdobeKewords(event.target.checked)
    };

    const handleClose = () => {
        console.log('closing upload')
        setIsOpenUploadDialog(false);
        if(isUploading==="finished"||isUploading==="finished_with_errors"||isUploading==="finished_with_quota_exceeded") {
            uploadStore.setIsUploading("ready")
            // setToGall("new")
            setGallValue(defaultGallVal)
            setSelectedWatermark(0)
            setNewGalleryName('')
            setFilesToUpload([])
            setAuthorValue(defaultAuthorVal)
          setAlreadyUploadedCount(0)
        }

    };

    function convertTime(secondss) {
        let seconds = parseInt(secondss, 10)
        const hours   = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds - (hours * 3600)) / 60)
        seconds = seconds - (hours * 3600) - (minutes * 60)
        if ( hours ) {
            if ( minutes ) {
                return `${hours}h ${minutes}m ${seconds}s`
            }
                return `${hours}h ${seconds}s`

        }
        if ( minutes ) {
            return `${minutes}m ${seconds}s`
        }
        return `${seconds}s`
    }
  const calculateTotalProgress = (files) => {
    const totalProgress = files.reduce((acc, file) => acc + file.progress, 0);
    const numberOfFiles = files.length;
    return numberOfFiles > 0 ? (totalProgress / numberOfFiles) : 0;
  };
  // const countCompletedFiles = (files) => files.filter(file => file.progress === 100).length;
  // const countCompletedFiles = (files) => files.filter(file => file.status.includes("uploaded")).length;
  const calculateRemainingTime = (startTime, percentComplete) => {
    const currentTime = new Date();
    const elapsedTime = currentTime - startTime; // Elapsed time in milliseconds

    if (percentComplete <= 0) {
      return Infinity; // If no progress has been made, we can't estimate the remaining time
    }

    const totalTime = (elapsedTime / percentComplete) * 100; // Estimated total time in milliseconds
    const remainingTime = totalTime - elapsedTime; // Remaining time in milliseconds

    return remainingTime;
  };
  const formatTime = (milliseconds) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    return `${hours}h ${minutes}m ${seconds}s`;
  };
  const calculateTotalUploadedBytes = (files) => files.reduce((acc, file) => acc + (file.updBytes ?? 0), 0);
  function uploadFile(file, jwt,upid=999,gallid) {
    console.log('uploading ',file)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upid', upid);
    formData.append('gallid', gallid);
    formData.append('doAiRec', doAiRecognition);
    formData.append('action', 'upload');
    updateFileStatus(file.id,t('upld.uploading'))

    return axios.post('https://api.administrix.pro/v1/post/index.php', formData, {
      headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`},
      onUploadProgress: progressEvent => {

        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = parseInt(percentComplete * 100,10);
        updateFileProgress(file.id,percentComplete)
        if(percentComplete===100)updateFileStatus(file.id,t('upld.processing'))
        // console.log(percentComplete);
        // updateProgress(percentComplete);
        const totalProgress = Math.floor(calculateTotalProgress(filesToUpload));

        // uploadStore.setWholeUploadProcess(percentComplete)
         uploadStore.setWholeUploadProcess(totalProgress)
        const current_time = Date.now()
        // const elapsed_time = ((Math.floor((current_time - startTime) / 1000)));// in seconds
        const elapsed_time = ((Math.floor((current_time - 1000) / 1000)));// in seconds
        // console.log("starttime vs currenttime:" + startTime + " a " + current_time)
        // console.log('Uplynulo od zaciatku uploadu: ' + elapsed_time + ' sekund')
        // setRemainingBytes(progressEvent.total-progressEvent.loaded)
        // const remaining_bytes = progressEvent.total - progressEvent.loaded;
        // console.log(`progressEvent.total: ${  progressEvent.total}`)
        // console.log(`progressEvent.loaded: ${  progressEvent.loaded}`)
        // setUploadedBytes(progressEvent.loaded);
        updateFileUploadedBytes(file.id,progressEvent.loaded)
        const uploadedBytesWhole=calculateTotalUploadedBytes(filesToUpload)
        setUploadedBytes(uploadedBytesWhole)
        // const transfer_speed = progressEvent.loaded / elapsed_time  // bytes per second
        // console.log('transfer speed: ' + transfer_speed + ' bytes/s')
        // const expectedtime = remaining_bytes / transfer_speed;
        // console.log('expected time: ' + expectedtime + ' s')
        const expectedtime = calculateRemainingTime(uploadStartTime, totalProgress);
         // console.log(`expected time: ${  expectedtime  } s`)
         // console.log('expected time:',formatTime(expectedtime))
        setExpectedUploadTime(formatTime(expectedtime));
        // console.log("Stav uploadu:" + formatBytes(uploadedBytes) + "/" + formatBytes(wholeUploadFileSize))
        // let expectedUploadTime

      }
    })
      .then(responsef => {
        // enqueueSnackbar('fileuploaded')
        // console.log(response.data.data.status=1);
        // console.log('UPLOADED ')
        if(responsef.data.data.status===1) {
          axStore.setIsPixmeEmpty(false);
          updateFileProgress(file.id,100)
          setAlreadyUploadedCount((prevCount) => prevCount + 1);
          updateFileUploadedBytes(file.id,file.size)
          updateFileStatus(file.id, 'uploaded')
          updateFilePreview(file.id,responsef.data.data.file_processed_thumb)
          updateFileAiTagsSk(file.id,responsef.data.data.file_processed_ai_tags_sk)
          updateFileAiTagsEn(file.id,responsef.data.data.file_processed_ai_tags_en)


          addExecutionTime(file.id,responsef.data.meta.executionTime)
        }else{
          updateFileStatus(file.id, t('upld.failed'))
        }
      })
      .catch(error => {
        // handle error here
        console.error(error);
      });
  }
/*
  async function uploadFiles(filesToUploadArg,upid,gallid,jwt,concurrency=3) {

    const promises = [];

    for (let i = 0; i < filesToUploadArg.length; i += concurrency) {
      const batch = filesToUploadArg.slice(i, i + concurrency);

      // Create an array to track the currently uploading files
      const currentlyUploading = [];
// eslint-disable-next-line no-restricted-syntax
      for (const file of batch) {
        // Limit the number of concurrent uploads
        if (currentlyUploading.length < concurrency) {
          currentlyUploading.push(uploadFile(file, jwt, upid, gallid));
        } else {
          // eslint-disable-next-line no-await-in-loop
          await Promise.race(currentlyUploading);
          currentlyUploading.length = 0;
          currentlyUploading.push(uploadFile(file, jwt, upid, gallid));
        }
      }

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(currentlyUploading);
    }


  }
*/
  /*
  async function uploadFiles(filesToUploadArg, upid, gallid, jwt, concurrency = 3) {
    const queue = [...filesToUploadArg];
    const currentlyUploading = [];
    setUploadStartTime(new Date())
    async function uploadNext() {
      if (queue.length === 0) return;

      const file = queue.shift();
      const uploadPromise = uploadFile(file, jwt, upid, gallid).then(() => {
        currentlyUploading.splice(currentlyUploading.indexOf(uploadPromise), 1);
        uploadNext();
      });

      currentlyUploading.push(uploadPromise);

      if (currentlyUploading.length < concurrency && queue.length > 0) {
        uploadNext();
      }
    }

    // Start initial uploads up to the concurrency limit
    let i = 0;
    while (i < concurrency && queue.length > 0) {
      uploadNext();
      i += 1;
    }

    // Wait for all uploads to complete
    await Promise.all(currentlyUploading);
  }
*/

  async function uploadFiles(filesToUploadArg, upid, gallid, jwt, concurrency = 3) {
    const queue = [...filesToUploadArg];
    const currentlyUploading = new Set();
    setUploadStartTime(new Date());

    async function uploadNext() {
      if (queue.length === 0) return;

      const file = queue.shift();
      const uploadPromise = uploadFile(file, jwt, upid, gallid).then(() => {
        currentlyUploading.delete(uploadPromise);
        uploadNext();
      });

      currentlyUploading.add(uploadPromise);

      if (currentlyUploading.size < concurrency && queue.length > 0) {
        uploadNext();
      }
    }

    // Start initial uploads up to the concurrency limit
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < concurrency && queue.length > 0; i++) {
      uploadNext();
    }

    // Wait for all uploads to complete
    while (currentlyUploading.size > 0) {
      // eslint-disable-next-line no-await-in-loop
      await Promise.all([...currentlyUploading]);
    }
  }


  // const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);
    function uploadImages() {
      // console.log('going to upload')
        // const startTime = Date.now();

        uploadStore.setIsUploading("in_progress")
        const uploadPrecheckData = new FormData();
        uploadPrecheckData.append('action', 'can_i_upload');
        uploadPrecheckData.append('gallery', toGal);
        uploadPrecheckData.append('gallery_name', encodeURIComponent(newGalleryName));
        uploadPrecheckData.append('author', toAuthor);
        uploadPrecheckData.append('author_name', encodeURIComponent(newAuthorName));
        uploadPrecheckData.append('watermark', selectedWatermark);
        uploadPrecheckData.append('get_tags_from_filename', getTagsFromFilename);
        uploadPrecheckData.append('get_tags_from_file_lightroom_keywords', getTagsFromFileAdobeKewords);
        uploadPrecheckData.append('do_ai_analyze', doAiRecognition);
        uploadPrecheckData.append('licence', licence);
        uploadPrecheckData.append('wholesize', wholeUploadFileSize);
        uploadPrecheckData.append('filescount', filesToUpload.length);

        // const formData = new FormData();
        // formData.append('action', 'upload');
        /* filesToUpload.forEach(item => {
            formData.append(item.name, item)
        });
*/

        const jwt = localStorage.getItem('axjwt')
//* ****start upload - check if possibnle to upload - due department and user quota, or another conditions like too much upload sessions etc... if can upload, creates upload and sends back upload ID
        if (jwt) {
  //       can i upload


            return axios.post(`https://api.administrix.pro/v1/post/index.php`, uploadPrecheckData, {
                headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
            }).then(
                (res) => {
    //                console.log("upload precheck finished");
      //              console.log(res);
        //            console.log(res.data.data);
// console.log(filesToUpload)
                    if(res.data.data.uploadId&&res.data.data.galleryId){
                        const upid=res.data.data.uploadId
                        const gallid=res.data.data.galleryId
                      let concurr=3;
                      if(user.ax_role<2)
                      { concurr=adminConcurrency}else
                      { concurr=res.data.data.maxSimUploads}
                        setNewGalleryName(res.data.data.galleryName)

                      // **********
                      /*
                      const filesWithUniqueIds = filesToUpload.map((file, index) => ({
                          id: generateUniqueId(),
                          ...file,
                        }));
                      Promise.resolve(filesWithUniqueIds).then(); */
                      uploadFiles(filesToUpload, upid,gallid,jwt,concurr).then(() => {
                        uploadStore.setIsUploading('finished')
                      })

                      /* filesToUpload.forEach(file => {
                        console.log('uploading ',file)
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('upid', upid);
                        formData.append('action', 'upload');


                        axios.post('https://api.administrix.pro/v1/post/index.php', formData, {
                          headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`},
                          onUploadProgress: progressEvent => {
                            let percentComplete = progressEvent.loaded / progressEvent.total
                            percentComplete = parseInt(percentComplete * 100,10);
                             console.log(percentComplete);
                            // updateProgress(percentComplete);
                            uploadStore.setWholeUploadProcess(percentComplete)
                            const current_time = Date.now()
                            const elapsed_time = ((Math.floor((current_time - startTime) / 1000)));// in seconds
                            // console.log("starttime vs currenttime:" + startTime + " a " + current_time)
                            // console.log('Uplynulo od zaciatku uploadu: ' + elapsed_time + ' sekund')
                            // setRemainingBytes(progressEvent.total-progressEvent.loaded)
                            const remaining_bytes = progressEvent.total - progressEvent.loaded;
                             console.log(`progressEvent.total: ${  progressEvent.total}`)
                             console.log(`progressEvent.loaded: ${  progressEvent.loaded}`)
                            setUploadedBytes(progressEvent.loaded);
                            const transfer_speed = progressEvent.loaded / elapsed_time  // bytes per second
                            // console.log('transfer speed: ' + transfer_speed + ' bytes/s')
                            const expectedtime = remaining_bytes / transfer_speed;
                            // console.log('expected time: ' + expectedtime + ' s')
                            setExpectedUploadTime(Math.ceil(expectedtime));
                            // console.log("Stav uploadu:" + formatBytes(uploadedBytes) + "/" + formatBytes(wholeUploadFileSize))
                            // let expectedUploadTime

                          }
                        })
                          .then(response => {
                            enqueueSnackbar('fileuploaded')
                            console.log(response.data);
                            console.log('UPLOADED ')
                          })
                          .catch(error => {
                            // handle error here
                            console.error(error);
                          });
                      }); */


                      // ************



/*
                        return axios.post(`https://api.administrix.pro/v1/post/index.php`, formData, {
                            headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`},
                            onUploadProgress: progressEvent => {
                                let percentComplete = progressEvent.loaded / progressEvent.total
                                percentComplete = parseInt(percentComplete * 100,10);
                                // console.log(percentComplete);
                                // updateProgress(percentComplete);
                                uploadStore.setWholeUploadProcess(percentComplete)
                                const current_time = Date.now()
                                const elapsed_time = ((Math.floor((current_time - startTime) / 1000)));// in seconds
                                // console.log("starttime vs currenttime:" + startTime + " a " + current_time)
                                // console.log('Uplynulo od zaciatku uploadu: ' + elapsed_time + ' sekund')
                                // setRemainingBytes(progressEvent.total-progressEvent.loaded)
                                const remaining_bytes = progressEvent.total - progressEvent.loaded;
                                // console.log('progressEvent.total: ' + progressEvent.total)
                                // console.log('progressEvent.loaded: ' + progressEvent.loaded)
                                setUploadedBytes(progressEvent.loaded);
                                const transfer_speed = progressEvent.loaded / elapsed_time  // bytes per second
                                // console.log('transfer speed: ' + transfer_speed + ' bytes/s')
                                const expectedtime = remaining_bytes / transfer_speed;
                                // console.log('expected time: ' + expectedtime + ' s')
                                setExpectedUploadTime(Math.ceil(expectedtime));
                                // console.log("Stav uploadu:" + formatBytes(uploadedBytes) + "/" + formatBytes(wholeUploadFileSize))
                                // let expectedUploadTime

                            }
                        }).then(
                            (ress) => {
                                // console.log("upload finished");
                                console.log(ress.data.meta);
                                // console.log(res.data.meta.uploaded);
                                if (ress.data.meta.uploadedCnt > 0) uploadStore.setIsUploading("finished"); else uploadStore.setIsUploading("finished_with_errors")
                                if (ress.data.meta.filesCountInRequest === 0) {
                                    uploadStore.setIsUploading("finished_with_errors")
                                    if (ress.data.meta.reason) setErrorMess(ress.data.meta.reason);
                                }

                                // uploadStore.setWholeUploadProcess(false)
                                setFilesToUpload([]);
                                readModalUploadSelectData()
                                processUploadedFilesQueues(upid);

                            }
                        ).catch((error) => {
                            console.log(error);
                            uploadStore.setIsUploading("finished_with_errors")
                            setErrorMess(error);
                        });

*/




                    }
/*
                        // console.log("can't upload because "+res.data.data.reason);
                        uploadStore.setIsUploading("finished_with_quota_exceeded")
                        // console.log('Je nám ľúto, ale nemáte zakúpenú dostatočnú kapacitu priestoru v systéme piXme.<br />Vaša kapacita: <strong>'+res.data.data.wholeQuota+' MB</strong><br />Obsadené miesto:
                        // <strong>'+res.data.data.wholeUsedCapacity+' MB</strong><br />Súbory na upload: <strong>'+res.data.data.uploadSize+' MB</strong><br />. Pre zvýšenie kapacity kontakujte prosíme sales@pixme.sk.')
                        setErrorMess(`Je nám ľúto, ale nemáte zakúpenú dostatočnú kapacitu priestoru v systéme piXme.<br />Vaša kapacita: <strong>${res.data.data.wholeQuota} MB</strong><br />Obsadené miesto: <strong>${res.data.data.wholeUsedCapacity} MB</strong><br />Súbory na upload: <strong>${res.data.data.uploadSize} MB</strong><br />Pre zvýšenie kapacity kontakujte prosíme sales@pixme.sk alebo 0908 519 314.`)
*/
                  return true;
                }
            ).catch((error) => {
                console.log(error);
                uploadStore.setIsUploading("finished_with_errors")
            });
        }
            console.log("jwt not found")
//            router.push('/', undefined, {shallow: true})
      return true

    }
    const remainingThumbsCount=(filesToUpload.length>3)?filesToUpload.length-3:0;
  // eslint-disable-next-line no-unsafe-optional-chaining


    const acceptedFilesList = filesToUpload.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const rejectedFilesList = rejectedFiles.map(({file,errors}) => (

        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));
    const remainingFilesThumbsDiv = {
        display: 'flex',
        alignItems: 'center'
    };
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: 16
    };
    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };


    const thumbs = filesToUpload.slice(0, 3).map(file => {
        const ext=getExtension(file.name);
        // este doplnit ikony
        switch (ext){
            case 'pdf':
            case 'xlsx':
            case 'pptx':
            case 'mp4':
            case 'docx':
            case 'mov':
                file.preview=`img/ft/${ext}.png`;
                break;
          default: ;
        }

    return(
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    title={file.name}
                    alt={file.name}
                    style={img}
                    // Revoke data uri after image is loaded
                    /* onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }} */
                />

            </div>
        </div>
    )
    });
    const piXmeApiUrl = ')'
    const piXmeApiProcessUploadedQueueUrl = ''

    const readModalUploadSelectData = () => { //
        // console.log("readModalUploadSelectData")
        const jwt=localStorage.getItem('axjwt')
        if(jwt){
            let headers={}
            headers={
                'Authorization': `Bearer ${jwt}`
            }
            const params={
                'action': 'get_data_for_upload_modal'
            }
            axios.get(`https://api.administrix.pro/v1/get/`,{
                headers,
                params
            } )
                .then((response => {
                    // console.log(response)
                    setGalleriesToSelect(response.data.data.galleries);
                    // console.log(response.data.data.galleries.length)
                    // console.log(response.data.data.galleries)
                    // console.log(defaultGallVal)
                    if(response.data.data.galleries) {
                        response.data.data.galleries.unshift(defaultGallVal);
                        setGallListAutocompleteOptions(response.data.data.galleries)
                     //   console.log(response.data.data.galleries)

                    }
                    // console.log(response.data.data.authors)


                      setAllowAiRecognition(!!response.data.data.allow_ai_recognition)
                      setDoAiRecognition(!!response.data.data.allow_ai_recognition)
                      setGetTagsFromFilename(!!response.data.data.get_tags_from_filename)
                      setGetTagsFromFileAdobeKewords(!!response.data.data.get_tags_from_lightroom_keywords)
                      setAdminConcurrency(response.data.data.max_parallel_uploads)




                    if(response.data.data.authors) {
                        response.data.data.authors.unshift(defaultAuthorOptions[1]);
                        response.data.data.authors.unshift(defaultAuthorOptions[0]);
                        setAuthorListAutocompleteOptions(response.data.data.authors)
                      // console.log(response.data.data.authors)
                    }
                    setWatermarksToSelect(response.data.data.watermarks);
                }))
                .catch(error => {
                    // console.log(error);
                })


        }else{
            console.log("jwt not found")
            // router.push('/',undefined,{ shallow: true })

        }

    };

    useEffect(() => {

if(isUploading==='finished') {
  // router.push('/dashboard/pixme/folders')
  console.log('curren location pathname:',location.pathname)
  if (location.pathname === '/dashboard/pixme/folders') {
    // Force reload the page if already on the same URL
    window.location.reload();
  } else {
    // Navigate to the route
    navigate('/dashboard/pixme/folders');
  }

}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isUploading])

    useEffect(() => {
      if(filesToUpload.length) {
        // console.log('files to upload useeffect: nr of files in it:', filesToUpload.length)
        // if(filesToUpload.length<8)renderFilePreviews()
        const wholeUploadFileSizeTmp = filesToUpload.reduce((prevValue,currValue)=>prevValue+currValue.size,0)
        setWholeUploadFileSize(wholeUploadFileSizeTmp)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filesToUpload.length])

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      setFid(searchParams.get('fid'));
        if(user&&isOpenUploadDialog)readModalUploadSelectData(fid)
        // readWatermarksToSelect();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user,isOpenUploadDialog])


    useEffect(() => {
      if(fid) {
        // console.log('fid here:',fid)
        // eslint-disable-next-line no-shadow
        const optionG = gallListAutocompleteOptions.find(optionG => optionG.id === fid);
        // console.log('options here:',gallListAutocompleteOptions)
        // console.log('optionG here:',optionG)
        setGallValue(optionG)
        setToGall(fid)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[gallListAutocompleteOptions])



    const handleGalleryListComboBoxBlur = (event,value, reason) => {
//        console.log("oblur")
//        console.log(toGal)
        // defaultGallVal={label:tog,id:"new"}
    }
    const handleAuthorListComboBoxBlur = (event,value, reason) => {
        // console.log("oblur author")
        // console.log(toAuthor)
        // defaultGallVal={label:tog,id:"new"}
    }
    const handleOptionSelectedOnlyGalClick = (event,value, reason) => {
        /* console.log("onchange")
        console.log(event)
        console.log(value)
        console.log(reason)

         */
        // if (reason === 'clear') {}
        if (reason === 'selectOption') {
            // alert('vybrali ste '+value.label+' a idem smerovat do '+value.id);
         //   Router.push('/gallery/?slug='+value.id);
            setToGall(value.id);
            setToGallName(value.label);
            setGallValue({"label":value.label,"id":value.id})
        }
    };
    const handleOptionSelectedOnlyAuthorClick = (event,value, reason) => {
        /* console.log("onchange author")
        console.log(event)
        console.log(value)
        console.log(reason) */
        // if (reason === 'clear') {}
        if (reason === 'selectOption') {
            // alert('vybrali ste '+value.label+' a idem smerovat do '+value.id);
         //   Router.push('/gallery/?slug='+value.id);
            setToAuthor(value.id);
            setToAuthorName(value.label);
            setAuthorValue({"label":value.label,"id":value.id})
        }
    };
  const handleUploadClick = () => {
    uploadImages();
  };
  const renderFileCountText = () => {
    if (filesToUpload.length === 1) {
      return t('file_nom').toLowerCase();
    } if (filesToUpload.length < 5) {
      return t('files_nom').toLowerCase();
    }
      return 'súborov';

  };
  const galleriesGetOptionLabel = (option) => option.label;
    return (
            <Dialog open={isOpenUploadDialog} onClose={handleClose}     fullWidth
                    maxWidth="lg">
                {isUploading==="in_progress"&&<DialogTitle>{t('upld.uploading_files_to_pixme')}</DialogTitle>}
                {isUploading==="ready"&&<DialogTitle>{t('upld.ready_to_upload')}</DialogTitle>}
                {isUploading==="finished"&&<DialogTitle>{t('upld.files_succ_uploaded')}</DialogTitle>}
                {isUploading==="finished_with_errors"&&<DialogTitle>{t('upld.upload_error_occured')}</DialogTitle>}
                {isUploading==="finished_with_quota_exceeded"&&<DialogTitle>{t('upld.upload_not_enough_space')}</DialogTitle>}
                <DialogContent>

                    {isUploading==="ready"&& <>
                    {/* <TextField fullWidth id="outlined-basic" label="Dátum galérie"  variant="outlined"  type="date" sx={{width:"100%"}}/> */}

                    <FormControl fullWidth sx={{marginBottom:2,marginTop:2}}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="galleryListComboBox"
                            options={gallListAutocompleteOptions}
                            onBlur={handleGalleryListComboBoxBlur}
                            autoComplete
                            getOptionLabel={galleriesGetOptionLabel}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={defaultGallVal}
                            value={gallValue}
                            renderInput={(params) => <TextField {...params} label={t('upld.choose_destination_folder')} />}
                            onChange={handleOptionSelectedOnlyGalClick}

                        />

                    </FormControl>

                    {/* <FormControl fullWidth sx={{marginBottom:2,marginTop:2}}>
                            <InputLabel id="to_gallery_select_label">Kam chcete nahrať fotografie?</InputLabel>
                            <Select
                                labelId="to_gallery_select_label"
                                id="to_gallery_select"
                                name="to_gallery_select"
                                value={toGal}
                                label="Vyberte kam nahrať fotografie"
                                onChange={changeToGallery}
                            >
                                <MenuItem key="new" value="new" >
                                    Do novej galérie
                                </MenuItem>
                                {galleriesToSelect&&galleriesToSelect.map((option) => (
                                    <MenuItem key={option.slug} value={option.slug} >
                                        {option.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl> */}
                      {(toGal==="new")&&<FormControl fullWidth sx={{marginBottom: 2}}><TextField size="small" fullWidth id="outlined-basic" label="Zadajte názov galérie, do ktorej chcete nahrať fotografie"  variant="filled"  type="text" sx={{width:"100%"}} onChange={changeGalleryNewName} value={newGalleryName}/></FormControl>}

                      <FormControl fullWidth sx={{marginBottom: 2}}>
                        <Autocomplete
                          size="small"
                          disablePortal
                          variant="outlined"
                          id="authorListComboBox"
                          options={authorListAutocompleteOptions}
                          onBlur={handleAuthorListComboBoxBlur}
                          autoComplete
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          defaultValue={defaultAuthorVal}
                          value={authorValue}
                          renderInput={(params) => <TextField {...params} label={t('upld.choose_author')}/>}
                          onChange={handleOptionSelectedOnlyAuthorClick}

                        />
                      </FormControl>
                      {(toAuthor === "new") &&
                        <TextField size="small" fullWidth id="new-author-name-ipnut" label={t('upld.type_author_name')} variant="filled" type="text" sx={{width: "100%", marginBottom: 2}} onChange={changeAuthorNewName} value={newAuthorName}/>}



                       <Box

                        sx={{
                          padding: "20px",
                          marginBottom: "20px",
                          borderRadius: "20px",
                          bgcolor: '#8080800D'
                        }}
                      >
                         {isVisibleSettings?<>
                        <strong>Settings:</strong><br/><br/>

                        <FormControl variant="filled" fullWidth sx={{marginBottom: 2}}>
                          <InputLabel id="demo-simple-select-licence_select_label">{t('upld.licence')}</InputLabel>
                          <Select
                            labelId="licence_select_label"
                            id="licence_select"
                            value={licence}
                            label={t('upld.licence')}
                            size="small"
                            onChange={handleLicenceChange}
                          >
                            <MenuItem value={0}>{t('upld.not_setting')}</MenuItem>
                            <MenuItem value={1}>{t('upld.non_exclusive_licence')}</MenuItem>
                            <MenuItem value={2}>{t('upld.exclusive_licence')}</MenuItem>
                            <MenuItem value={3}>{t('upld.other_licence')}</MenuItem>
                          </Select>


                        </FormControl>

                           <FormControl fullWidth sx={{marginBottom:2,marginTop:2}}>
                             <InputLabel id="watermark_select_label">{t('upld.apply_watermark')}</InputLabel>
                             <Select
                               labelId="watermark_select_label"
                               id="watermark_select"
                               name="watermark_select"
                               value={selectedWatermark}
                               label="Vyberte watermark"
                               onChange={changeWatermark}
                               variant="filled"

                             >
                               <MenuItem key="0" value="0" >
                                 {t('upld.no_watermark')}
                               </MenuItem>
                               {watermarksToSelect?.map((option) => (
                                 <MenuItem key={option.id} value={option.id}>
                                   {option.name}
                                 </MenuItem>
                               ))}

                             </Select>
                           </FormControl>



                        {((user.department_role < 2)||(user.ax_role < 2)) &&
                          <>
                        <FormControl variant="filled" fullWidth sx={{marginBottom: 2}}>
                          <FormControlLabel control={<Checkbox/>}  checked={getTagsFromFileAdobeKewords} onChange={handleTagFromFileChange}  label={t('upld.get_tags_from_file_lightroom_keyword')}/>
                        </FormControl>
                        <FormControl variant="filled" fullWidth sx={{marginBottom: 2}}>
                          <FormControlLabel control={<Checkbox/>} checked={getTagsFromFilename} onChange={handleTagFromFilenameChange} label={t('upld.get_tags_from_filename')}/>
                        </FormControl>


                        {(user.ax_role < 2) &&
                          <>

                            {allowAiRecognition &&
                              <FormControl variant="filled" fullWidth sx={{marginBottom: 2}}>
                                <FormControlLabel control={<Checkbox checked={doAiRecognition} onChange={handleDoAiChange} />} label={t('upld.analyze_photos_by_ai')}/>
                              </FormControl>
                            }

                          <FormControl variant="filled" fullWidth sx={{marginBottom: 2}}><TextField size="small" fullWidth id="outlined-basic" label={t('upld.max_concurrent_uploads')} variant="filled" type="number" sx={{width: "100%"}}
                                                                                                    onChange={changeConcurrency} value={adminConcurrency}/></FormControl>

                          </>}
                          </>
                        }

                         </>:<>

                        <strong>{t('upld.upload_files_with_this_settings')}:</strong><br/>
                           <div>
                             <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap',marginBottom:"20px",marginTop:"20px" }}>
                          <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.licence')}: ${selectedLicenceText}`} variant="outlined" />
                          <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.apply_watermark')}: ${selectedWatermarkName}`} variant="outlined" />

                               {((user.department_role < 2)||(user.ax_role < 2)) &&
                               <>
                               <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.tags_from_file')}: ${(getTagsFromFileAdobeKewords?t('yes'):t('no')).toLowerCase()}`} variant="outlined" />
                               <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.tags_from_filename')}: ${(getTagsFromFilename?t('yes'):t('no')).toLowerCase()}`} variant="outlined" />

                               {(user.ax_role < 2) &&
                                 <>
                                 <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.analyze_photos_by_ai')}: ${(doAiRecognition?t('yes'):t('no')).toLowerCase()}`} variant="outlined" />
                                   <Chip sx={{fontWeight:"normal",color:"#8c8c8c"}} label={`${t('upld.max_concurrent_uploads')}: ${adminConcurrency}`} variant="outlined" />
                                   </>
                               }

                               </>
                               }
                        </Stack>



                           </div>
                    </>}


                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<SettingsIcon />}
                          onClick={handleToggleSettings}
                          sx={{marginBottom:"15px"}}
                          size="small"
                        >
                          <Typography>
                            {isVisibleSettings ? t('upld.hide_setting') : t('upld.change_setting')}
                          </Typography>
                        </Button>

            </Box>






                    {!filesToUpload.length&&
                    <PixmeUpload filesToUpload={filesToUpload} rejectedFiles={rejectedFiles} setFilesToUpload={setFilesToUpload}/>
                    }
                    {/*                    <aside style={thumbsContainer}>
                        {thumbs}
                        {remainingThumbsCount>0&&<div key="remaining" style={remainingFilesThumbsDiv}>
                            ...(+{remainingThumbsCount})
                        </div>}
                    </aside>
*/}                    {!!filesToUpload.length&&
                      <>
                      <Paper sx={{m:"15px",color:"green",fontSize:"1em",display:"flex",justifyContent:"center",fontWeight:"bold"}}>
                        {t('upld.files_are_ready_to_upload')}
                      </Paper>


                      <Button sx={{backgroundColor:"#107c2f",color:"white",p:"16px",fontSize:"1.1em"}} fullWidth variant="contained"  onClick={handleUploadClick} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <CloudUploadIcon sx={{ fontSize: "30px" }} />
                          <Box sx={{ mt: 1, textAlign: 'center' }}>
                            {t('upld.do_upload')} {filesToUpload.length} {renderFileCountText()} {t('upld.into_pixme')} ({fData(wholeUploadFileSize)})

                          </Box>
                        </Box>
                    </Button>
                      </>
                    }
                    </>}

                    {isUploading==="in_progress"&& <Box sx={{width: '100%', textAlign: "center"}}>
                      {t('upld.into_pixme')} {filesToUpload.length} {renderFileCountText()}
                      {newGalleryName && <>
                        <br/>
                        {t('upld.into_gallery')} {newGalleryName}
                      </>
                      }
                      <br/>
                      <LinearProgressWithLabel value={uploadingProcess}/>
                      <br/>
                      {t('upld.upload_status')}:
                      {uploadedBytes&&<><br/>
                        {fData(uploadedBytes)} / {fData(wholeUploadFileSize)}
                    </>}

                      <br/>
                      {fNumber(alreadyUploadedCount)} / {fNumber(filesToUpload.length)}

                      {expectedUploadTime > 0 && <><br/>{t('upld.estimated_upload_time')}: {convertTime(expectedUploadTime)}</>}
                    </Box>}

                  {!!filesToUpload.length&&
                    <Grid xs={12} sx={{display:"flex",justifyContent:"center", p:"10px"}}>
                      <Button
                        variant="contained"
                        sx={{backgroundColor:"#38588a",color:"white",m:"10px"}}
                        onClick={handleShowMoreToggle}
                        startIcon={expandedFilesInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      >
                        {expandedFilesInfo ? t('upld.show_less') : t('upld.show_details')}
                      </Button>
                    </Grid>}


                  {isUploading==="finished"&& <>
                        <Box sx={{ width: '100%',display:"flex",justifyContent:"center",marginBottom:"10px" }}>
                            {isUploadedFilesQueueProcessing&&<div>{t('upld.now_processing_uploaded_files')}</div>}
                            {!isUploadedFilesQueueProcessing&&<CheckCircleOutlineIcon sx={{color:"darkgreen",fontSize:"6em"}}/>}
                        </Box>
                        {isUploadedFilesQueueProcessing&&<LinearProgress />}
                        {!isUploadedFilesQueueProcessing&&
                        <Button fullWidth variant="contained" onClick={()=>uploadStore.setIsUploading("ready")}>
                          {t('upld.upload_next_files')}
                        </Button>}
                    </>}
                    {isUploading==="finished_with_errors"&& <Box sx={{ width: '100%',display:"flex",justifyContent:"center" }}>
                        <ErrorOutlineIcon sx={{color:"darkorange",fontSize:"6em"    }}/>
                            <div dangerouslySetInnerHTML={{ __html: errorMess }} />
                        </Box>}
                    {isUploading==="finished_with_quota_exceeded"&& <>
<div dangerouslySetInnerHTML={{ __html: errorMess }} />
                        <Box sx={{ width: '100%',display:"flex",justifyContent:"center" }}>


                        <ErrorOutlineIcon sx={{color:"red",fontSize:"6em"    }}/>
                        </Box>
                    </>}

                  {expandedFilesInfo&&<Grid xs={12}>
                    {!!filesToUpload.length&&isUploading==="ready"&&<Typography variant="h5" sx={{textAlign:"center"}}>{t('upld.files_are_ready_to_upload')}</Typography>}

                    {isUploading==="finished"||isUploading==="finished_with_errors"&&<Typography variant="h5" sx={{textAlign:"center"}}>{t('upld.uploaded_files')}</Typography>}

                    <TableContainer component={Paper}>
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">{t('upld.preview')}</TableCell>
                            <TableCell align="left">{t('upld.name')}</TableCell>
                            <TableCell align="center">{t('upld.file_size')}</TableCell>
                            <TableCell align="center">{t('upld.status')}</TableCell>
                            {isUploading==="ready"&&<TableCell align="center">{t('upld.remove_from_upload')}</TableCell>}
                          </TableRow>
                        </TableHead>
                    <TableBody>
                      {filesToUpload?.map((item, index) => (
                        <TableRow key={index} variant="subtitle1" >
                          <TableCell sx={{maxWidth:"300px"}} align="center"> {item.preview?<Image sx={{maxWidth:"200px",borderRadius:"10px"}}
                            src={item.preview}/>:<Tooltip title={t('upld.preview_appears_after_upload')}><PhotoIcon /></Tooltip> }</TableCell>
                          <TableCell>{item.name}
                            {/*
                            {item.aiTagsSk && (
                              <Tooltip title={item.aiTagsSk}>
                                <SellIcon sx={{ marginLeft: "5px", marginRight: "5px", color: "#fafafa" }} />
                              </Tooltip>
                            )}

                            {item.aiTagsEn && (
                              <Tooltip title={item.aiTagsEn}>
                                <SellIcon sx={{ marginLeft: "5px", marginRight: "5px", color: "#fafafa" }} />
                              </Tooltip>
                            )}
*/ }
                          </TableCell>
                          <TableCell align="center">{fData(item?.size)}</TableCell>

                          <TableCell align="center">
                            {item.status.includes("loading")&&<LinearProgressWithLabel  color="success" value={parseInt(item.progress,10)} />}
                            {item.status.includes("processing")&&<Tooltip title={t('upld.file_is_now_processing')}><CircularProgress color="success" /></Tooltip>}
                            {item.status.includes("uploaded") && (
                              <Tooltip title={`${t('upld.file_was_processed_in')} ${parseFloat(item.execution_time).toFixed(2)} s`}>
                                <DoneIcon color="success" />
                              </Tooltip>
                            )}



                              {item.status.includes("failed")&&<Tooltip title={t('upld.upload_failed')}><FeedbackIcon  color="error" /></Tooltip>}
                            {item.status.includes("before")&&<Tooltip title={t('upld.file_is_ready_to_upload')}><AlarmOnIcon  color="success" /></Tooltip>}


                          </TableCell>
                          {isUploading==="ready"&&<TableCell align="center">{item.status.includes("before")&&<Tooltip title={t('upld.remove_this_file_from_upload')}><DeleteForeverIcon color="error" onClick={()=>eraseFile(item.id)} sx={{cursor:"pointer"}} /></Tooltip>}</TableCell>}
                        </TableRow>

                      ))}
                    </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>}

                </DialogContent>
                <DialogActions>
                  {(!!filesToUpload.length&&expandedFilesInfo&&isUploading==="ready")&&
                  <Button sx={{backgroundColor:"#107c2f",color:"white",}}  variant="contained"  onClick={handleUploadClick} >

                      <CloudUploadIcon sx={{marginRight:"10px"}}/> {t('upld.upload_files')}


                  </Button>}

                  {(!!filesToUpload.length&&!isUploading.includes("finished"))&&<Button
                    variant="contained"
                    sx={{backgroundColor:"#e0511f",color:"white",m:"10px"}}
                    onClick={handleCancelUploadAndStartNew}
                    startIcon={<HighlightOffIcon />}
                  >
                    {t('upld.cancel_upload')}
                  </Button>}

                    <Button onClick={handleClose}>{t('close')}</Button>
                    {/* <Button onClick={()=>handlePhpProgress(5)}>test php progress</Button> */}
                </DialogActions>
            </Dialog>

    );
}
UploadDialog.propTypes = {
  isOpenUploadDialog:PropTypes.bool,
  setIsOpenUploadDialog:PropTypes.func,

};
