import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useForm,useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import {_roles, _tags, USER_STATUS_OPTIONS} from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import SettingsIcon from '@mui/icons-material/Settings';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {RHFSelect, RHFTextField, RHFAutocomplete, RHFEditor, RHFUpload, RHFSwitch, RHFUploadAvatar} from 'src/components/hook-form';
import {AccordionDetails, Autocomplete, CircularProgress, LinearProgress, TextField, Tooltip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from '@mui/icons-material/Link';
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';
import Card from "@mui/material/Card";
import {QueuePlayNext} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useTranslation} from "react-i18next";
import {useAxStore} from "../../store/axStore";
import axios from "../../utils/axios";
import {useResponsive} from "../../hooks/use-responsive";
import ConfirmDepartmentDelete from "./ConfirmDepartmentDelete";
import {useAuthContext} from "../../auth/hooks";
import CompanyQuickEditForm from "../company/company-quick-edit-form";
import UserQuickEditForm from "../user/user-quick-edit-form";
import {useBoolean} from "../../hooks/use-boolean";
import Label from "../../components/label";
import Ax4UserDepIncQuickEditForm from "../../modal/ax4-user-dep-setting";
import Ax5UserDepIncQuickEditForm from "../../modal/ax5-user-dep-setting";
import UnlinkUserFromDepartment from "../../modal/unlink_user_from_department";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k**i).toFixed(dm))  } ${  sizes[i]}`;
}
function ConvertToNiceDate(mysql_timestamp) {
  // console.log('ConvertToNiceDate working with ',mysql_timestamp);
  if((mysql_timestamp!=="0000-00-00 00:00:00")&&(mysql_timestamp!=="0000-00-00")) {
    const optionsDate = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
    };
    const optionsDay = {
      weekday: 'long'
    };

    const dateFormatter = new Intl.DateTimeFormat('sk-SK', optionsDate);
    const timeFormatter = new Intl.DateTimeFormat('sk-SK', optionsTime);
    const dayFormatter = new Intl.DateTimeFormat('sk-SK', optionsDay);
    const timestamp_created = new Date(mysql_timestamp);
    // return dateFormatter.format(timestamp_created);

    // eslint-disable-next-line no-unreachable
    const niceDateObject={
      niceDate:dateFormatter.format(timestamp_created),
      niceTime: timeFormatter.format(timestamp_created),
      niceday: dayFormatter.format(timestamp_created)
    }
    return niceDateObject
  }return false;
} // returns an object with date, time, day_in_week_by_name
/*
function ConvertToNiceTime(mysql_timestamp){
  if(mysql_timestamp!=="0000-00-00 00:00:00") {
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
    };

    const timeFormatter = new Intl.DateTimeFormat('sk-SK', optionsTime);
    const timestamp_created = new Date(mysql_timestamp);
    return timeFormatter.format(timestamp_created);
  }return false;
}
*/


export default function DepartmentQuickEditForm({ depIdToEdit, setDepIdToEdit, open, onClose,currentDepAsProp, setDepOldIdToEdit, depOldIdToEdit,loadDeps }) {
  const mdUp = useResponsive('up', 'md');
  const [paymentOverview,setPaymentOverview] = useState({})
  const [myCompanyId,setMyCompanyId]=useState(0)
  const [needReloadDepEditData,setNeedReloadDepEditData]=useState(0)
  const [companyOfDepId,setCompanyOfDepId]=useState(0)
  const [myCompanyEditOpen,setMyCompanyEditOpen]=useState(false)
  const [isDepartmentMainCompany,setIsDepartmentMainCompany]=useState(1) // 1=my company, 2=partners
  const [invoiceDates,setInvoiceDates] = useState([])
  const [currentDep,setCurrentDep] = useState({})
  const [deleteDepConfirmModalIsOpen,setDeleteDepConfirmModalIsOpen] = useState(false)
  const [depsLoading,setDepsLoading] = useState(false)
  const [isInvoiceDatesCounting,setIsInvoiceDatesCounting] = useState(true)
  const [currentDepAllLoadedData,setCurrentDepAllLoadedData] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState(false)
  const [linkingToCompany, setLinkingToCompany] = useState(false)
  const [companies, setCompanies] = useState([])
  const [dialogWidth, setDialogWidth] = useState("md")
  const [labelForAutocompleteCompanies, setLabelForAutocompleteCompanies] = useState("Vyberte subjekt, ktorý je priradená tomuto departmentu")
  const axStore=useAxStore()
  const [languages, setLanguages] = useState([])
  const { t, onChangeLang } = useTranslation()
  const user=axStore.axCurrentUser
  const autocomplete=true;
  const [curDepSelectbox,setCurDepSelectbox]=useState('');
  const [curCompSelectbox,setCurCompSelectbox]=useState('');
  const [isSwitchingWeb,setIsSwitchingWeb]=useState(false);
  const [isSavingDepData,setIsSavingDepData]=useState(false);
  const [webNameSwitchingTo,setWebNameSwitchingTo]=useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const currentYearForSel = new Date().getFullYear();
  const runOnClose = () => {loadDepDataToEdit(currentDep.id)};
  // *****user states
  const[userToEdit,setUserToEdit]=useState({})

  const[userIdToEdit,setUserIdToEdit]=useState(0)
  const[userOldIdToEdit,setUserOldIdToEdit]=useState(0)
  const[depIdForUserToEdit,setDepIdForUserToEdit]=useState(0)
  const ax4DepUserIdEdit = useRef(0)
  const[ax4ModalOpen,setAx4ModalOpen]=useState(false)
  const ax5DepUserIdEdit = useRef(0)
  const[ax5ModalOpen,setAx5ModalOpen]=useState(false)
  const unlinkUserIdEdit = useRef(0)
  const[unlinkUserModalOpen,setUnlinkUserModalOpen]=useState(false)
  const userQuickEditModal = useBoolean(false);
  const userAx4DepEditModal = useBoolean(false);



  // Generate an array of 10 options
  const yearOptions = Array.from({ length: 10+(currentYearForSel-2018) }, (_, index) => {
    const year = 2017 + index;
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  });
  const [currentTab, setCurrentTab] = useState('zakladne-nastavenie');
  const handleCloseModal = () => {
    // enqueueSnackbar('closing')
    if(loadDeps)loadDeps()
    onClose()
  }
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const onError = (errors, e) => {
    enqueueSnackbar("Chyba pri validácii, chck console",{ variant: `error`,autoHideDuration: 6000 });
    // console.log('submit validation error:',errors, e)
  }

  const switchToDep = (value) => {  // value has id and web label
// console.log('switching to ',value.id)
    // alert(`vybrali ste ${value.label} a idem smerovat do ${value.id}`);
    setIsSwitchingWeb(true)
    setWebNameSwitchingTo(value.label)

    setDepIdToEdit(parseInt(value.id,10))
    loadDepDataToEdit(parseInt(value.id,10))
  }
  const handleOptionSelectedDepClick = (event,value, reason) => {
     // console.log("onchange dep from department modal edit")
    // console.log(event)
    // console.log(value)
     console.log(reason)
    if (reason === 'selectOption') switchToDep(value)
  };
  const handleOptionSelectedCompClick = (event,value, reason) => {
     // console.log("onchange company")
    // console.log(event)
    // console.log(value)
    // console.log(reason)
    // if (reason === 'selectOption') switchToDep(value)
  };

  const loadDepsToAxStore = () => {
    setDepsLoading(true)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_deps'
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if (data.deps) {
              axStore.setAxDepartments(data.deps)
            }
          }else
          if (response.data.error) {
            const {error} = response.data;
            // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
          }

          setDepsLoading(false)

        }))
        .catch(error => {
          setDepsLoading(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
    }





  }

  const NewUserSchema = Yup.object().shape({
    depname: Yup.string().when('depIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.required('Názov departmentu je povinná položka'),
      otherwise: schema => schema.notRequired()
    }),
    /* description: Yup.string().when('depIdToEdit', {
       is: depId => depId !== "0",
      // is: true,
      then: schema=>schema.required('Popis departmentu je povinná položka'),
      otherwise: schema => schema.optional()
    }), */
    website_canonic_url: Yup.string().when('depIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: schema => schema.notRequired()
    }),
    /* website_canonic_url: Yup.string().when('depIdToEdit', {
      is: depId => depId > 0,
      then: Yup.string().url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: Yup.string().nullable()
    }) */
    // name: Yup.string().required('Názov departmentu je povinná položka'),
    // website_canonic_url: Yup.string().url('Táto položká musí byť v tvare webovej adresy, napr. https://pixme.sk').nullable(),
    // description: Yup.string().required('Name is required'),
    /* email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    role: Yup.string().required('Role is required'), */
  });

  const defaultValues = useMemo(
    () =>({
      /* -------------- NOVY DEPARTMENT  ----------------*/
        name_new:'',
        description_new:'',
       /* -------------- ZAKLADNE NASTAVENIA  ----------------*/
        // na riadku cca 611 povolit defaultne nastavit depas prop
        // dep_visible: currentDep?.visible==="1"?true: currentDep?.visible !== "0",
        // dep_visible: currentDep?.visible==="1"?true: false,
        // dep_visible: currentDep?.visible === "1",
        // depname: currentDep?.name || '',
//        dep_visible: currentDep && currentDep.dep_visible&& currentDep.dep_visible==="1" ? true : false,

  logoUrl: currentDep && currentDep.logo_version>0 ? `${axStore.systemCurrentConfig.s3.s3_endpoint_system}dep_logos/${currentDep.hashed_id}.${currentDep.logo_extension}?${currentDep.logo_version}`:'',

        dep_visible: !!(currentDep && currentDep.visible && currentDep.visible === 1),

        we_provide_website_for_this_department: !!(currentDep && currentDep.we_provide_website_for_this_department && currentDep.we_provide_website_for_this_department === "1"),
        is_pixme: currentDep && currentDep?.is_pixme === 1,
        is_administrix: currentDep && currentDep?.is_administrix === 1,
        is_events: currentDep && currentDep?.is_events === 1,
        is_wiam: currentDep && currentDep?.is_wiam === 1,


        def_lang: currentDep && currentDep?.lang ? currentDep.lang : '',
        depname: currentDep && currentDep?.name ? currentDep.name : '',
        max_users: (currentDep && currentDep?.max_users>0) ? currentDep?.max_users : -1,
      default_pixcoins: (currentDep && currentDep?.default_pixcoins) ? currentDep?.default_pixcoins : 0,
      remaining_pixcoins: (currentDep && currentDep?.remaining_pixcoins) ? currentDep?.remaining_pixcoins : 0,
        max_parallel_uploads: currentDep && currentDep?.pixme?.max_parallel_uploads ? currentDep.pixme.max_parallel_uploads : 5,

        pixme_quota: currentDep && currentDep?.pixme?.quota ? currentDep.pixme.quota : 0,
        pixme_allow_ai_recognition: currentDep && currentDep?.pixme?.allow_ai_recognition === 1,
        pixme_get_tags_from_filename: currentDep && currentDep?.pixme?.get_tags_from_filename === 1,
        pixme_tags_from_lightroom_keywords: currentDep && currentDep?.pixme?.get_tags_from_lightroom_keywords === 1,




          //        depname: '',
        // description: currentDep?.description || '',
        // concated_domains: currentDep?.domains_concated || '',
        description: currentDep && currentDep.description ? currentDep.description : '',
        department_admin_note: currentDep && currentDep.admin_note ? currentDep.admin_note : '',
        concated_domains: currentDep && currentDep.domains_concated ? currentDep.domains_concated : '',

        /* -------------- MULTIOBEC  ----------------*/
      is_multiobec_system: !!(currentDep && currentDep.is_multiobec_system && currentDep.is_multiobec_system === "1"),

      web_domain_tiny: currentDep?.multiobec?.web_domain_tiny || '',
      web_type: currentDep?.multiobec?.web_type || 0,

        /* -------------- ZAKLADNE NASTAVENIA  ----------------*/

        website_status: currentDep?.website_config?.status || "0",
        website_name: currentDep?.website_config?.website_name || '',
        website_canonic_url: currentDep?.website_config?.website_canonic_url || '',
        website_title: currentDep?.website_config?.website_title || '',
        website_description: currentDep?.website_config?.website_description || '',
        website_author: currentDep?.website_config?.website_author || '',

        website_image_width: currentDep?.website_config?.website_image_width || "1200",
        website_image_height: currentDep?.website_config?.website_image_height || "1200",
        website_image_thumb_width: currentDep?.website_config?.website_image_thumb_width || "500",
        website_image_thumb_height: currentDep?.website_config?.website_image_thumb_height || "500",

        website_images_sorting: currentDep?.website_config?.website_images_sorting || "1",
        website_documents_sorting: currentDep?.website_config?.website_documents_sorting || "1",
        /*
                website_show_documents_date: currentDep?.website_config?.website_show_documents_date==="1"?true: false,
                website_show_hash_file_path_date: currentDep?.website_config?.hash_file_path==="1"?true: false,
                website_show_title_photo_in_gallery: currentDep?.website_config?.website_show_title_photo_in_gallery==="1"?true: false,
                website_show_title_photo: currentDep?.website_config?.website_show_title_photo==="1"?true: false,
                website_show_image_gallery: currentDep?.website_config?.website_show_image_gallery==="1"?true: false,
                website_show_documents_table: currentDep?.website_config?.website_show_documents_table==="1"?true: false,
        */
        website_show_documents_date: currentDep?.website_config?.website_show_documents_date === "1",
        website_show_hash_file_path_date: currentDep?.website_config?.hash_file_path === "1",
        website_show_title_photo_in_gallery: currentDep?.website_config?.website_show_title_photo_in_gallery === "1",
        website_show_title_photo: currentDep?.website_config?.website_show_title_photo === "1",
        website_show_image_gallery: currentDep?.website_config?.website_show_image_gallery === "1",
        website_show_documents_table: currentDep?.website_config?.website_show_documents_table === "1",

        /* website_show_hash_file_path_date: currentDep?.website_config?.hash_file_path==="1"?true: currentDep?.website_config?.hash_file_path !== "0",
        website_show_title_photo_in_gallery: currentDep?.website_config?.website_show_title_photo_in_gallery==="1"?true: currentDep?.website_config?.website_show_title_photo_in_gallery !== "0",
        website_show_title_photo: currentDep?.website_config?.website_show_title_photo==="1"?true: currentDep?.website_config?.website_show_title_photo !== "0",
        website_show_image_gallery: currentDep?.website_config?.website_show_image_gallery==="1"?true: currentDep?.website_config?.website_show_image_gallery !== "0",
        website_show_documents_table: currentDep?.website_config?.website_show_documents_table==="1"?true: currentDep?.website_config?.website_show_documents_table !== "0",

         */

        /* -------------- DB & FTP  ----------------*/
        // db_visible: currentDep?.access_data?.db?.visible==="1"?true: currentDep?.visible !== "0",
        // db_visible: currentDep?.access_data?.db?.visible==="1"?true: false,
        db_visible: currentDep?.access_data?.db?.visible === "1",
        db_server: currentDep?.access_data?.db?.db_server || '',
        db_user: currentDep?.access_data?.db?.db_user || '',
        db_password: currentDep?.access_data?.db?.db_password || '',
        db_name: currentDep?.access_data?.db?.db_name || '',
        dsn_local: currentDep?.access_data?.db?.dsn_local || '',
        phpmyadmin: currentDep?.access_data?.db?.phpmyadmin || '',
        db_port: currentDep?.access_data?.db?.db_port || '',
        db_server_local: currentDep?.access_data?.db?.db_server_local || '',
        db_server_unix_socket: currentDep?.access_data?.db?.db_server_unix_socket || '',


        // ftp
        // ftp_visible: currentDep?.access_data?.ftp?.visible==="1"?true: false,
         ftp_visible: currentDep?.access_data?.ftp?.visible === "1",
        // ftp_visible: currentDep?.access_data?.ftp?.visible==="1"?true: currentDep?.visible !== "0",
        ftp_server: currentDep?.access_data?.ftp?.ftp_server || '',
        ftp_name: currentDep?.access_data?.ftp?.ftp_name || '',
        ftp_password: currentDep?.access_data?.ftp?.ftp_password || '',
        ftp_dir: currentDep?.access_data?.ftp?.ftp_dir || '',

      /* -------------- NASTAVENIE FAKTURACIE  ----------------*/
      invoicing_with_invoice: currentDep?.payment?.invoicing_with_invoice === "1",
      payment_is_active: currentDep?.payment?.is_active === "1",
      payment_name: currentDep?.payment?.payment_name || '',
      payment_due_days: currentDep?.payment?.due_days || 14,
      payment_zdan_plnenie: currentDep?.payment?.zdanitelne_plnenie,
      payment_invoice_item: currentDep?.payment?.invoice_item || '',
      payment_apply_discount: currentDep?.payment?.apply_discount === "1",
      payment_periodicity_old: currentDep?.payment?.periodicity_old || 1,
      payment_base_price_old: currentDep?.payment?.base_price_old || 0,
      payment_gb_included_in_hosting: currentDep?.payment?.gb_included_in_hosting || 2,
      payment_over_prepaid: currentDep?.payment?.gb_over_prepaid || 0,
      payment_admin_interface: currentDep?.payment?.admin_interface || '',
      payment_department_data_size: currentDep?.payment?.department_data_size || 0,
      payment_department_data_size_comment: currentDep?.payment?.department_data_size_comment || '',
      payment_submitted: currentDep?.payment?.submitted || '',
      note: currentDep?.payment?.payment || '',

      payment_price_admin_interface: currentDep?.payment?.price_admin_interface || '0',
      payment_price_content_support: currentDep?.payment?.price_content_support || '0',
      payment_price_domain: currentDep?.payment?.price_domain || '0',
      payment_domain_count: currentDep?.payment?.domain_count || '0',
      payment_price_hosting: currentDep?.payment?.price_hosting || '0',
      payment_price_technical_support: currentDep?.payment?.price_technical_support || '0',
      payment_hours_prepaid_in_month_technical_support: currentDep?.payment?.hours_prepaid_in_month_technical_support || '',
      payment_hours_prepaid_in_month_content_support: currentDep?.payment?.hours_prepaid_in_month_content_support || '',
      payment_price_website_rental: currentDep?.payment?.price_website_rental || '0',
      payment_extra_price: currentDep?.payment?.extra_price || '0',
      payment_extra_price_comment: currentDep?.payment?.extra_price_comment || '',
      payment_periodicity_current: currentDep?.payment?.periodicity || 1,
      payment_invoice_period: currentDep?.payment?.invoice_period || 2,
      payment_invoice_day: currentDep?.payment?.invoice_day || 0,
      payment_invoice_month_before: currentDep?.payment?.invoice_month_before || 1,

      payment_price_webspace: currentDep?.payment?.price_webspace || '0',
      payment_discount_3_months: currentDep?.payment?.discount_3months_period || '0',
      payment_discount_6_months: currentDep?.payment?.discount_6months_period || '0',
      payment_discount_12_months: currentDep?.payment?.discount_12months_period || '0',
      payment_invoice_start_month: currentDep?.payment?.invoice_start_month || '0',
      /* payment_invoice_start_year: currentDep?.payment?.invoice_start_year || '0', */
      payment_invoice_start_year: currentDep?.payment?.invoice_start_year === "0" || !currentDep?.payment ? new Date().getFullYear() : currentDep.payment.invoice_start_year,

      // companies_autocomplete: companies || [],
    }),
// eslint-disable-next-line react-hooks/exhaustive-deps
    // [currentDep === null ? null : currentDep.id]
// eslint-disable-next-line react-hooks/exhaustive-deps
     [currentDep]
  );



  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    dirtyFields,
    setIsDirty,
    setValue,
    getValues,
    watch,
    control,
    formState: { isSubmitting, isDirty,touchedFields },
  } = methods;

  const generateTabs = () => {
    const defaultTabs = [
      {
        value: 'zakladne-nastavenie',
        label: t('dep.basic_settings'),
        icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
      },
      {
        value: 'uzivatelia',
        label: t('dep.user_accounts'),
        icon: <Iconify icon="solar:user-id-bold" width={24} />,
      }
    ];

    if (user.ax_role<2||user.department_role===1) {
      if (getValues('we_provide_website_for_this_department')) {

        defaultTabs.push(
          {
            value: 'web-config',
            label: 'Web config',
            icon: <Iconify icon="solar:settings-bold" width={24}/>,
          },
          {
            value: 'db-ftp',
            label: 'DB a FTP',
            icon: <Iconify icon="solar:database-bold" width={24}/>,
          },
          {
            value: 'nastavenie-fakturacie',
            label: 'Nastavenie fakturácie',
            icon: <Iconify icon="solar:money-bag-bold" width={24}/>,
          })
      }
      defaultTabs.push(
        /*
                  {
                    value: 'logy',
                    label: 'Logy',
                    icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
                  },

                 {
                  value: 'todo',
                  label: 'Todo',
                  icon: <Iconify icon="solar:battery-charge-minimalistic-bold" width={24} />,
                },


                {
                  value: 'vystavene-faktury',
                  label: 'Vystavené faktúry',
                  icon: <Iconify icon="solar:money-bag-bold" width={24} />,
                },
                {
                  value: 'posli-email',
                  label: 'Pošli email',
                  icon: <Iconify icon="material-symbols:mail" width={24} />,
                },{
                  value: 'dokumenty',
                  label: 'Dokumenty',
                  icon: <Iconify icon="solar:documents-bold" width={24} />,
                } */
      );
    }


    return defaultTabs;
  };
  const TABS = generateTabs()

  const isPaymentActive = getValues('payment_is_active');
  const paymentActiveLabel = isPaymentActive
    ? 'Pravidelná fakturácia je aktívna'
    : 'Pravidelná fakturácia nie je aktívna';

 const invoicing_with_invoice = getValues('invoicing_with_invoice');
  const invoicing_with_invoice_label = invoicing_with_invoice
    ? 'Vystaviť faktúru'
    : 'Bez faktúry';


function recalculateInvoiceDates(startInvoicingDate=null,invoiceDatesCount=10,periodicity=0) {
  setIsInvoiceDatesCounting(true)
  // enqueueSnackbar('recalculating invoice dates...')
  // return array of objects invoice dates, from given day.if null,starts todat
  setInvoiceDates([])
  let startDateForRenderInvoiceTo
  function getLastDayOfMonth(year, month) {
    const lastDayOfMonth = new Date(year, month , 0);
    //  console.log("message from function getLastDayOfMonth with parameters year:",year,", month:", month, "and returns ", lastDayOfMonth.getDate())
    return lastDayOfMonth.getDate();

  }

  if(!startInvoicingDate) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    startDateForRenderInvoiceTo = `${year}-${month}-${day}`;
  }else  startDateForRenderInvoiceTo=startInvoicingDate
  // const updatedInvoiceDates = { ...invoiceDates }
  const veryCurrentDay=new Date(startInvoicingDate);
  let tempStartDate = new Date(startDateForRenderInvoiceTo)
  const newInvoicePeriods = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= invoiceDatesCount; i++) {
// console.log('-------------------------------------------------------------')
// console.log('---------- ZACINAM CYKLUS i=',i,'----------------------------')
// console.log('-------------------------------------------------------------')
    const updatedInvoiceDates = {}
    updatedInvoiceDates.veryCurrentYear=veryCurrentDay.getFullYear()
    updatedInvoiceDates.veryCurrentMonth=veryCurrentDay.getMonth()+1
    updatedInvoiceDates.veryCurrentDay=veryCurrentDay.getDate()
    updatedInvoiceDates.invoicePeriodicity = parseInt(getValues('payment_periodicity_current'), 10) // 1,3,6,12

    // calculate date for next iteration
    const today = new Date(tempStartDate)
    // today is date simulated for generating invoice to
    // today.setMonth(tempStartDate.getMonth() + updatedInvoiceDates.invoicePeriodicity * i);
    // console.log("today before", today)
    // console.log("tempStartDate before", tempStartDate)
    // console.log("tempStartDate.getMonth()", tempStartDate.getMonth())
    // console.log("updatedInvoiceDates.invoicePeriodicity", updatedInvoiceDates.invoicePeriodicity)
    if(i) {

      today.setMonth(tempStartDate.getMonth() + updatedInvoiceDates.invoicePeriodicity);
      // ak pri pridani mesiacov presiahnem na dalsi mesiac, pretoze mozu mat rozne dlzky mesiacov, tak sa vratim na predchadzajuci: je to fix vzniknutej issue
      if(((tempStartDate.getMonth()+updatedInvoiceDates.invoicePeriodicity)>12)&&(today.getMonth()>(tempStartDate.getMonth()+updatedInvoiceDates.invoicePeriodicity-12)))today.setDate(0)
    }
    // console.log("today to count", today)
    // console.log("today.getMonth()", today.getMonth())


// CURRENT DAY DAYS - not neccessary current, but day which are we calculating invoice dates TP
    updatedInvoiceDates.currentMonth = today.getMonth() + 1;
    updatedInvoiceDates.nextMonth = ((updatedInvoiceDates.currentMonth + 1) > 12) ? 1 :updatedInvoiceDates.currentMonth + 1;
    updatedInvoiceDates.currentDay = today.getDate();
    updatedInvoiceDates.currentYear = today.getFullYear();
    updatedInvoiceDates.nextYear = updatedInvoiceDates.currentYear + 1;
    // VALUES FROM FORM
    updatedInvoiceDates.invoicePeriod = parseInt(getValues('payment_invoice_period'), 10)
    updatedInvoiceDates.invoiceDueDays = parseInt(getValues('payment_due_days'), 10)
        updatedInvoiceDates.letIssueInvoiceMonthBefore = parseInt(getValues('payment_invoice_month_before'), 10)
    updatedInvoiceDates.invoiceItem = getValues('payment_invoice_item')
    updatedInvoiceDates.paymentInvoiceDayFromForm = parseInt(getValues('payment_invoice_day'), 10);
    // RENDERED VALUES
    updatedInvoiceDates.invoicePeriodText = (updatedInvoiceDates.invoicePeriod === 2) ? 'vopred' : 'spätne'
    if ((updatedInvoiceDates.paymentInvoiceDayFromForm === 0)) updatedInvoiceDates.paymentInvoiceDayFromForm = (updatedInvoiceDates.invoicePeriod === 2 ? 1 : 50); // ak automaticky. ak50, znamena, ze musim prepocitat na posledny den
    updatedInvoiceDates.finalInvoiceIssueDay = updatedInvoiceDates.paymentInvoiceDayFromForm;
    updatedInvoiceDates.paymentInvoiceStartMonthFromForm = parseInt(getValues('payment_invoice_start_month'), 10);
    updatedInvoiceDates.paymentInvoiceStartYearFromForm = parseInt(getValues('payment_invoice_start_year'), 10);

    // SET invoice_issue_year value depending form invoicing year FROM
    let invoice_issue_year = updatedInvoiceDates.paymentInvoiceStartYearFromForm > updatedInvoiceDates.currentYear ? updatedInvoiceDates.paymentInvoiceStartYearFromForm : updatedInvoiceDates.currentYear

    // SET invoice_issue_month value depending form invoicing year and month FROM
    let invoice_issue_month = updatedInvoiceDates.currentMonth;
    if((updatedInvoiceDates.paymentInvoiceStartYearFromForm > updatedInvoiceDates.currentYear)&&(updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0))invoice_issue_month=1;

// console.log("Prepocitane hodnoty invoice_issue_year:",invoice_issue_year," a invoice_issue_month:",invoice_issue_month)

    switch (updatedInvoiceDates.invoicePeriodicity) {
      case 1:


        if (updatedInvoiceDates.invoicePeriod === 2) {// za nasl. obdobie
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm
          updatedInvoiceDates.finalInvoiceIssueDay = 1
          updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
          updatedInvoiceDates.finalRenderedInvoicePeriod = `${updatedInvoiceDates.finalInvoiceIssueMonth}/${updatedInvoiceDates.finalInvoiceIssueYear}`

        } else {
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm

          updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
          updatedInvoiceDates.finalInvoiceIssueDay = 50
          updatedInvoiceDates.finalRenderedInvoicePeriod = `${updatedInvoiceDates.finalInvoiceIssueMonth}/${updatedInvoiceDates.finalInvoiceIssueYear}`
        }
        break
      case 3:
        updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year

        if (updatedInvoiceDates.invoicePeriod === 2) {// za nasl. obdobie
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm

          // 1,4,7,10...
          if ([1].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 1
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 03/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }
          if ([2, 3, 4].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 4
            updatedInvoiceDates.finalRenderedInvoicePeriod = `04 - 06/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

          if ([5, 6, 7].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 7
            updatedInvoiceDates.finalRenderedInvoicePeriod = `07 - 09/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

          if ([8, 9, 10].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 10
            updatedInvoiceDates.finalRenderedInvoicePeriod = `10 - 12/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

          if ([11, 12].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueYear += 1
            updatedInvoiceDates.finalInvoiceIssueMonth = 1
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 03/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }
          updatedInvoiceDates.finalInvoiceIssueDay = 1
        } else { // za uplynule obdobie
          // 3, 6, 9, 12
          updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm
          // 3, 6, 9, 12
          if ([1, 2, 3].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 3
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 03/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

          if ([4, 5, 6].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 6
            updatedInvoiceDates.finalRenderedInvoicePeriod = `04 - 06/${updatedInvoiceDates.finalInvoiceIssueYear}`

          }

          if ([7, 8, 9].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 9
            updatedInvoiceDates.finalRenderedInvoicePeriod = `07 - 09/${updatedInvoiceDates.finalInvoiceIssueYear}`

          }

          if ([10, 11, 12].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 12
            updatedInvoiceDates.finalRenderedInvoicePeriod = `10 - 12/${updatedInvoiceDates.finalInvoiceIssueYear}`

          }

          updatedInvoiceDates.finalInvoiceIssueDay = 50

        }


        break
      case 6:
        updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
        if (updatedInvoiceDates.invoicePeriod === 2) {// za nasl. obdobie
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm

          // 1,7
          if ([1].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 1
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 07/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }
          if ([2, 3, 4, 5,6,7].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 7
            updatedInvoiceDates.finalRenderedInvoicePeriod = `07 - 12/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

                if ([8,9,10,11, 12].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueYear += 1
            updatedInvoiceDates.finalInvoiceIssueMonth = 1
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 07/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }
          updatedInvoiceDates.finalInvoiceIssueDay = 1
        } else { // za uplynule obdobie
          // 3, 6, 9, 12
          updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
          if(i)updatedInvoiceDates.finalInvoiceIssueMonth =  invoice_issue_month; else updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? invoice_issue_month : updatedInvoiceDates.paymentInvoiceStartMonthFromForm
          // 3, 6, 9, 12
          if ([1, 2, 3,4,5,6].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 6
            updatedInvoiceDates.finalRenderedInvoicePeriod = `01 - 06/${updatedInvoiceDates.finalInvoiceIssueYear}`
          }

          if ([7,8,9,10, 11, 12].includes(updatedInvoiceDates.finalInvoiceIssueMonth)) {
            updatedInvoiceDates.finalInvoiceIssueMonth = 12
            updatedInvoiceDates.finalRenderedInvoicePeriod = `07 - 12/${updatedInvoiceDates.finalInvoiceIssueYear}`

          }

          updatedInvoiceDates.finalInvoiceIssueDay = 50

        }


        break

      // *************** LETS COUNT INVOICE ISSUE FOR YEAR PERIOD
      case 12:
// ROZLISOVAT DATUM VIDANIA FAKTURY A OBDOBIE FAKTURACIE !!
        // vydanie faktury: ISSUE day, month, year

// INVOICE ISSUE YEAR


        if ((updatedInvoiceDates.currentDay > updatedInvoiceDates.paymentInvoiceDayFromForm) && (updatedInvoiceDates.currentMonth === 12)) invoice_issue_year += 1   // iba ak je dnesny den vacsi ako den v decembri,lebo vtedy fakturujem


        if (updatedInvoiceDates.invoicePeriod === 2) {// za nasl. obdobie

          if (updatedInvoiceDates.letIssueInvoiceMonthBefore) {
            updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
            updatedInvoiceDates.finalInvoiceIssueMonth = 12
            updatedInvoiceDates.finalRenderedInvoicePeriod = invoice_issue_year + 1
          } else {
            updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year + 1
            updatedInvoiceDates.finalInvoiceIssueMonth = updatedInvoiceDates.paymentInvoiceStartMonthFromForm === 0 ? 1 : updatedInvoiceDates.paymentInvoiceStartMonthFromForm
            updatedInvoiceDates.finalRenderedInvoicePeriod = invoice_issue_year + 1
          }
        } else {
          updatedInvoiceDates.finalInvoiceIssueYear = invoice_issue_year
          updatedInvoiceDates.finalInvoiceIssueMonth = 12
          updatedInvoiceDates.finalRenderedInvoicePeriod = invoice_issue_year
        }

// FINAL RENDER
        updatedInvoiceDates.finalRenderedInvoicePeriod = `rok ${updatedInvoiceDates.finalRenderedInvoicePeriod}`


        break

      default:
        break
    }
// LAST DAY OF MONTH
    if (updatedInvoiceDates.finalInvoiceIssueDay === 50) updatedInvoiceDates.finalInvoiceIssueDay = getLastDayOfMonth(updatedInvoiceDates.finalInvoiceIssueYear, updatedInvoiceDates.finalInvoiceIssueMonth)

// IF IS INVOICING DAY TODAY
    if (updatedInvoiceDates.veryCurrentDay === updatedInvoiceDates.finalInvoiceIssueDay && updatedInvoiceDates.veryCurrentMonth === updatedInvoiceDates.finalInvoiceIssueMonth && updatedInvoiceDates.veryCurrentYear === updatedInvoiceDates.finalInvoiceIssueYear)
      updatedInvoiceDates.isToday = true; else updatedInvoiceDates.isToday = false;
    newInvoicePeriods.push(updatedInvoiceDates)

// console.log("creating new tempstartdate from",`${updatedInvoiceDates.finalInvoiceIssueYear}-${updatedInvoiceDates.finalInvoiceIssueMonth}-${updatedInvoiceDates.finalInvoiceIssueDay}`)
  tempStartDate = new Date(`${updatedInvoiceDates.finalInvoiceIssueYear}-${updatedInvoiceDates.finalInvoiceIssueMonth}-${updatedInvoiceDates.finalInvoiceIssueDay}`)
    // console.log('new tempstart day is ',tempStartDate)
  //  }
  }
  setInvoiceDates(newInvoicePeriods);
  // console.log("invoiceDates:",newInvoicePeriods)
  // console.log("invoiceDatesLength:",newInvoicePeriods.length)
  setIsInvoiceDatesCounting(false)
}
function recalculatePaymentOverview(){
  // enqueueSnackbar('recalculating payment overview')
  const updatedPaymentOverview = { ...paymentOverview };
  updatedPaymentOverview.new_apply_discount=getValues('payment_apply_discount')
  // console.log("updatedPaymentOverview.new_apply_discount:",updatedPaymentOverview.new_apply_discount)
  updatedPaymentOverview.new_periodicity=parseInt(getValues('payment_periodicity_current'),10)
  // -------------------------------------
  // --------WHOLE PRICE PER MONTH--------
  // -------------------------------------




// --------INVOICE TEXT RENDERING--------

// --------RENDER DATE INVOICE--------

// --------WHOLE SUM--------
  // **new, according to filled values
  updatedPaymentOverview.newBruttoPricePerMonth=0;
  updatedPaymentOverview.newPricePartsHtml='';

  const payment_price_domain=parseFloat(getValues('payment_price_domain'));
  const payment_domain_count=parseInt(getValues('payment_domain_count'),10);
  if(payment_price_domain&&payment_domain_count){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_domain*payment_domain_count;
    updatedPaymentOverview.newPricePartsHtml+=`Poplatok za doménu(x${payment_domain_count}): <strong>${updatedPaymentOverview.newBruttoPricePerMonth.toFixed(2)} €</strong>,<br />`;
  }

  const payment_price_hosting=parseFloat(getValues('payment_price_hosting'));
  if(payment_price_hosting){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_hosting;
    updatedPaymentOverview.newPricePartsHtml+=`Poplatok za webhosting: <strong>${payment_price_hosting.toFixed(2)} €</strong>,<br />`;
  }
  const payment_price_webspace=parseFloat(getValues('payment_price_webspace'));
  if(payment_price_webspace){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_webspace;
    updatedPaymentOverview.newPricePartsHtml+=`Poplatok za dodatočný webpriestor: <strong>${payment_price_webspace.toFixed(2)} €</strong>,<br />`;
  }
  const payment_price_admin_interface=parseFloat(getValues('payment_price_admin_interface'));
  if(payment_price_admin_interface){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_admin_interface;
    updatedPaymentOverview.newPricePartsHtml+=`Licencia administračný systém (${getValues('payment_admin_interface')}):<strong> ${payment_price_admin_interface.toFixed(2)} €,</strong><br />`;
  }

  const payment_price_content_support=parseFloat(getValues('payment_price_content_support'));



  const payment_hours_prepaid_in_month_content_support=parseFloat(getValues('payment_hours_prepaid_in_month_content_support'));
  // console.log("payment_price_content_support:",payment_price_content_support)
  // console.log("payment_hours_prepaid_in_month_content_support:",payment_hours_prepaid_in_month_content_support)
  if(payment_price_content_support&&payment_hours_prepaid_in_month_content_support){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_content_support*payment_hours_prepaid_in_month_content_support;
    updatedPaymentOverview.newPricePartsHtml+=`Asistovaná obsahová administrácia: <strong>${payment_price_content_support*payment_hours_prepaid_in_month_content_support.toFixed(2)} €</strong>, <br />`;
  }

  const payment_price_technical_support=parseFloat(getValues('payment_price_technical_support'));
  const payment_hours_prepaid_in_month_technical_support=parseFloat(getValues('payment_hours_prepaid_in_month_technical_support'));
  // console.log("payment_price_technical_support:",payment_price_technical_support)
  // console.log("payment_hours_prepaid_in_month_technical_support:",payment_hours_prepaid_in_month_technical_support)

  /* if(payment_price_technical_support&&payment_hours_prepaid_in_month_technical_support){ */
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_technical_support*payment_hours_prepaid_in_month_technical_support;
    updatedPaymentOverview.newPricePartsHtml+=`Technická podpora: <strong>${payment_price_technical_support*payment_hours_prepaid_in_month_technical_support.toFixed(2)} €</strong>, <br />`;
  /* } */


  const payment_price_website_rental=parseFloat(getValues('payment_price_website_rental'));
  if(payment_price_website_rental){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_price_website_rental;
    updatedPaymentOverview.newPricePartsHtml+=`Prenájom a prevádzka webového sídla: <strong> ${payment_price_website_rental.toFixed(2)} €,</strong><br />`;
  }

  const payment_extra_price=parseFloat(getValues('payment_extra_price'));
  if(payment_extra_price){
    updatedPaymentOverview.newBruttoPricePerMonth+=payment_extra_price;
    updatedPaymentOverview.newPricePartsHtml+=`Príplatok: ${payment_extra_price.toFixed(2)} €<br />`;
  }

  /*
  updatedPaymentOverview.newDiscountAmountPerMonth= updatedPaymentOverview.newBruttoPricePerMonth*(updatedPaymentOverview.new_applicable_discount/100);
  updatedPaymentOverview.newBruttoPricePerMonthWithDiscount=updatedPaymentOverview.newBruttoPricePerMonth-updatedPaymentOverview.newDiscountAmountPerMonth
*/
// --------TEXT AND SUMS FOR EVERY PERIOD--------
  // ***month
  updatedPaymentOverview.newSumForMonthlyInvoicing=updatedPaymentOverview.newBruttoPricePerMonth

  // ***kvartal
  updatedPaymentOverview.newSumForKvartalInvoicing=updatedPaymentOverview.newBruttoPricePerMonth*3;
  if(updatedPaymentOverview.new_apply_discount)
  updatedPaymentOverview.newKvartalDiscountPerc=parseInt(getValues('payment_discount_3_months'), 10);else updatedPaymentOverview.newKvartalDiscountPerc=0;
  updatedPaymentOverview.newDiscountAmountPerKvartal=updatedPaymentOverview.newBruttoPricePerMonth*(updatedPaymentOverview.newKvartalDiscountPerc/100)*3
  updatedPaymentOverview.newSumForKvartalInvoicingWithDiscount=updatedPaymentOverview.newSumForKvartalInvoicing-updatedPaymentOverview.newDiscountAmountPerKvartal

  // ***halfyear
  updatedPaymentOverview.newSumForHalfYearInvoicing=updatedPaymentOverview.newBruttoPricePerMonth*6;
  if(updatedPaymentOverview.new_apply_discount)
    updatedPaymentOverview.newHalfYearDiscountPerc=parseInt(getValues('payment_discount_6_months'), 10);else updatedPaymentOverview.newHalfYearDiscountPerc=0;
  updatedPaymentOverview.newDiscountAmountPerHalfYear=updatedPaymentOverview.newBruttoPricePerMonth*(updatedPaymentOverview.newHalfYearDiscountPerc/100)*6
  updatedPaymentOverview.newSumForHalfYearInvoicingWithDiscount=updatedPaymentOverview.newSumForHalfYearInvoicing-updatedPaymentOverview.newDiscountAmountPerHalfYear

  // ***year
  updatedPaymentOverview.newSumForYearInvoicing=updatedPaymentOverview.newBruttoPricePerMonth*12;
  if(updatedPaymentOverview.new_apply_discount)
    updatedPaymentOverview.newYearDiscountPerc=parseInt(getValues('payment_discount_12_months'), 10);
  else updatedPaymentOverview.newYearDiscountPerc=0;
  updatedPaymentOverview.newDiscountAmountPerYear=updatedPaymentOverview.newBruttoPricePerMonth*(updatedPaymentOverview.newYearDiscountPerc/100)*12
  updatedPaymentOverview.newSumForYearInvoicingWithDiscount=updatedPaymentOverview.newSumForYearInvoicing-updatedPaymentOverview.newDiscountAmountPerYear


  // --------PERCENTAGE & PERIODICITY--------
  switch (updatedPaymentOverview.new_periodicity) {
    case 1:
      updatedPaymentOverview.new_applicable_discount = 0
      updatedPaymentOverview.newPeriodicityText = 'mesačne'
      updatedPaymentOverview.newPeriodicityTextNom = 'mesiac'
      updatedPaymentOverview.newFinalInvoicedPrice=updatedPaymentOverview.newBruttoPricePerMonth
      updatedPaymentOverview.newFinalInvoicedPriceWithDiscount=updatedPaymentOverview.newBruttoPricePerMonth
      updatedPaymentOverview.newFinalInvoicedPriceDiscount=0
      break
    case 3:
      updatedPaymentOverview.new_applicable_discount = parseInt(getValues('payment_discount_3_months'), 10)
      updatedPaymentOverview.newPeriodicityText = 'kvartálne'
      updatedPaymentOverview.newPeriodicityTextNom = 'kvartál'
      updatedPaymentOverview.newFinalInvoicedPrice=updatedPaymentOverview.newBruttoPricePerMonth*3
      updatedPaymentOverview.newFinalInvoicedPriceWithDiscount=updatedPaymentOverview.newSumForKvartalInvoicingWithDiscount
      updatedPaymentOverview.newFinalInvoicedPriceDiscount=updatedPaymentOverview.newDiscountAmountPerKvartal
      break
    case 6:
      updatedPaymentOverview.new_applicable_discount = parseInt(getValues('payment_discount_6_months'), 10)
      updatedPaymentOverview.newPeriodicityText = 'polročne'
      updatedPaymentOverview.newPeriodicityTextNom = 'polrok'
      updatedPaymentOverview.newFinalInvoicedPrice=updatedPaymentOverview.newBruttoPricePerMonth*6
      updatedPaymentOverview.newFinalInvoicedPriceWithDiscount=updatedPaymentOverview.newSumForHalfYearInvoicingWithDiscount
      updatedPaymentOverview.newFinalInvoicedPriceDiscount=updatedPaymentOverview.newDiscountAmountPerHalfYear

      break
    case 12:
      updatedPaymentOverview.new_applicable_discount = parseInt(getValues('payment_discount_12_months'), 10)
      updatedPaymentOverview.newPeriodicityText = 'ročne'
      updatedPaymentOverview.newPeriodicityTextNom = 'rok'
      updatedPaymentOverview.newFinalInvoicedPrice=updatedPaymentOverview.newBruttoPricePerMonth*12
      updatedPaymentOverview.newFinalInvoicedPriceWithDiscount=updatedPaymentOverview.newSumForYearInvoicingWithDiscount
      updatedPaymentOverview.newFinalInvoicedPriceDiscount=updatedPaymentOverview.newDiscountAmountPerYear
      break
    default:
      updatedPaymentOverview.new_applicable_discount = 0
      break
  }
  if(!updatedPaymentOverview.new_apply_discount)
    updatedPaymentOverview.new_applicable_discount = 0


  // --------DIFFERENCE OLD / NEW PAYMENTS--------
  const payment_base_price_old=parseFloat(getValues('payment_base_price_old'));
  const payment_periodicity_old=parseFloat(getValues('payment_periodicity_old'));
  const basePricePerMonthOld=payment_base_price_old/payment_periodicity_old;

  if(updatedPaymentOverview.new_applicable_discount)
  updatedPaymentOverview.priceDifferencePerMonth=updatedPaymentOverview.newFinalInvoicedPriceWithDiscount/updatedPaymentOverview.new_periodicity -basePricePerMonthOld;else
    updatedPaymentOverview.priceDifferencePerMonth=updatedPaymentOverview.newFinalInvoicedPrice/updatedPaymentOverview.new_periodicity-basePricePerMonthOld







  // **according to values from DB
updatedPaymentOverview.bruttoPricePerMonth=parseFloat(defaultValues.payment_price_domain)*parseInt(defaultValues.payment_domain_count,10)+parseFloat(defaultValues.payment_price_hosting)+parseFloat(defaultValues.payment_price_webspace)+parseFloat(defaultValues.payment_price_admin_interface)+parseFloat(defaultValues.payment_price_technical_support)*parseFloat(defaultValues.payment_hours_prepaid_in_month_technical_support)+parseFloat(defaultValues.payment_price_content_support)*parseFloat(defaultValues.payment_hours_prepaid_in_month_content_support)+parseFloat(defaultValues.payment_price_website_rental)+parseFloat(defaultValues.payment_extra_price)






// console.log("updatedPaymentOverview:",updatedPaymentOverview)
  setPaymentOverview(updatedPaymentOverview)
// recalculateInvoiceDates("2023-12-15") // for test purposes YYYY-MM-DD eg. 2023-12-15
recalculateInvoiceDates() // for test purposes YYYY-MM-DD eg. 2023-12-15
}
// here load companies
  const logoFileIsUploading = useBoolean(false)
  const handleDropLogo = useCallback(
    (acceptedFiles) => {
      if(!logoFileIsUploading.value) {
        logoFileIsUploading.onTrue()
        const jwt = localStorage.getItem('axjwt');
        if (jwt) {

          const file = acceptedFiles[0];
          const postValues = new FormData();
          postValues.append('file_to_set_logo', file);
          postValues.append('dep_id', currentDep.id); // dep id to whom are we going to change the logo
          postValues.append('action', 'upload_dep_logo'); // user id to whom are we going to change the loog


          axios.post('https://api.administrix.pro/v1/post/',
            postValues, {
              headers: {'Authorization': `Bearer ${jwt}`}
            })
            .then((response => {
              if (response.data.data) {
                const {data} = response.data;
                const {meta} = response.data;
                if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

                if (data.status === 1) {

                  enqueueSnackbar("Výborne, nastavili ste nové logo", {variant: `success`, autoHideDuration: 6000});
                  setValue('logoUrl',axStore.systemCurrentConfig.s3.s3_endpoint_system+data.logo_url )
                  axStore.setCurrentDepartment({ ...axStore.axCurrentDepartment, logo_url: data.logo_url })
//                  dispatch(updateUserAvatar(data.avatar_url));
                } else {
                  enqueueSnackbar("Bohužiaľ, nepodarilo sa zmeniť department logo.", {variant: `error`, autoHideDuration: 6000});

                }


              } else if (response.data.error) {
//            const {error} = response.data;
                enqueueSnackbar(`Chyba pri zmene loga: ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});

              }

            }))
            .catch(error => {
              // console.log("any changing logo  fetch api error",error);

            })

        }
        logoFileIsUploading.onFalse()
      }















      /*
            if (file) {
              setValue('avatarUrl', newFile, { shouldValidate: true });
            } */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue,currentDep]
  );


function readAllAxCompanies(){

    setIsGettingCompanies(true)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_companies',
        // 'depid': depId
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);


            // this is, if i want to convert id to number:
            /*
            data.companies.forEach(company => {
              company.id = parseInt(company.id, 10);
            });
             */

            setCompanies(data.companies)
//            console.log('companies:',data.companies)

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní firiem: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          setIsGettingCompanies(false)
        }))
        .catch(error => {
//           console.log("any get_companies fetch api error");
          // console.log(error);
          // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setIsGettingCompanies(false)
        })
    }
  }
// handleLinkToCompany
  /*
  function handleLinkToCompany(){
  console.log('clicked handleLinkToCompany - message from fc inside')
    setLinkingToCompany(true)

    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      const postValues = new FormData();
      postValues.append('action', 'link_company_to_dep');
      postValues.append('depid', currentDep.id);
      postValues.append('compid', selectedCompany.id);

      axios.post('https://api.administrix.pro/v1/post/',
        postValues,{
          headers: {'Authorization': `Bearer ${  jwt}`}
        } )
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if(data.success===1) {
              enqueueSnackbar("Firmu sme úspešne priradili.", {variant: `success`,autoHideDuration: 6000});
              loadDepDataToEdit(data.depid)
            }else {
              enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`,autoHideDuration: 6000});
              console.log(response.data)
            }
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri priraďovaní firmy k departmentu: ${error.title}`,{ variant: `error` ,autoHideDuration: 6000});
          }
          setLinkingToCompany(false)
        }))
        .catch(error => {
           console.log("any linkingcompany fetch api error");
          // console.log(error);
          // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setLinkingToCompany(false)
        })
    }
  }
*/
// handleLinkToCompany
  function postDepDataToSaveToServer(dep_data_to_save){
// console.log('calling postDepDataToSaveToServer...')
    setIsSavingDepData(true)

    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      const postValues = new FormData();
      postValues.append('action', 'save_department_data');
      if(depIdToEdit===0) {
        postValues.append('depid', "0");
        postValues.append('name_new', getValues('name_new'));
        postValues.append('description_new', getValues('description_new'));
      }
      else {
        postValues.append('depid', currentDep.id);

        Object.entries(dep_data_to_save).forEach(([key, value]) => {
          postValues.append(key, value);
        });
        Object.entries(paymentOverview).forEach(([key, value]) => {
          postValues.append(key, value);
        });
        Object.entries(invoiceDates[0]).forEach(([key, value]) => {
          postValues.append(key, value);
        });
      }
      axios.post('https://api.administrix.pro/v1/post/',
        postValues,{
          headers: {'Authorization': `Bearer ${  jwt}`}
        } )
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if(depIdToEdit===0) {
              if(data.newDepId){
                enqueueSnackbar("Výborne, vytvorili sme nový department", {variant: `success`, autoHideDuration: 6000});
                setDepIdToEdit(data.newDepId)
                loadDepsToAxStore()
              }
            }
            else if (data.success === 1) {
                enqueueSnackbar("Údaje o departmente sme úspešne uložili.", {variant: `success`, autoHideDuration: 6000});
                reset(dep_data_to_save)
                 loadDepDataToEdit(currentDep.id)
              // loadDepDataToEdit
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                // console.log(response.data)
              }
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri ukladaní dát: ${error.title}`,{ variant: `error` ,autoHideDuration: 6000});
          }
          setIsSavingDepData(false)
        }))
        .catch(error => {
           // console.log("any saving dep data fetch api error");
          setIsSavingDepData(false)
        })
    }
  }

const loadDepDataToEdit=(depid)=>{
  setCurrentDepAllLoadedData(false)
  if(depid>0){
    // console.log("loadDepDataToEdit runned")
    setDialogWidth('xl')
    setIsLoading(true);
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_dep_edit_data',
        'depid': depid
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            // console.log('data get_dep_edit_data response:')
          // console.log(response)
          if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
          if(data.languages) {
            setLanguages(data.languages)
            const selectedLanguage = data.languages.find(language => language.id === data.lang);
            const languageName = selectedLanguage ? selectedLanguage.name_origin : "not found";
            data.language_name=languageName;

          }
          if(data.domains) {
            data.domains_concated = Object.values(data.domains).map(domain => domain.url).join(', ');
           /* console.log('data.domains',data.domains)
            console.log('concated domaines:', data.domains_concated)
            console.log(Object.values(data.domains).map(domain => domain.url).join(', ')) */
          }

          data.created_at_nice=ConvertToNiceDate(data.created_at)
          data.updated_at_nice=ConvertToNiceDate(data.updated_at)
          if(data.website_config) {
            if(data.website_config.updated_at)data.website_config.updated_at_nice = ConvertToNiceDate(data.website_config.updated_at)
            if(data.website_config.website_created_at)data.website_config.created_at_nice = ConvertToNiceDate(data.website_config.website_created_at)
          }
          setInvoiceDates([])
          setCurrentDep(data)
          setCurrentDepAllLoadedData(true)



          setDepOldIdToEdit(parseInt(data.id,10))
            /*
            if(data.website_company){

              setSelectedCompany({"id":data.website_company.id,"label":data.website_company.name,"municipality":data.website_company.municipality,"street":data.website_company.street,"street_number":data.website_company.street_number})
              setLabelForAutocompleteCompanies("Tento department je priradený k subjektu")
            }else{
              console.log('dep NOT connected to company:')
              setSelectedCompany(null);
              setLabelForAutocompleteCompanies("Vyberte subjekt, ktorý je priradený k tomuto departmentu")
            }
*/
          // if(data.companies)setCompanies(data.companies)

          setDialogWidth('xl')
            setCurDepSelectbox({"id": data.id, "label": data.name})
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní dát o departmente: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          setIsLoading(false);
          setIsSwitchingWeb(false)
        }))
        .catch(error => {
          // console.log("any get_dep_edit_data fetch api error");
            // console.log(error);
          setIsLoading(false);
          setIsSwitchingWeb(false)
          setDialogWidth('xl')
        })
    }
  }else{console.log("loadDepDataToEdit skiped")}
}
function priceDifference(periodicity=1){
  let renderedDifference=''
  let colorClss=''
  let diffSign='';
    if(paymentOverview.priceDifferencePerMonth>0) {
      diffSign = '+'
      colorClss='green'
    }
    if(paymentOverview.priceDifferencePerMonth<0) {

      colorClss='red'
    }
  renderedDifference=`<strong style="color:${colorClss}">${diffSign + (paymentOverview.priceDifferencePerMonth*periodicity).toFixed(2)}€</strong>`

  return renderedDifference

}

const watched_values = useWatch({ control,name:['payment_domain_count','we_provide_website_for_this_department','payment_is_active','payment_price_domain','payment_price_hosting','payment_price_webspace','payment_price_admin_interface','payment_price_technical_support','payment_price_content_support','payment_hours_prepaid_in_month_technical_support','payment_hours_prepaid_in_month_content_support','payment_price_website_rental','payment_apply_discount','payment_periodicity_current','payment_discount_3_months','payment_discount_6_months','payment_discount_12_months','payment_invoice_period','payment_invoice_day','payment_invoice_start_month','payment_invoice_start_year','payment_invoice_month_before','payment_base_price_old','payment_periodicity_old','payment_extra_price','is_multiobec_system','web_type','invoicing_with_invoice','is_pixme','max_parallel_uploads'] });


useEffect(()=> {
   // console.log("max_users:")
   // console.log(getValues('max_users'))
  if(isPaymentActive)recalculatePaymentOverview()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[watched_values])

useEffect(()=> {
  if(needReloadDepEditData) {
    loadDepDataToEdit(currentDep.id)
    setNeedReloadDepEditData(0)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[needReloadDepEditData])



useEffect(()=> {
  // console.log('useeffect selectedCompany')
  if(depIdToEdit===0)setDialogWidth('md');else setDialogWidth('xl')
  if(depIdToEdit) {
//     console.log('company changed:', selectedCompany)
    if (currentDep.website_company && selectedCompany) {
      if (selectedCompany.id !== currentDep.website_company.id) console.log('vybrata nova firma'); else console.log('vybrata firma co uz bola priradena')
    } else console.log('vybrata nova firma')
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCompany]);

/* ------- NASTAV FORMULAR HODNOTAMI, KED SA NACITAJU Z DB -------- */

useEffect(()=> {
  // console.log('useeffect defaultValues')
    if(currentDep&&Object.keys(currentDep).length>0) {
       // console.log("reseting form with default values:", defaultValues);
       reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[defaultValues,currentDep])
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[defaultValues])



  useEffect(()=> {
    // console.log("currentDep useeffect = ",currentDep)
    if ((Object.keys(currentDep).length !== 0)&&companies&&!isGettingCompanies && companies.length === 0) {
//       console.log("dep modal read comapnies")
      readAllAxCompanies()
    }
    recalculatePaymentOverview();
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentDep])



  useEffect(()=> {
// console.log("Object.values(axStore.axDepartments):",Object.values(axStore.axDepartments))
    // toto sa spusti akonahle je nastavena hodnota pre currentDep, ale chcem to len pri prvom otvoreni, aby sa dotiahli data z API
    // console.log('useEffect open')
    // console.log('depIdToEdit=', depIdToEdit)
    // console.log('previous depid depOldIdToEdit=', depOldIdToEdit)


if(depIdToEdit!==0) {
  if (open) {
    /* console.log('open modal useeffect')
    console.log('change depIdToEdit useeffect')
    console.log('previous depid depOldIdToEdit=', depOldIdToEdit)
    console.log('depIdToEdit=', depIdToEdit)
    */

    // reset(defaultValues);
 //   if ((!currentDepAllLoadedData)&&(!isLoading)&&(parseInt(depOldIdToEdit, 10) !== parseInt(depIdToEdit, 10))) loadDepDataToEdit(depIdToEdit);
    if ((!isLoading)&&(parseInt(depOldIdToEdit, 10) !== parseInt(depIdToEdit, 10))) loadDepDataToEdit(depIdToEdit);
    /*
    if (depIdToEdit > 0) {
      console.log('setting setCurDepSelectbox',{"id": depIdToEdit, "label": currentDepAsProp.name})
      // setCurDepSelectbox({"id": depIdToEdit, "label": currentDepAsProp.name}) // moved to loaddepdatatoedit
    }
    */
  }
}
  // },[currentDep]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open,depIdToEdit]);

  const onSubmit = handleSubmit(async (data) => {
    // enqueueSnackbar('Saving department data');
    // console.log('going to save department data',data)
    postDepDataToSaveToServer(data)

  });

  const renderAddNewDepartment = (
    <>
    <DialogTitle>Vytvorenie nového departmentu</DialogTitle>
    <DialogContent>
      <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingLeft: "20px",paddingTop:1}}>
        <Stack spacing={2}>

          <RHFTextField name="name_new" label="Názov nového departmentu"/>
          <RHFTextField name="description_new" label="Popis"/>

        </Stack>
      </Grid>

    </DialogContent>

      </>
  )
  const getOptionLabel = (option) => option.label;
  const getOptionLabelCompanies = (option) => (`${option.label}, ${option.street} ${option.street_number}, ${option.municipality}. ID:${option.id}`);
  const datetimeSlovakFormatOptions = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };

  const handleCompaniesAutoselectOnBlur = () => {

    if(depIdToEdit!==0&&currentDep.website_company&&!selectedCompany) {
      setSelectedCompany({"id":currentDep.website_company.id,"label":currentDep.website_company.name,"municipality":currentDep.website_company.municipality,"street":currentDep.website_company.street,"street_number":currentDep.website_company.street_number})
    }

  }

  const renderEditDepartment = (
    (currentDep&&depIdToEdit!==0)?(<>
      <DialogTitle>{t('dep.department_settings')}</DialogTitle>
      <DialogContent>

      <Grid container spacing={2} sx={{paddingTop:1}}>
    <Grid xs={12} sm={7} md={8} lg={9} xl={10} >
      {(Object.keys(axStore.axDepartments).length > 1) && (<Autocomplete
        autoComplete={autocomplete}
        value={curDepSelectbox !== '' ? curDepSelectbox : null}
        disablePortal
        id="depSelectbox"
        size="small"
        isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}

        getOptionLabel={getOptionLabel}
        options={Object.values(axStore.axDepartments)}
        onChange={handleOptionSelectedDepClick}
        renderInput={(params) => <TextField {...params} label={t('dep.choose_department')}/>}
      />)}
    </Grid>
        <Grid xs={12} sm={5}  md={4} lg={3} xl={2} >
          {user.ax_role<2&&
          <Button
            fullWidth
            color="success"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={()=>{setCurrentDepAllLoadedData(false);setCurrentDep(null);setDepIdToEdit(0)}}
          >
            Nový department
          </Button>}
        </Grid>
    <Grid xs={12} lg={3}>
      <Paper elevation={4} sx={{margin: 2, padding: 2, fontSize: "0.7em"}}>
<Grid container>


        {isLoading && (<Grid><Typography align="center" sx={{fontSize: "0.8em"}}>získavam údaje o webe {webNameSwitchingTo}...</Typography><LinearProgress/></Grid>)}
        {currentDepAllLoadedData && (<>
        <Grid sm={12} md={6} lg={12}>

          <RHFUploadAvatar
            name="logoUrl"
            caption="Zmeň logo"
            rounded={false}
            maxSize={3145728}
            onDrop={handleDropLogo}
            sx={{marginBottom:"20px",width:"90%"}}
          />

          {/* <Typography variant="h6" gutterBottom>
            {currentDep.name}            <RHFUploadAvatar
            name="logoUrl"
            maxSize={3145728}
            onDrop={handleDropLogo}
          />
          </Typography>
*/ }
          <ConfirmDepartmentDelete depId={currentDep.id} depName={currentDep.name} handleCloseModal={handleCloseModal} setDeleteDepConfirmModalIsOpen={setDeleteDepConfirmModalIsOpen} deleteDepConfirmModalIsOpen={deleteDepConfirmModalIsOpen} />

          <Typography variant="body2" gutterBottom>
            Department ID: <strong>{currentDep.id}</strong>
          </Typography>

            <Typography variant="body2" gutterBottom>
              {t('dep.department_creation_date')}:
              <Tooltip TransitionComponent={Zoom} title={`${t('dep.department_was_created_by_user')} ${currentDep.created_by_name} ${t('dep.day_gen')} ${currentDep.created_at_nice.niceDate}, ${currentDep.created_at_nice.niceTime}`}><strong> {currentDep.created_at_nice.niceDate}</strong>
              </Tooltip>
            </Typography>

          {currentDep.updated_at_nice && (

              <Typography variant="body2" gutterBottom>
                {t('dep.department_last_update')}:
                <Tooltip TransitionComponent={Zoom} title={`${t('dep.department_was_updated_by_user')} ${currentDep.updated_by_name} ${t('dep.day_gen')} ${currentDep.updated_at_nice.niceDate}, ${currentDep.updated_at_nice.niceTime}`}>
                 <strong>{currentDep.updated_at?currentDep.updated_at_nice.niceDate:currentDep.created_at_nice.niceDate}</strong>
                </Tooltip>
              </Typography>

          )
          }

          {getValues('we_provide_website_for_this_department')&&(<>

          {currentDep.website_config && currentDep.website_config.created_at_nice && (
            <Tooltip TransitionComponent={Zoom}
                     title={`Webstránka bola vytvorená dňa ${currentDep.website_config.created_at_nice.niceDate} o ${currentDep.website_config.created_at_nice.niceTime} - pozor tento údaj nieje automatický, ale nastavovaný manuálne`}>
              <Typography variant="body2" gutterBottom>
                Vytvorenie stránky: <strong>{currentDep.website_config.created_at_nice.niceDate}</strong>
              </Typography>
            </Tooltip>
          )
          }
          {currentDep.website_config && currentDep.website_config.updated_at_nice && (
            <Tooltip TransitionComponent={Zoom}
                     title={`Webstránka bola aktualizovaná užívateľom ${currentDep.website_config.updated_by_name} dňa ${currentDep.website_config.updated_at_nice.niceDate} o ${currentDep.website_config.updated_at_nice.niceTime}`}>
              <Typography variant="body2" gutterBottom>
                Posledný update stránky: <strong>{currentDep.website_config.updated_at_nice.niceDate}</strong>
              </Typography>
            </Tooltip>
          )
          }
          <Typography variant="body2" gutterBottom>
            Počet článkov: <strong>{currentDep.articles_count}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Počet súborov: <strong>{currentDep.files_count}</strong>, veľkosť: <strong>{formatBytes(currentDep.files_size)}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Doména:
            {/* currentDep.domains&&currentDep.domains.map((domain) => (
                <span key={domain.id}>
                  <strong><a href={domain.url} target="_blank" rel="noopener noreferrer">
                    {domain.url}
                  </a></strong>,
                </span>
              )) */}
            {currentDep.domains && currentDep.domains.url}
            {/* {currentDep.domains&&Object.keys(currentDep.domains).map((domain) => {
        // const domain = currentDep.domains[domainKey];
        return (
          <span key={domain.id}>
            <a href={domain.url} target="_blank" rel="noopener noreferrer">
              {domain.url}
            </a>,
          </span>
        );
      })}
*/}

          </Typography>
          </>)}
          {(user.ax_role<2||user.department_role===1)&&
            <>
          <Typography variant="body2" gutterBottom>
            {t('dep.users')}: <strong> {currentDep.users.length} / {currentDep.max_users===-1?`${t('dep.unlimited_users')}`:currentDep.max_users}</strong>
          </Typography>

              <Typography variant="body2" gutterBottom>
            {t('dep.ax_def_language')} <strong> {currentDep.language_name}</strong>
          </Typography>
            </>}

          {(currentDep.is_pixme&&(user.ax_role<2||user.department_role===1))&&
            <>
              <Typography variant="body1" gutterBottom sx={{marginTop:"30px"}}>
                <strong>{t('dep.pixme_params')}</strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                {t('dep.pixme_quota')}: <strong> {formatBytes(currentDep?.pixme?.total_filesize?currentDep.pixme.total_filesize:0)} / {formatBytes(currentDep?.pixme?.quota?(currentDep.pixme.quota* 1024 * 1024):'0')}</strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                {t('dep.pixme_total_files')}: <strong> {currentDep?.pixme?.file_count}</strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                {t('dep.pixme_tags_from_filename')}: <strong> {currentDep?.pixme?.get_tags_from_filename?'yes':'no'}</strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t('dep.pixme_tags_from_lightroom_keywords')}: <strong> {currentDep?.pixme?.get_tags_from_lightroom_keywords?'yes':'no'}</strong>
              </Typography>


            </>}

        </Grid>
          {user.ax_role<2&&
            <>
          <Grid sm={12} md={6}  lg={12}>
          <Tooltip TransitionComponent={Zoom}
                   title={currentDep.website_company ? (`${currentDep.website_company.name},${currentDep.website_company.municipality}`) : 'Tento department nemá vyplnené firemné údaje. Kliknutím firemné údaje vytvoríte.'}>
            <Typography variant="body2" gutterBottom>
              Subjekt: <strong>{currentDep.website_company ? currentDep.website_company.name : (<span style={{color: 'red'}}>NIE</span>)} </strong> <IconButton onClick={()=> {

                if(currentDep.website_company) {
                  setMyCompanyId(currentDep.website_company.id)
                } else {
                  setMyCompanyId(0)

              }
              setIsDepartmentMainCompany(1)
              setMyCompanyEditOpen(true)
            }}><SettingsIcon /></IconButton>

            </Typography>
          </Tooltip>
            <Typography variant="body2" gutterBottom>
              Pravidelná fakturácia: <strong>{currentDep?.payment?.is_active ? 'Áno' : 'Nie'} </strong>
            </Typography>




            {currentDep.website_company&&(<>

              {(currentDep.invoices_count_together&&parseInt(currentDep.invoices_count_together,10)>0)&&(<>
            <Typography variant="body2" gutterBottom>
            Uhradené všetky faktúry: <strong>
            {
              (parseInt(currentDep.invoices_count_unpaid,10)===0)?(<span style={{color:"#52a200"}}>Áno</span>):(<span style={{color:"#d50000"}}>NIE</span>)
            }

          </strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Celkovo faktúr: <strong>{currentDep.invoices_count_together}</strong> v cene <strong> {parseFloat(currentDep.invoices_sum_together).toFixed(2)} €</strong>
          </Typography>
          {
            (parseInt(currentDep.invoices_count_unpaid,10)>0)&&(
          <Typography variant="body2" gutterBottom>
            Celkovo neuhradených: <strong>{currentDep.invoices_count_unpaid}</strong> v cene <strong> {parseFloat(currentDep.invoices_sum_unpaid).toFixed(2)} €</strong>
          </Typography>
            )}

          <Typography variant="body2" gutterBottom>
            Perióda fakturácie: <strong>každý mesiac</strong>
          </Typography>

          <Typography variant="body2" gutterBottom>
            Cena fakturácie: <strong>xxx/perioda, 26€/m</strong>
          </Typography>

            <Typography variant="body2" gutterBottom>
              Zľava: <strong>{paymentOverview.new_apply_discount?`${paymentOverview.new_applicable_discount} %`:' sa neaplikuje'} - tu nastavit old discount</strong>
            </Typography>

            <Typography variant="body2" gutterBottom>
              Najbližšia faktúra: <strong>1.1.2024</strong>
            </Typography>
              </>)}
</>)}




          </Grid>



          {currentDep&&<Button
            fullWidth
            color="error"
            variant="contained"
            startIcon={<Iconify icon="material-symbols:delete-outline" />}
            onClick={()=>{console.log('setting setDeleteDepConfirmModalIsOpen to true',deleteDepConfirmModalIsOpen);setDeleteDepConfirmModalIsOpen(true)}}
          >
            Odstráň department
          </Button>}
            </>
        }




        </>)}
  {/*
  <Typography variant="h6" sx={{textAlign:"center",color:"#7a7a7a"}}>
    <RefreshIcon onClick={()=>loadDepDataToEdit(currentDep.id)} sx={{cursor:"pointer"}}/>
  </Typography>
*/}
</Grid>
      </Paper>
    </Grid>


    <Grid xs={12} lg={9}>

      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label="Nastavenia departmentu"
          >
            {TABS.map((tab,index) => (
              <Tab key={index} value={tab.value} icon={tab.icon} label={tab.label}/>
            ))}
          </Tabs>
        </Box>

        {currentTab === 'zakladne-nastavenie' &&
        <Grid xs={12} sx={{top: 0, overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>
          <Stack spacing={2}>
            {user.ax_role<2&&<RHFSwitch name="dep_visible" label="Department je aktívny"/>}

              <RHFTextField name="depname" label={t('dep.department_name')}/>
             <RHFTextField name="description" label={t('dep.department_description')}/>
            {user.ax_role<2&&(
              <>
            <RHFTextField name="department_admin_note" label="Admin poznámka - užívateľovi sa nezobrazuje" />
            <RHFTextField name="max_users" label="Maximálny počet užívateľov departmentu: -1  ak nekonečno" type="number"/>
            <RHFTextField name="default_pixcoins" label="Default piXcoins" title="Default amount, which is periodically added every month" type="number"/>
            <RHFTextField name="remaining_pixcoins" label="Amount, of remaining piXcoins" type="number"/>

                <RHFSelect name="def_lang" label="Defaultný jazyk pre department">
                  {languages?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name_origin}
                    </MenuItem>
                  ))}

                </RHFSelect>
              </>
                )}

            {user.ax_role<2&&
              <>
              <RHFSwitch name="is_administrix" label={t('dep.is_administrix')}/>
              <RHFSwitch name="is_pixme" label={t('dep.is_pixme')}/>

                {(getValues('is_pixme'))&&
                  <Box sx={{ p: 2, backgroundColor: '#8080800D',borderRadius:'10px' }}>


                  <RHFTextField name="pixme_quota" label="piXme quota in MB" type="number"/>
                  <RHFTextField name="max_parallel_uploads" label={t('upld.max_concurrent_uploads')} type="number"/>
                  <RHFSwitch name="pixme_get_tags_from_filename" label={t('dep.pixme_tags_from_filename')}/>
                  <RHFSwitch name="pixme_tags_from_lightroom_keywords" label={t('dep.pixme_tags_from_lightroom_keywords')}/>
                  <RHFSwitch name="pixme_allow_ai_recognition" label={t('dep.allow_ai_recognition')}/>

                  </Box>
                }
              <RHFSwitch name="is_events" label={t('dep.is_events')}/>
              <RHFSwitch name="is_wiam" label={t('dep.is_wiam')}/>
              </>
            }

            {(user.ax_role<2&&getValues('is_administrix'))&&
            <RHFSwitch name="we_provide_website_for_this_department" label={t('dep.we_provide_website_for_this_department')}/>
            }

            {getValues('we_provide_website_for_this_department')&&(
             <RHFTextField name="concated_domains" label="Doména webového sídla. Ak viac, oddeľ čiarkou"/>

            )}
            {/*
            <Autocomplete
              autoComplete={autocomplete}
              value={selectedCompany}
              onBlur={handleCompaniesAutoselectOnBlur}
              onChange={(event, newValue) => setSelectedCompany(newValue)}
              disablePortal
              id="companies_autocomplete"
              name="companies_autocomplete"
              isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
              getOptionLabel={getOptionLabelCompanies} // this is very important if i have duplicate keys !!!!
              options={companies || []}

              // onChange={handleOptionSelectedDepClick}
              renderInput={(params) => <TextField {...params} label={labelForAutocompleteCompanies}/>}
            />

              ((!currentDep.website_company && selectedCompany) || (currentDep.website_company && selectedCompany && (selectedCompany.id !== currentDep.website_company.id))) && (
                <LoadingButton onClick={() => handleLinkToCompany()} loading={linkingToCompany} variant="contained" color="success" endIcon={<LinkIcon/>}>
                  Prepojiť department {currentDep.name} a subjekt {selectedCompany.label}
                </LoadingButton>
              )
            */}
            {
              (currentDep.website_company && selectedCompany && (selectedCompany.id === currentDep.website_company.id)) && (<Card sx={{paddingLeft: 1, fontSize: "0.6em"}}>


                <strong>Detail subjektu:</strong> {currentDep.website_company.name}, {currentDep.website_company.street} {currentDep.website_company.street_number}, {currentDep.website_company.postcode} {currentDep.website_company.municipality}, IČO: {currentDep.website_company.cin},
                {/* eslint-disable-next-line no-nested-ternary */}
                {currentDep.website_company.vatin ? (`IČ DPH: ${currentDep.website_company.vatin}`) : currentDep.website_company.tin ? (`DIČ: ${currentDep.website_company.tin}`) : ''}


              </Card>)
            }

            {getValues('we_provide_website_for_this_department')&&(
              <RHFSwitch name="is_multiobec_system" label="Patrí do systému multiobec"/>              )}
            {
              getValues('is_multiobec_system')&&(
                <>
                  <RHFTextField name="web_domain_tiny" label="ID v multiobec (web_domain_tiny) napr. krupinask"/>
                  <RHFSelect name="web_type" label="Typ webu v multiobec">
                    <MenuItem key="0" value="0">Neurčené</MenuItem>
                    <MenuItem key="1" value="1">Mesto/obec</MenuItem>
                    <MenuItem key="2" value="2">Zariadenie soc. služieb</MenuItem>
                    <MenuItem key="3" value="3">ObFZ</MenuItem>
                  </RHFSelect>
                  {getValues('web_type')==="1"&&(
                    <RHFTextField name="multiobec_municode" label="Muni code"/>
                    )
                  }

                  {getValues('web_type')==="2"&&(
                    <RHFTextField name="multiobec_ddico" label="DD IČO"/>
                    )
                  }

                </>
              )
            }

          </Stack>
        </Grid>}
        {currentTab === 'uzivatelia' &&
          <Grid xs={12} sx={{top: 0,  overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>

            <UserQuickEditForm userIdToEdit={userIdToEdit} currentUserAsProp={userToEdit} setUserOldIdToEdit={setUserOldIdToEdit} setUserIdToEdit={setUserIdToEdit} userOldIdToEdit={userOldIdToEdit} open={userQuickEditModal.value} onClose={userQuickEditModal.onFalse} runOnClose={runOnClose} depIdForUserToEdit={currentDep.id} depNameForUserToEdit={currentDep.name}  isAxUser />

            <Typography variant="h6" gutterBottom sx={{fontSize:"0.9em"}}>

              {t('dep.users_allowed_to_log_into')} {axStore.systemCurrentConfig.systemNameGenitiv} {t('dep.and_administrate_content_of')} {currentDep.name}
            </Typography>
            <Ax4UserDepIncQuickEditForm open={ax4ModalOpen} setOpen={setAx4ModalOpen} depId={currentDep.id} userId={ax4DepUserIdEdit.current} setNeedReloadDepEditData={setNeedReloadDepEditData}/>
            <Ax5UserDepIncQuickEditForm open={ax5ModalOpen} setOpen={setAx5ModalOpen} depId={currentDep.id} userId={ax5DepUserIdEdit.current} setNeedReloadDepEditData={setNeedReloadDepEditData}/>
            <UnlinkUserFromDepartment open={unlinkUserModalOpen} setOpen={setUnlinkUserModalOpen} depId={currentDep.id} userId={unlinkUserIdEdit.current} setNeedReloadDepEditData={setNeedReloadDepEditData}/>
            <Stack spacing={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell  align="center">ID</TableCell>
                      <TableCell>{t('dep.user')}</TableCell>
                      <TableCell align="center">{t('dep.role')}</TableCell>
                      {user.ax_role<2&&<>

                      <TableCell align="center">Menu ax4</TableCell>
                      </>}
                      <TableCell align="center">Email</TableCell>

                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">{t('dep.edit')}</TableCell>
                      <TableCell align="center">{t('user.disconnect')}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentDep.users?.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell component="th" scope="row" >
                          {`${row.title_before_name} ${row.name} ${row.surname}${row.title_after_name ? ', ' : ''} ${row.title_after_name}`}

                        </TableCell>



                        <TableCell align="center"><Tooltip title={t(axStore.departmentRoles[row.dep_role].role_desc)}><>{t(axStore.departmentRoles[row.dep_role].role_name)}</></Tooltip>

                          <Tooltip title={row.menu_composition_id?row.menu_composition_name:'undefined menu'}>
                            <Iconify
                              sx={{color: "#626262", textAlign: "center", cursor: "pointer", fontSize: "1.5em", top: "4px", position: "relative"}} icon="fluent:edit-16-regular" onClick={() => {
                              ax5DepUserIdEdit.current = row.id;
                              setAx5ModalOpen(true)


                            }}/>
                          </Tooltip>
                        </TableCell>
                        {user.ax_role<2&&<>


                          <TableCell align="center">

                          <Tooltip title="Edit ax4 menu">
                            <Iconify
                              sx={{color:"#626262",textAlign:"center",cursor:"pointer",fontSize:"1.5em",top:"4px",position:"relative"}} icon="fluent:edit-16-regular" onClick={()=>{ax4DepUserIdEdit.current=row.id;setAx4ModalOpen(true)}} />
                          </Tooltip>

                        </TableCell>
                        </>}

                        <TableCell align="center">{row.email_values}</TableCell>




                        <TableCell align="right"  style={{ verticalAlign: 'middle' }}>
                          <Tooltip title={`Posledná aktivita: ${row.last_activity_time}`}>
                          <Label
                            color={
                              (row.status === 1 && 'success') ||
                              (row.status === 0 && 'error') ||
                              (row.status === 2 && 'error') ||
                              (row.status === 3 && 'error') ||
                              'warning'
                            }
                            sx={{ position: 'relative',  top:-5,textTransform: 'none' }}
                          >
                            {
                              t(axStore.userStatuses[row.status].status_name)

                            }
                          </Label>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center"><Tooltip title={t('user.users_account')}>
                          <Iconify
                            sx={{color:"#626262",textAlign:"center",cursor:"pointer",fontSize:"1.5em",top:"4px",position:"relative"}} icon="mdi:user-outline" onClick={()=>{setUserIdToEdit(row.id);setDepIdForUserToEdit(0);userQuickEditModal.setValue(true)}} />
                        </Tooltip></TableCell>
 <TableCell align="center"><Tooltip title={t('user.disconnect_user_from_department')}>
                          <LinkOffIcon
                            sx={{color:"#626262",textAlign:"center",cursor:"pointer",fontSize:"1.5em",top:"4px",position:"relative"}} icon="fluent:edit-16-regular" onClick={()=>{unlinkUserIdEdit.current=row.id;setUnlinkUserModalOpen(true)}} />
                        </Tooltip></TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {(user.ax_role<2||user.department_role===1)&&
              <Grid xs={12}  >
                {(currentDep.users.length<currentDep.max_users)?
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={()=>{setUserIdToEdit(0);setDepIdForUserToEdit(0);userQuickEditModal.setValue(true)}}
                >
                  {t('dep.add_new_user')}
                </Button>:
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  disabled
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  {t('dep.max_users_reached')}
                </Button>}
              </Grid>}

            </Stack>
          </Grid>}

        {currentTab === 'web-config' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} container>

          <Grid xs={12} md={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h5">
                Základné nastavenia a metadáta
              </Typography>
              <Box sx={{display: {xs: 'none', sm: 'block'}}}/>
              <RHFSelect name="website_status" label="Status webstránky">

                <MenuItem key="0" value="0">Trvalo vypnutá</MenuItem>
                <MenuItem key="1" value="1">Aktívna</MenuItem>
                <MenuItem key="2" value="2">Prebieha údržba</MenuItem>
                <MenuItem key="3" value="3">Dočasný výpadok</MenuItem>
                <MenuItem key="4" value="4">Dočasné pozastavanie</MenuItem>
                <MenuItem key="5" value="5">Neuhradená</MenuItem>

              </RHFSelect>

              <RHFTextField name="website_name" label="Názov webovej stránky" size="small"/>
              <RHFTextField name="website_title" label="Titul webovej stránky" size="small"/>
              <RHFTextField name="website_canonic_url" label="Kánonická URL adresa" size="small"/>
              <RHFTextField name="website_description" label="Popis webovej stránky" size="small"/>
              <RHFTextField name="website_author" label="Autor webovej stránky" size="small"/>


            </Stack>
          </Grid>
          <Grid xs={12} md={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h5">
                Defaulty pre fotografie a dokumenty
              </Typography>
              <Box sx={{display: {xs: 'none', sm: 'block'}}}/>
              <RHFTextField name="website_image_width" label="Max šírka fotografií" size="small"/>
              <RHFTextField name="website_image_height" label="Max výška fotografií" size="small"/>
              <RHFTextField name="website_image_thumb_width" label="Max šírka náhľadov" size="small"/>
              <RHFTextField name="website_image_thumb_height" label="Max výška náhľadov" size="small"/>
              <RHFSelect name="website_images_sorting" label="Defaultné zoraďovanie obrázkov" size="small">
                <MenuItem key="1" value="0">Neurčené</MenuItem>
                <MenuItem key="2" value="1">Podľa názvu vzostupne</MenuItem>
                <MenuItem key="3" value="3">Podľa názvu zostupne</MenuItem>
                <MenuItem key="4" value="4">Od najstaršieho</MenuItem>
                <MenuItem key="5" value="5">Od najnovšieho</MenuItem>
                <MenuItem key="6" value="6">Podľa poradia</MenuItem>
              </RHFSelect>
              <RHFSelect name="website_documents_sorting" label="Defaultné zoraďovanie dokumentov" size="small">
                <MenuItem key="1" value="0">Neurčené</MenuItem>
                <MenuItem key="2" value="1">Podľa názvu vzostupne</MenuItem>
                <MenuItem key="3" value="3">Podľa názvu zostupne</MenuItem>
                <MenuItem key="4" value="4">Od najstaršieho</MenuItem>
                <MenuItem key="5" value="5">Od najnovšieho</MenuItem>
                <MenuItem key="6" value="6">Podľa poradia</MenuItem>
              </RHFSelect>

              <RHFSwitch name="website_show_documents_date" label="Zobrazovať dátum dokumentov"/>
              <RHFSwitch name="hash_file_path" label="Kryptovať názov súboru"/>
              <RHFSwitch name="website_show_title_photo_in_gallery" label="Zobrazovať titulné foto v galérii"/>
              <RHFSwitch name="website_show_title_photo" label="Zobrazovať titulné foto"/>
              <RHFSwitch name="website_show_image_gallery" label="Zobrazovať galériu"/>
              <RHFSwitch name="website_show_documents_table" label="Zobrazovať dokumenty"/>



            </Stack>
          </Grid>

        </Grid>}


        {currentTab === 'db-ftp' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} container>

          <Grid xs={12} md={6}  lg={6} xl={4} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia databázy
              </Typography>
                <RHFSwitch name="db_visible" label="Databáza je aktívna"/>
              {getValues('db_visible')&&(<>
              <RHFTextField name="db_server" label="DB Server" size="small"/>
              <RHFTextField name="db_user" label="DB User/login" size="small"/>
              <RHFTextField name="db_password" label="DB Password" size="small"/>
              <RHFTextField name="db_name" label="DB Name" size="small"/>
              <RHFTextField name="dsn_local" label="DSN LOCAL" size="small"/>
              <RHFTextField name="phpmyadmin" label="PHP MyAdmin" size="small"/>
              <RHFTextField name="db_port" label="DB Port" size="small"/>
              Pokročilejšie nastavenie:<br/>

                  Db server local napr. host=127.0.0.1;port=3306
                  <RHFTextField name="db_server_local" label="DB server local" size="small"/>
                  Db server unix socket napr. unix_socket=/var/run/mysqld/mysqld.sock
                  <RHFTextField name="db_server_unix_socket" label="DB server unix socket" size="small"/>
              </>
              )}
            </Stack>
          </Grid>
          <Grid xs={12} md={6} sx={{padding: 1}}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Nastavenia ftp
              </Typography>
               <RHFSwitch name="ftp_visible" label="FTP je aktívne"/>
              {getValues('ftp_visible')&&(<>
              <RHFTextField name="ftp_server" label="FTP Server" size="small"/>
              <RHFTextField name="ftp_name" label="FTP login" size="small"/>
              <RHFTextField name="ftp_password" label="FTP password" size="small"/>
              <RHFTextField name="ftp_dir" label="FTP dir" size="small"/>
              </>)}
            </Stack>
          </Grid>

        </Grid>}

 {currentTab === 'nastavenie-fakturacie' && (
   currentDep?.payment?(
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto'}} container>
          <Grid xs={12} sx={{padding: 1}} spacing={2}>
          <Typography variant="h4" >
            Nastavenia pravidelnej fakturácie
          </Typography>
            {((!currentDep?.payment?.submitted)||(currentDep?.payment?.submitted==="000-00-00"))?(<span style={{color:"orange",fontWeight:"bold",fontSize:"0.7em"}}>Pravidelná platba zatiaľ nebola dohodnutá !</span>):(<Tooltip title={`Bola dohodnutá dňa ${ConvertToNiceDate(currentDep.payment.submitted).niceDate}`}><span style={{color:"green",fontWeight:"bold",fontSize:"0.7em"}}>Pravidelná platba bola dohodnutá  !</span></Tooltip>)}
            <RHFSwitch name="payment_is_active" label={paymentActiveLabel}/>

          </Grid>
          <Grid xs={12} md={6} lg={6} xl={4} sx={{padding: 1}} >
<Stack spacing={2}>
  {isPaymentActive&&(<>
    <Tooltip title={`ID platby: ${currentDep?.payment?.id}`}>
    <Typography variant="h5">
                Základné nastavenie platby
              </Typography>
    </Tooltip>
    <RHFSwitch name="invoicing_with_invoice" label={invoicing_with_invoice_label}/>
              <RHFTextField name="payment_name" label="Názov platby" size="small"/>
              <RHFTextField name="payment_due_days" label="Splatnosť faktúry v dňoch" size="small"/>
              <RHFTextField name="payment_invoice_item" label="Text na faktúre" size="small"/>
    <small>pre renderované obdobie: [*rendered-invoice-period*]</small>
              <RHFTextField name="payment_periodicity_old" label="Pôvodná periodicita" size="small"/>
              <RHFTextField name="payment_base_price_old" label="Pôvodná cena" size="small"/>
              <RHFTextField name="payment_gb_included_in_hosting" label="Počet predplatených GB" size="small"/>


              <RHFTextField name="payment_over_prepaid" label="Prekročené množstvo" size="small"/>

              <RHFTextField name="payment_admin_interface" label="Administračné rozhranie" size="small"/>
              <RHFTextField name="payment_department_data_size" label="Obsadené miesto" size="small"/>
              <RHFTextField name="payment_department_data_size_comment" label="Čoho sa týka obsadené miesto" size="small"/>
              <RHFTextField name="payment_submitted" label="Kedy bola platba schválená (YYYY-MM-DD)" size="small"/>
              <RHFTextField name="payment_note" label="Poznámka" size="small"/>
              </>)}


</Stack>

          </Grid>
          <Grid xs={12} md={6}  lg={6} xl={4} sx={{padding: 1}}>
            {isPaymentActive&&(<Stack spacing={2}>
              <Typography variant="h5">
                Cena a rozsah služby
              </Typography>
              <RHFTextField name="payment_price_domain" label="Cena domény/mesiac" size="small"

              />
              <RHFTextField name="payment_domain_count" label="Počet domén" size="small"/>
              <RHFTextField name="payment_price_hosting" label="Cena hosting/mesiac" size="small"/>
              <RHFTextField name="payment_price_webspace" label="Cena webpriestor/mesiac" size="small"/>
              <RHFTextField name="payment_price_admin_interface" label="Cena licencia administračné rozhranie/mesiac" InputProps={{endAdornment:<InputAdornment position="end">€</InputAdornment>}} size="small"/>
              <RHFTextField name="payment_price_technical_support" label="Cena za technickú podporu/hodina" size="small"/>
              <RHFTextField name="payment_price_content_support" label="Cena za obsahovú administráciu/hodina" size="small"/>
              <RHFTextField name="payment_hours_prepaid_in_month_technical_support" label="Počet predpl. hodín tech.podp/mesiac" size="small"/>
              <RHFTextField name="payment_hours_prepaid_in_month_content_support" label="Počet predpl. hodín obsah.podp/mesiac" size="small"/>
              <RHFTextField name="payment_price_website_rental" label="Cena prenájmu webového sídla/mesiac" size="small"/>
              <RHFTextField name="payment_extra_price" label="Príplatok / mesiac" size="small"/>
              <RHFTextField name="payment_extra_price_comment" label="Čoho sa týka príplatok" size="small"/>

              {/* <RHFTextField name="payment_periodicity_current" label="Periodicita platby" size="small"/> */}
              <RHFTextField name="payment_discount_3_months" label="Zľava pri kvartálnej plabe" size="small"/>
              <RHFTextField name="payment_discount_6_months" label="Zľava pri polročnej plabe" size="small"/>
              <RHFTextField name="payment_discount_12_months" label="Zľava pri ročnej plabe" size="small"/>



              <RHFTextField name="payment_invoice_day" label="V ktorý deň v mesiaci vystaviť fa." size="small"/>
              <RHFTextField name="payment_zdan_plnenie" label="Dátum dodania služby - zdan. plnenie" size="small"/>
              <small>0(automaticky) začiatok, alebo koniec periódy,1 = v deň vystavenia faktúry</small>

              <RHFSelect name="payment_invoice_start_month" label="V ktorý mesiac začať fakturáciu najskôr">
                <MenuItem key="0" value="0">Automaticky (najbl. termín)</MenuItem>
                <MenuItem key="1" value="1">Január</MenuItem>
                <MenuItem key="2" value="2">Február</MenuItem>
                <MenuItem key="3" value="3">Marec</MenuItem>
                <MenuItem key="4" value="4">Apríl</MenuItem>
                <MenuItem key="5" value="5">Máj</MenuItem>
                <MenuItem key="6" value="6">Jún</MenuItem>
                <MenuItem key="7" value="7">Júl</MenuItem>
                <MenuItem key="8" value="8">August</MenuItem>
                <MenuItem key="9" value="9">September</MenuItem>
                <MenuItem key="10" value="10">Október</MenuItem>
                <MenuItem key="11" value="11">November</MenuItem>
                <MenuItem key="12" value="12">December</MenuItem>
              </RHFSelect>

              {/*              <RHFTextField name="payment_invoice_start_year" label="V ktorý rok začať fakturáciu najskôr" size="small"/> */}

              <RHFSelect name="payment_invoice_start_year" label="Ktorý rok začať najskôr fakturovať">
                {yearOptions}
              </RHFSelect>
              { parseInt(getValues('payment_periodicity_current'), 10)===12&&(
              <RHFSelect name="payment_invoice_month_before" label="Ktorý mesiac vystaviť faktúru">
                <MenuItem key="0" value="0">Automaticky</MenuItem>
                <MenuItem key="1" value="1">Mesiac vopred, ak sa platí dopredu</MenuItem>
              </RHFSelect>)}



            </Stack>)}
          </Grid>
          <Grid xs={12} md={12}  lg={12} xl={4} sx={{padding: 1}} >
            {isPaymentActive&&(<Stack spacing={0}>
              <Typography variant="h6" gutterBottom>
                Aktuálne nastavené
                {currentDep?.payment?.id&&(
                  <Tooltip title="Ukáž platbu v tabuľke nastavení všetkých platieb - pre kontrolu">
                    <IconButton
                      color="primary"
                      onClick={()=>window.open(`https://new.administrix.pro/utils/show_all_customers.php#platba_id_${currentDep.payment.id}`, '_blank')}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>)}
              </Typography>
              <Grid container spacing={1}>
              <Grid xs={6}>
              <RHFSelect name="payment_periodicity_current" label="Periodicita platby"  size="small">
                <MenuItem key="1" value="1">Mesačne</MenuItem>
                <MenuItem key="3" value="3">Kvartálne</MenuItem>
                <MenuItem key="6" value="6">Polročne</MenuItem>
                <MenuItem key="12" value="12">Ročne</MenuItem>
              </RHFSelect>
              </Grid>
                <Grid xs={6}>
              <RHFSelect name="payment_invoice_period" label="Obdobie fakturácie"  size="small">
                <MenuItem key="1" value="1">Za uplynulé obdobie</MenuItem>
                <MenuItem key="2" value="2">Za nasledujúce obdobie</MenuItem>
              </RHFSelect>
                </Grid>
                </Grid>
              <RHFSwitch name="payment_apply_discount" label="Aplikovať zľavu"/>

              <Typography variant="body2" gutterBottom>
                Pravidelnosť platby: <strong>{paymentOverview?.newPeriodicityText}</strong>, zľava <strong>{paymentOverview.new_apply_discount?`${paymentOverview.new_applicable_discount} %`:' sa neaplikuje'}</strong>
                <br/>  Suma platby: <strong>{paymentOverview.newFinalInvoicedPriceWithDiscount.toFixed(2)} €</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>Suma platby pred zľavou: <strong>{paymentOverview.newFinalInvoicedPrice.toFixed(2)} €</strong><br/></>)}
                Prepočet na mesiac: <strong>{(paymentOverview.newFinalInvoicedPriceWithDiscount/paymentOverview.new_periodicity).toFixed(2)} €</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>
                Úspora po zľave: <strong>{paymentOverview.newFinalInvoicedPriceDiscount.toFixed(2)} € /</strong> {paymentOverview.newPeriodicityTextNom}, <strong>{(paymentOverview.newFinalInvoicedPriceDiscount/paymentOverview.new_periodicity).toFixed(2)} € /</strong> mesiac</>)}
              </Typography>

              <Typography variant="body2" gutterBottom>
                Fakturujeme za obdobie: <strong>{invoiceDates[0]?.invoicePeriodText} </strong> k <strong>{invoiceDates[0]?.finalInvoiceIssueDay}.dňu v mesiaci</strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                Najbližšie faktúry:<br />
                {
                  !isInvoiceDatesCounting &&
                  invoiceDates.map((date, index) => (
                    <span key={index}>
      {!date.isToday ? (
        <span
          dangerouslySetInnerHTML={{
            __html: `<strong>${date?.finalInvoiceIssueDay}.${date.finalInvoiceIssueMonth}.${date.finalInvoiceIssueYear}</strong>, splatnosť <strong>${date.invoiceDueDays} dní</strong> za obdobie ${date?.finalRenderedInvoicePeriod}`,
          }}
        />
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: '<span style="color:red;font-weight:bold">DNES</span> - ešte zistiť, či už bola poslaná',
          }}
        />
      )}
                      <br />
    </span>
                  ))
                }

              </Typography>

              <Typography variant="body2" gutterBottom>
                Text: <strong>{getValues.payment_invoice_item} pre obdobie {invoiceDates[0]?.finalRenderedInvoicePeriod} </strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Rozdiel oproti pôvodnej cene:</strong><br />
                Mesačne: <span dangerouslySetInnerHTML={{ __html: priceDifference() }} /><br />
                Kvartálne: <span dangerouslySetInnerHTML={{ __html: priceDifference(3) }} /><br />
                Polročne: <span dangerouslySetInnerHTML={{ __html: priceDifference(6) }} /><br />
                Ročne: <span dangerouslySetInnerHTML={{ __html: priceDifference(12) }} /><br />
              </Typography>

              <Typography variant="h6" gutterBottom>
                Základná cena/mesiac sa počíta z:
              </Typography>
              <Paper sx={{fontSize:"0.7em",paddingLeft:"10px"}} >
                <div dangerouslySetInnerHTML={{ __html: paymentOverview.newPricePartsHtml }} />
              </Paper>
              <Typography variant="h6" gutterBottom sx={{marginTop:1}}>
                Potenciálne ceny jednotlivých periód:
              </Typography>

              <Typography variant="body2" gutterBottom>

                Pravidelnosť platby: <strong>mesačne</strong><br/>
                Suma platby: <strong>{paymentOverview.newSumForMonthlyInvoicing.toFixed(2)} €</strong><br/>


                <br/>
                Pravidelnosť platby: <strong>kvartálne</strong>, <strong>{paymentOverview.new_apply_discount?`${paymentOverview.newKvartalDiscountPerc} % zľava`:''}</strong>
                <br/>  Suma platby: <strong>{paymentOverview.newSumForKvartalInvoicingWithDiscount.toFixed(2)} € / kvartál</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>Suma platby pred zľavou: <strong>{paymentOverview.newSumForKvartalInvoicing.toFixed(2)} €</strong><br/></>)}
                Prepočet na mesiac: <strong>{(paymentOverview.newSumForKvartalInvoicingWithDiscount/3).toFixed(2)} €</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>
                  Úspora po zľave: <strong>{paymentOverview.newDiscountAmountPerKvartal.toFixed(2)} € /</strong> kvartál, <strong>{(paymentOverview.newDiscountAmountPerKvartal/3).toFixed(2)} € /</strong> mesiac</>)}

                <br/>
                <br/>
                Pravidelnosť platby: <strong>polročne</strong>, <strong>{paymentOverview.new_apply_discount?`${paymentOverview.newHalfYearDiscountPerc} % zľava`:''}</strong>
                <br/>  Suma platby: <strong>{paymentOverview.newSumForHalfYearInvoicingWithDiscount.toFixed(2)} € / polrok</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>Suma platby pred zľavou: <strong>{paymentOverview.newSumForHalfYearInvoicing.toFixed(2)} €</strong><br/></>)}
                Prepočet na mesiac: <strong>{(paymentOverview.newSumForHalfYearInvoicingWithDiscount/6).toFixed(2)} €</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>
                  Úspora po zľave: <strong>{paymentOverview.newDiscountAmountPerHalfYear.toFixed(2)} € /</strong> polrok, <strong>{(paymentOverview.newDiscountAmountPerHalfYear/6).toFixed(2)} € /</strong> mesiac</>)}

               <br/>
                <br/>
                Pravidelnosť platby: <strong>ročne</strong>, <strong>{paymentOverview.new_apply_discount?`${paymentOverview.newYearDiscountPerc} % zľava`:''}</strong>
                <br/>  Suma platby: <strong>{paymentOverview.newSumForYearInvoicingWithDiscount.toFixed(2)} € / rok</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>Suma platby pred zľavou: <strong>{paymentOverview.newSumForYearInvoicing.toFixed(2)} €</strong><br/></>)}
                Prepočet na mesiac: <strong>{(paymentOverview.newSumForYearInvoicingWithDiscount/12).toFixed(2)} €</strong><br/>
                {!!paymentOverview.new_apply_discount&&(<>
                  Úspora po zľave: <strong>{paymentOverview.newDiscountAmountPerYear.toFixed(2)} € /</strong> rok, <strong>{(paymentOverview.newDiscountAmountPerYear/12).toFixed(2)} € /</strong> mesiac</>)}



              </Typography>



            </Stack>)}
          </Grid>
        </Grid>):(<span style={{color:"red"}}><br/>V hlavnej AX databáze, v tabuľke ax_regular_payments sme nenašli zodpovedajúci riadok podľa depId. <br /><br />
     {currentDep?.payment_by_company&&<span>Našli sme riadok ax_regular_payments zodpovedajúci firme {currentDep.website_company.name} pod id {currentDep.payment_by_company.id} <br /><br /></span>}

     Skontrolujte, prípadne ho pridajte  manuálne v PHPmyAdmine !</span>)
 )

 }


      </Box>


    </Grid>

    <Grid>


      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      />
    </Grid>
  </Grid>
      </DialogContent>
  </>):null

      )

  return (
    <>
      <CompanyQuickEditForm compIdToEdit={myCompanyId} companyOfDepId={companyOfDepId} open={myCompanyEditOpen} onClose={()=>setMyCompanyEditOpen(false)} isDepartmentMainCompany={isDepartmentMainCompany}/>

    <Dialog
      fullWidth
      maxWidth={dialogWidth}
      open={open}
      onClose={handleCloseModal}
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit, onError)}

      >
          {(depIdToEdit!==0)?renderEditDepartment:renderAddNewDepartment  }
          {/* console.log("cely komponente render, depIdToEdit:",depIdToEdit,typeof depIdToEdit) */}
          {/* renderEditDepartment */}

        <DialogActions>


          {(isDirty && depIdToEdit===0) && <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<QueuePlayNext/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            {t('dep.create_new_department')}
          </LoadingButton>
          }
          {(isDirty&&depIdToEdit>0) && <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<SaveIcon/>}
            sx={{ml: 2, backgroundColor: '#cc0d0d', color: '#fff'}}
          >
            {t('dep.save_changes')}
          </LoadingButton>
          }

          <Button variant="outlined" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
    </>
  );
}

DepartmentQuickEditForm.propTypes = {
  depIdToEdit: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setDepOldIdToEdit:PropTypes.func,
  setDepIdToEdit:PropTypes.func,
  loadDeps:PropTypes.func,
  depOldIdToEdit:PropTypes.number,
  currentDepAsProp: PropTypes.object,
};
