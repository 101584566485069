import { Helmet } from 'react-helmet-async';
// sections
import { AboutView } from 'src/sections/about/view';
import {useEffect, useState} from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import {CircularProgress} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import StorageIcon from '@mui/icons-material/Storage';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Alert from "@mui/material/Alert";
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useRouter} from "../routes/hooks";
import {fData} from "../utils/format-number";

const useQuery = () => new URLSearchParams(useLocation().search);
// ----------------------------------------------------------------------
function forceDownload(href, filename) {
  const anchor = document.createElement("a");
  anchor.href = href;
  anchor.download = filename;
  // document.body.appendChild(anchor);
  anchor.click();
}
export default function DownloadPage() {
  const { t, onChangeLang } = useTranslation()
  const [downloadData, setDownloadData] = useState(null);
  const[isLoading,setIsLoading]=useState(true) // getting download data
  const [downLoadingStatus, setDownLoadingStatus] = useState(0);// 0 - not downloading, 1-downloading has begin succesfuly, 2-downloading didn't started beacuse any problem on server side
  const [gettingPreDownloadData, setGettingPreDownloadData] = useState(false);
  const query = useQuery();
  const did = query.get('did');
  const auth = query.get('auth');

  const handleClick = () => {
    console.log('start download')
    setGettingPreDownloadData(true);
    // forceDownload
    axios.get('https://api.administrix.pro/v1/get/index.php', {
      params: {'action': 'download_file', 'did': downloadData.did, 'auth': downloadData.auth, 'rcvr': downloadData.rcvr}
    })
      .then((response => {

        if(response.data.data.download_link) {
          forceDownload(response.data.data.download_link,response.data.data.download_file_name)
          setDownLoadingStatus(1)
        }
        else {
          setDownLoadingStatus(2);
          //  console.log('downloading failed')
        }
      }))
      .catch(error => {

        setDownLoadingStatus(2);
      })
  }



  useEffect(() => {
    setIsLoading(true);

      if(did&&auth) {
        axios.get('https://api.administrix.pro/v1/get/index.php', {
          params: {'action': 'get_download_public_info', 'did': did, 'auth': auth, 'rcvr': ''}
        })
          .then((response => {

            setDownloadData(response.data.data)

          }))
          .catch(error => {

          })
      }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[did,auth])

  if (downloadData) {

    return(
      <>
      <Helmet>
        <title> Download</title>
      </Helmet>
          <Container style={{marginTop: 40, textAlign: "center"}}>

            <Paper variant="outlined" sx={{ p: 3, borderRadius: 2,marginBottom:5 }}>
              <Stack spacing={1} flexGrow={1}>
                <Stack spacing={0.5}>
                  {!!downloadData.can_download &&<Typography variant="h6">
                    {t('px.files_to_download')}:</Typography>}
                  <Typography variant="h3">{downloadData.download_name}</Typography>

                  {downLoadingStatus===1&&(<Alert severity="success">
                    {t('px.thanks_download_just_begun')}
                  </Alert>)}
                  {downLoadingStatus===2&&(<Alert severity="error">
                    {t('px.we_are_sorry_download_error')}

                  </Alert>)}
                  {downLoadingStatus===0&&(<>
                    {!!downloadData.can_download && <>
                      <Typography variant="h7">
                        <Typography sx={{  minWidth: 100 }}> {downloadData.files_count} {t('files_gen')} {downloadData.is_zip===1&&(<> {t('px.in_the_zip_archive')}</>)} </Typography>
                        <Typography sx={{ minWidth: 100 }}>{t('download_size')}: {fData(downloadData.download_size)} </Typography>
                        {(downloadData.permanent==="1")&&(<Typography sx={{ minWidth: 100 }}>  {t('px.permanent_download')}</Typography>)}
                        <Typography sx={{ minWidth: 100 }}>  {t('valid_until')} {downloadData.valid_date}</Typography>
                      </Typography>

                      <LoadingButton
                        size="large"
                        onClick={handleClick}
                        loading={gettingPreDownloadData}
                        loadingIndicator={t('px.download_prepare')}
                        variant="contained"
                        endIcon={<FileDownloadIcon/>}
                        color="primary"
                      >
                        {t('download1')}
                      </LoadingButton>
                    </>
                    }
                    {!downloadData.can_download && <>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        {downloadData.reason===1&&<Alert severity="error">
                          {t('px.download_expired')}
                        </Alert>}
                        {downloadData.reason===2&&<Alert severity="error">
                          {t('px.download_not_found')}
                        </Alert>}
                        {downloadData.reason===3&&
                          <Box >
                            <Alert severity="warning" sx={{
                              '& .MuiAlert-message': {
                                width: '100%',
                                textAlign: 'center',
                              },
                            }}>{t('px.download_preparing')}</Alert>

                          </Box>
                        }

                      </Stack>
                    </>
                    }</>)}

                </Stack>





              </Stack>
            </Paper>
          </Container>
        </>
    )
  }
  if (!downloadData) {

    return(
      <>
      <Helmet>
        <title> Download</title>
      </Helmet>
          <Container style={{marginTop: 40, textAlign: "center"}}>
            <div>
              {isLoading?<CircularProgress />:<Alert severity="error">
                {t('px.download_not_valid')}
              </Alert>}
            </div>
          </Container>
        </>
    )
  }







}
