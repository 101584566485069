
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import {Autocomplete, LinearProgress, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {useSnackbar} from "../../components/snackbar";
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import {useAxStore} from "../../store/axStore";
import axios, {endpoints} from "../../utils/axios";
import {useBoolean} from "../../hooks/use-boolean";
import DepartmentQuickEditForm from "../../sections/department/department-quick-edit-form";
import CompanyQuickEditForm from "../../sections/company/company-quick-edit-form";
import {useAuthContext} from "../../auth/hooks";
import {fNumber} from "../../utils/format-number";

// ----------------------------------------------------------------------




export default function NavVertical({ openNav, onCloseNav }) {
  // const axStoreDepartments = useAxStore((state) => state.axDepartments)
  const axStore=useAxStore();
  const autocomoplete=true;
  const readonly=true;

  const user=axStore.axCurrentUser
  const pathname = usePathname();
  const lgUp = useResponsive('up', 'lg');
  const navData = useNavData();
  // const [curWebSelectbox,setCurWebSelectbox]=useState({ label: "Nevybraté",id: "0" });
  const [curWebSelectbox,setCurWebSelectbox]=useState('');
  const [isGettingDepDashData,setIsGettingDepDashData]=useState(false);
  const [isSwitchingWeb,setIsSwitchingWeb]=useState(false);
  const [webNameSwitchingTo,setWebNameSwitchingTo]=useState('');
  const { enqueueSnackbar } = useSnackbar();

  /*  departmen quick edit modal */
  const[depToEdit,setDepToEdit]=useState({})
  const[depIdToEdit,setDepIdToEdit]=useState(0)
  const[depOldIdToEdit,setDepOldIdToEdit]=useState(0)
  const quickEdit = useBoolean(false);
  /*  company quick edit modal */
  const[compToEdit,setCompToEdit]=useState({})
  const[compIdToEdit,setCompIdToEdit]=useState(0)
  const[compOldIdToEdit,setCompOldIdToEdit]=useState(0)
  const compQuickEdit = useBoolean(false);

  function readDepDashStat(){
    setIsGettingDepDashData(true)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_dep_dash_data',
       // 'depid': depId
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          // console.log('get_dep_dash_data response:')
          // console.log(response)
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            // eslint-disable-next-line no-inner-declarations
            function convertStringToInteger(value) {
              const intValue = parseInt(value, 10);
              // eslint-disable-next-line no-restricted-globals
              return isNaN(intValue) ? value : intValue;
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const key in data.cur_dep_dash_data) {
              if (typeof data.cur_dep_dash_data[key] === "string") {
                data.cur_dep_dash_data[key] = convertStringToInteger(data.cur_dep_dash_data[key]);
              }
            }
            axStore.setCurrentDepartmentDashData(data.cur_dep_dash_data)
            axStore.setAxDepLastArticles(data.cur_dep_dash_data.last_articles)
            if (data.access_data) axStore.setAxDepAccessData(data.access_data)

            if(data.cur_dep_dash_data.pixme_stat&&data.cur_dep_dash_data.pixme_stat.file_count)axStore.setIsPixmeEmpty(false);

          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri získavaní údajov o webe ${axStore.axCurrentDepartment.name}: ${error.title}`,{ variant: `error` });
          }
          setIsGettingDepDashData(false)
        }))
        .catch(error => {
            // console.log("any get_dep_dash_data fetch api error");
            // console.log(error);
           // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setIsGettingDepDashData(false)
        })
    }
  }
/*
  useEffect(() => {
//    console.log("axStore.axDepartments.length")
//    console.log(Object.keys(axStore.axDepartments).length)
//    console.log(axStore.axDepartments)
//    console.log(typeof axStore.axDepartments)

    }, [axStore.axDepartments.length,axStore.axDepartments]);
*/
  useEffect(() => {
     setCurWebSelectbox({"id": axStore.axCurrentDepartment?.id,"label": axStore.axCurrentDepartment?.name})
    // console.log(" useEffect with dep axStore.axCurrentDepartment.id ")
//     console.log(axStore.axCurrentDepartment.id)
  if(axStore.axCurrentDepartment?.id>0)readDepDashStat(axStore.axCurrentDepartment?.id)
  // get department dashboard data and statistics

    // const currentjwt=response.data.meta.jwt;
    // const user=response.data.data.user_data;
     // console.log('axStore.axCurrentDepartment:',axStore.axCurrentDepartment)
    // axStore.setAxDepartments(response.data.data.deps)
setDepIdToEdit(parseInt(axStore.axCurrentDepartment?.id,10))
    setCompIdToEdit(parseInt(axStore.axCurrentDepartment?.company_id,10))

  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axStore.axCurrentDepartment?.id, axStore.axCurrentDepartment?.name]);

  const switchToDep = (value) => {  // value has id and web label
 // console.log('switching to ',value.id)
    // alert(`vybrali ste ${value.label} a idem smerovat do ${value.id}`);
    setIsSwitchingWeb(true)
    setWebNameSwitchingTo(value.label)
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'force_select_department',
        'depid': value.id
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          // console.log(response.data)
          // console.log(typeof response.data.data.success)
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
            if (response.data.data.success === 1) {
              setDepIdToEdit(parseInt(response.data.data.cur_dep.id,10))
              axStore.setCurrentDepartment(response.data.data.cur_dep)
              setCurWebSelectbox({"id": response.data.data.cur_dep.id, "label": response.data.data.cur_dep.name})
              // readDepDashStat(response.data.data.cur_dep.id)
              // enqueueSnackbar(`Zmenil som web na ${response.data.cur_dep.name}`)
              // console.log(`Zmenil som web na ${response.data.data.cur_dep.name}`)
              axStore.setAxUserMenu(response.data.data.cur_dep_user_menu)
            } else {
              // enqueueSnackbar(`Akcia sa nepodarila: ${response.data.reason}`)
              // console.log("nezmenil som web")
              enqueueSnackbar(`Nepodaril sa pripojiť na web ${value.label}:  ${response.data.reason}`, {variant: `error`});
            }
          }else
          if (response.data.error) {
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
           switchToDep({id:user.default_dep_id,label:''})
            // console.log('mal by som zmenit na ',user.default_dep_id)
          }

          setIsSwitchingWeb(false)

        }))
        .catch(error => {
          setIsSwitchingWeb(false)
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })
    }





  }
  const handleOptionSelectedDepClick = (event,value, reason) => {
    // console.log("onchange")
    // console.log(event)
    // console.log(value)
    // console.log(reason)
      if (reason === 'selectOption') switchToDep(value)
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAxStat = (
    <>
    <Typography variant="subtitle1" gutterBottom sx={{mt:"20px"}} xs={12} textAlign="center">
      AdministriX stat
    </Typography>
      <Typography variant="caption" gutterBottom xs={12} textAlign="center">
        S3 provider: {axStore.systemCurrentConfig?.s3?.provider}
      </Typography>
      <Typography variant="caption" gutterBottom xs={12} textAlign="center">
        Whole piXme managed files: {fNumber(axStore.axCurrentDepartmentDashData?.pixme_stat?.whole_pixme_managed_files)}
      </Typography>

    </>
  )
    const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {axStore.axCurrentDepartment?.logo_url&&<Logo sx={{ mt: 4, mr: 3, ml: 2, mb:2 }} url={axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.axCurrentDepartment.logo_url}/>}

      {Object.keys(axStore.axDepartments).length===1&&(
        <Grid container spacing={2} sx={{ mt: 3}}>
          <Grid xs={10}>
        <div style={{textAlign:"center"}}>{axStore.axCurrentDepartment.name}</div>
          </Grid>
        </Grid>

      )

      }

      {(Object.keys(axStore.axDepartments).length>1)&&(
        <>
        <Grid container spacing={2}>
          <Grid xs={10}>
            <Autocomplete
              autoComplete={autocomoplete}
              value={curWebSelectbox !== '' ? curWebSelectbox : null}
              // defaultValue={curWebSelectbox}
              disablePortal
              id="combo-box-demo"
              size="small"

              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width:"100%",marginLeft:"15px",mt:"10px" }}
              options={Object.values(axStore.axDepartments)}
              onChange={handleOptionSelectedDepClick}
              renderInput={(params) => <TextField {...params} label="Spravujete:" />}
            />

            {/* user?.ax_role<=2&&<>
            <CompanyQuickEditForm compIdToEdit={compIdToEdit} currentDepAsProp={compToEdit} setCompOldIdToEdit={setCompOldIdToEdit} setCompIdToEdit={setCompIdToEdit} compOldIdToEdit={compOldIdToEdit} open={compQuickEdit.value} onClose={compQuickEdit.onFalse} companyOfDepId={19} />
            <Typography variant="body1" onClick={compQuickEdit.onTrue} style={{ cursor: 'pointer', color: 'blue',width:"100%",textAlign:"center" }}>
              {axStore.axCurrentDepartmentDashData?.website_company?.name}
            </Typography>
            </> */}


          </Grid>
          <Grid xs={2}>
            {user?.ax_role<=2&&<>
            <IconButton aria-label="delete"
                        sx={{bottom:"3px"}}
                        onClick={()=>quickEdit.onTrue()}
                        color="primary"
                        title={`Nastavenie ${axStore.axCurrentDepartment?.name}`}
            >
              <SettingsSuggestIcon fontSize="small" />
            </IconButton>
</>}

          </Grid>
        </Grid>



          <DepartmentQuickEditForm depIdToEdit={depIdToEdit} currentDepAsProp={depToEdit} setDepOldIdToEdit={setDepOldIdToEdit} setDepIdToEdit={setDepIdToEdit} depOldIdToEdit={depOldIdToEdit} open={quickEdit.value} onClose={quickEdit.onFalse}  />




        </>

        )}
      {isSwitchingWeb&&(<Grid ><Typography align="center" sx={{fontSize:"0.6em"}}>prepínam na web {webNameSwitchingTo}...</Typography><LinearProgress /></Grid>)}
          {isGettingDepDashData&&(<Grid><Typography align="center" sx={{fontSize:"0.6em"}}>získavam údaje o webe {axStore.axCurrentDepartment.name}...</Typography><LinearProgress /></Grid>)}

      {navData?
      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />:<Typography variant="body1">Nepriradené menu</Typography>
      }

      {user.ax_role===0&&renderAxStat}

      <Box sx={{ flexGrow: 1 }} />


       <NavUpgrade />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      {/*      <NavToggleButton /> */}

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}

        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}

        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
