import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {useAxStore} from "../store/axStore";
import {RHFTextField} from "../components/hook-form";
import Iconify from "../components/iconify";
import {useSnackbar} from "../components/snackbar";
import {useBoolean} from "../hooks/use-boolean";
import axios from "../utils/axios";


const Ax5UserDepIncQuickEditForm = ({open, setOpen, userId,depId,setNeedReloadDepEditData}) => {
  const { t, onChangeLang } = useTranslation()
  const axStore=useAxStore();
  const [menus,setMenus]=useState([])
  const [userName,setUserName]=useState('')
  const [departmentName,setDepartmentName]=useState('')
  const user=axStore.axCurrentUser
  const { enqueueSnackbar } = useSnackbar();
  const ax5DepUserIdEdit = useRef(0)


  useEffect(()=> {
    if(open) {
      // console.log('axuserdep modal opened, user',userId)

      let headers={}
      const jwt = localStorage.getItem('axjwt');
      if(jwt) {
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        const params = {
          'action': 'get_ax5_user_dep_settings',
          'uid': userId,
          'depid': depId,
        }
        axios.get('https://api.administrix.pro/v1/get/', {
          headers,
          params
        })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;

              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
              if (data.menu_list)setMenus(data.menu_list)
              if (data.settings) {
                setFormData(prevFormData => ({
                  ...prevFormData,  // keep previous formData values
                  ...data.settings       // update with new settings values
                }));
setSelectedMenu(data.settings.menu_composition_id)
setSelectedRole(data.settings.role)
setUserName(data.user_name)
                setDefaultPage(data.settings.default_page)
setDepartmentName(data.department_name)
              }
            }else
            if (response.data.error) {
              const {error} = response.data;
              // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
            }



          }))
          .catch(error => {

            // console.log("any force_select_department fetch api error");
            // console.log(error.title);

            // setErr(error);
            // setIsUploadedFilesQueueProcessing(false)
            // return (final_user)
          })
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])
  const [selectedRole, setSelectedRole] = useState(2); //
  const [selectedMenu, setSelectedMenu] = useState(0); //
  const [defaultPage, setDefaultPage] = useState(''); //
  const [formData, setFormData] = useState({
    default_page: '',

  });

  const handleChangeMenu = (event) => {
    const { value } = event.target;
    setSelectedMenu(value);

    // You can also dispatch or call an API to save the selected role, if needed.
    // Example: saveUserRole(value);
  };
  const handleChangeRole = (event) => {
    const { value } = event.target;
    setSelectedRole(value);

    // You can also dispatch or call an API to save the selected role, if needed.
    // Example: saveUserRole(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDefaultPage(value)
  };

  const handleSubmit = async () => {


    const jwt = localStorage.getItem('axjwt');
    if (jwt) {

      const postData = new FormData();
      // Object.keys(formData).forEach(key => postData.append(key, formData[key]));
      postData.append('action','save_ax5_user_dep_setting')
      postData.append('uid',userId)
      postData.append('depid',depId)
      postData.append('default_page',defaultPage)
      postData.append('role',selectedRole)
      postData.append('menu_composition_id',selectedMenu)

//      postData.append('role',selectedRole)


      axios.post('https://api.administrix.pro/v1/post/',
        postData, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === true) {

                enqueueSnackbar("Výborne, nastavenia sme uložili!", {variant: `success`, autoHideDuration: 6000});
                setNeedReloadDepEditData(1);
                setOpen(false)

              } else {
                enqueueSnackbar("Bohužiaľ, nastavenia sa nám nepodarilo uložiť :/", {variant: `error`, autoHideDuration: 6000});

              }


          } else if (response.data.error) {
//            const {error} = response.data;
            enqueueSnackbar(`Chyba : ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});

          }

        }))
        .catch(error => {
          console.log("any saving user ax5 setting  fetch api error");

        })

    }


//    setOpen(false)
  };






  return (

    <Dialog open={open}  maxWidth="sm" fullWidth >
        <DialogTitle>
          {t('dep.user_in_department_setting_ax5')}
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
  <Grid sm={12}>

    <Typography variant="body">{t('dep.setting_for')} {userName} {t('dep.in')} {departmentName}</Typography>

    <FormControl fullWidth variant="outlined" margin="normal" >
      <InputLabel id="ax5_role">{t('dep.user_role_in_department')}</InputLabel>
      <Select
        labelId="ax5_role"
        name="ax5_role"
        value={selectedRole}
        label={t('dep.user_role_in_department')}
        onChange={handleChangeRole}
      >
        {Object.keys(axStore.departmentRoles).map(roleKey => {
          const role = axStore.departmentRoles[roleKey];
          return (
            <MenuItem value={role.role_level} key={role.role_level} title={t(role.role_desc)}>
              {t(role.role_name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>


      <TextField
        fullWidth
        margin="normal"
        id="default_page"
        name="default_page"
        label={t('dep.default_page')}
        value={defaultPage}
        onChange={handleChange}
        helperText="e.g. /articles | /dashboard/pixme-dash | /dashboard/pixme/folders"
      />
    {/*
      <TextField
        fullWidth
        margin="normal"
        id="role"
        name="role"
        label="Role"
        value={formData.role}
        onChange={handleChange}
        helperText="role pre deparment"
      />
*/}

    {user.ax_role<2&&
      <FormControl fullWidth variant="outlined" margin="normal" >
        <InputLabel id="ax5_menu">{t('dep.user_menu_in_department')}</InputLabel>
        <Select
          labelId="ax5_menu"
          name="ax5_menu"
          value={selectedMenu}
          label={t('dep.user_menu_in_department')}

          onChange={handleChangeMenu}
        >
          {menus.map(menu => (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
    }

      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{marginBottom:"20px",marginTop:"20px"}}>
        {t('dep.save_changes')}
      </Button>



  </Grid>
          </DialogContentText>
        </DialogContent>

      </Dialog>
  );
};
Ax5UserDepIncQuickEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen:PropTypes.func,
  setNeedReloadDepEditData:PropTypes.func,
  userId:PropTypes.number,
  depId:PropTypes.number

};
export default Ax5UserDepIncQuickEditForm;
