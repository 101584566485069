import create from 'zustand'

const useStore = create((set) => ({
    filesToUpload: [],
    rejectedFiles: [],
    isUploading: "ready",
    wholeUploadProcess: 0,
    setFilesToUpload: (newFiles) => set((state) => ({ filesToUpload: {...newFiles} })),
    setWholeUploadProcess: (currentProcess) => set((state) => ({ wholeUploadProcess: currentProcess })),
    setIsUploading: (isUploadingStatus) => set((state) => ({ isUploading: isUploadingStatus })),
}))
export const useUploadStore = useStore;
