import React, {useCallback, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

// eslint-disable-next-line import/no-extraneous-dependencies
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line import/no-extraneous-dependencies
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// eslint-disable-next-line import/no-extraneous-dependencies
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// eslint-disable-next-line import/no-extraneous-dependencies
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import {QueuePlayNext} from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useAxStore} from "../store/axStore";
import axios from "../utils/axios";
import {RHFSelect} from "../components/hook-form";

const ConfirmExistingUserModal = ({open, email, status, depname,setOpen,canEdit, setConfExistUserModalResponse,setUserIdToEdit,userid,depid}) => {
/*

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  */
  const axStore=useAxStore();
  const user=axStore.axCurrentUser
  const { t, onChangeLang } = useTranslation()
  const [selectedRole, setSelectedRole] = useState(2); //
  const isAssigning = useRef(false);
  const submitUserAssign = useCallback(() => {
    if (!isAssigning.current) {
      isAssigning.current = true

      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'assign_user_to_dep');
        postValues.append('user_id', userid);
        postValues.append('dep_id', depid);
        postValues.append('admin_role', selectedRole);


        // console.log(postValues)
//        return

        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

              if (data.success === true) {
                enqueueSnackbar("Assign succeed !", {variant: `success`, autoHideDuration: 6000});
                // reset(user_data_to_save)
                // loadUserDataToEdit(currentUser.id);
                // loadUserDataToEdit(dep_data_to_save.depid)
                 setUserIdToEdit(userid)
                setOpen(false)
              } else {
                enqueueSnackbar("Some shit happened, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Assign error: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }
            isAssigning.current = false;

          }))
          .catch(error => {
            console.log("any saving user data fetch api error");
            isAssigning.current = false;
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depid,userid,selectedRole]);

  const handleChange = (event) => {
    const { value } = event.target;
    console.log('setting new role ',value)
    setSelectedRole(value);

    // You can also dispatch or call an API to save the selected role, if needed.
    // Example: saveUserRole(value);
  };


  return (
    <Dialog open={open}  maxWidth="sm" fullWidth>
        <DialogTitle>
          {t('user.user_account')} {email} {t('user.already_exists_in_system')}.
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>

            {status===1&&
<>

                {t('user.you_wish_to_assign_to_dep')} {depname} ? <br/><br/>
                {t('user.set_admin_rights')}:
              <FormControl fullWidth variant="outlined" margin="normal" >
                <Select
                  name="user_ax_role_new"
                  value={selectedRole}
                  onChange={handleChange}
                >
                  {Object.keys(axStore.departmentRoles).map(roleKey => {
                    const role = axStore.departmentRoles[roleKey];
                    return (
                      <MenuItem value={role.role_level} key={role.role_level} title={t(role.role_desc)}>
                        {t(role.role_name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
</>
            }
            {status===2&&
              <>
                {t('user.account_is_already_assigned')} {depname}.
              </>
            }

        </DialogContent>
      <DialogActions>


        {(status===1) && <LoadingButton
          /* type="submit" */
           onClick={submitUserAssign}
          variant="contained"
          size="large"
          loading={isAssigning.current}
          startIcon={<PersonAddIcon/>}
          sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
        >
          {t('user.assign_account')}
        </LoadingButton>
        }

        {(status===2&&canEdit===1) && <LoadingButton
          /* type="submit" */
          onClick={()=>setUserIdToEdit(userid)}
          variant="contained"
          size="large"
          // loading={isSubmitting}
          startIcon={<ManageAccountsIcon/>}
          sx={{ml: 2, backgroundColor: '#063056', color: '#fff'}}
        >
          {t('user.edit_account')}
        </LoadingButton>
        }

        <Button variant="outlined" onClick={()=>setOpen(false)}>
          {t('close')}
        </Button>
      </DialogActions>
      </Dialog>
  );
};
ConfirmExistingUserModal.propTypes = {
  open: PropTypes.bool,
  email:PropTypes.string,
  status:PropTypes.number,
  canEdit:PropTypes.number,
  userid:PropTypes.number,
  depid:PropTypes.number,
  depname:PropTypes.string,
  setConfExistUserModalResponse:PropTypes.func,
  setUserIdToEdit:PropTypes.func,
  setOpen:PropTypes.func,

};
export default ConfirmExistingUserModal;
