import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
 import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePageAx, HomePagePixme, HomePagePixmeTenant } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
// import {useAxStore} from "../../store/axStore";


// ----------------------------------------------------------------------

export default function Router(systemNameValue,subDomainValue) {
  let homePageElement
  if(systemNameValue.systemNameValue.includes("piXme")){
    switch (subDomainValue.subDomainValue) {
      case 'visitbratislava':
        homePageElement = (
          <MainLayout>
            <HomePagePixmeTenant />
          </MainLayout>
        );
        break;
      case 'centralslovakia':
        homePageElement = (
          <MainLayout>
            <HomePagePixmeTenant />
          </MainLayout>
        );
        break;
      // Add more cases as needed for different subdomain values
      default:
        homePageElement =(
          <MainLayout>
            <HomePagePixme />
          </MainLayout>)
    }
  }else{
    homePageElement =(
    <MainLayout>
      <HomePageAx />
    </MainLayout>)

  }



  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
/*
     {
       path: '/',
       element: <Navigate to={PATH_AFTER_LOGIN} replace />,
     },
*/
    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE

    {
      path: '/',
      element:
        homePageElement,
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
