// @mui
import {enUS, frFR, skSK,csCZ} from '@mui/material/locale';
import LanguageDetector from 'i18next-browser-languagedetector';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
{
    label: 'English',
    lang_name_english: 'English',
    value: 'en',
    systemValue: enUS,
//    icon: 'flagpack:us',
    icon: 'flagpack:gb-ukm',
  }
   ,
  {
    label: 'Français',
    lang_name_english: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: 'flagpack:fr',
  },  {
    label: 'Slovenčina',
    lang_name_english: 'Slovak',
    value: 'sk',
    systemValue: skSK,
    icon: 'flagpack:sk',
  }, /* {
    label: 'Česky',
    value: 'cz',
    systemValue: csCZ,
    icon: 'flagpack:cz',
  },
  /* {
    label: 'Vietnamese',
    value: 'vi',
    systemValue: viVN,
    icon: 'flagpack:vn',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: 'flagpack:cn',
  },
  {
    label: 'Arabic',
    value: 'ar',
    systemValue: arSA,
    icon: 'flagpack:sa',
  }, */
];

const userLanguage = navigator.language || navigator.userLanguage;
const languageCode = userLanguage.split('-')[0]; // e.g., "en"

// export const defaultLang = allLangs[3]; // Slovak
export const defaultLang =  allLangs.find(lang => lang.value === languageCode) || allLangs[2];


// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
