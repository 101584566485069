
import create from 'zustand'

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// Utility function to load from local storage
const loadFromLocalStorage = (key) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : [];
};


export const useAxStore = create((set,get) => ({
  systemAuthState:'loading', // authenticated, unauthenticated
  isPixmeEmpty:true, // if pixme has 0 files - especially if new department - for welcome screen

  systemDefault:{
    // s3_system_url:"https://eu2.contabostorage.com/1ad5f744cecb42b59814cf76d79c6ebe:ax-public/",
    // s3_system_url:"https://ax-public.fra1.digitaloceanspaces.com/",

    avatarDef:`avatars/avatar-def${Math.floor(Math.random() * 8) + 1}.jpg`,
    avatarDefMale:"avatars/avatar-def-man.jpg",
    avatarDefFemale:"avatars/avatar-def-woman.jpg"
  },
  axRoles: {
    0: { role_level: 0, role_name: 'roles.ultraadmin', role_desc: 'roles.ultraadmin_desc' },
    1: { role_level: 1, role_name: 'roles.superadmin', role_desc: 'roles.superadmin_desc' },
    2: { role_level: 2, role_name: 'roles.user', role_desc: 'roles.ax_user_desc' },
    3: { role_level: 3, role_name: 'roles.user', role_desc: 'roles.ax_user_desc' },
    4: { role_level: 4, role_name: 'roles.user', role_desc: 'roles.ax_user_desc' },
    5: { role_level: 5, role_name: 'roles.user', role_desc: 'roles.ax_user_desc' },
  },
  departmentRoles: {
    1: { role_level: 1, role_name: 'roles.main_administrator', role_desc: 'roles.main_administrator_desc' },
    2: { role_level: 2, role_name: 'roles.content_moderator', role_desc: 'roles.content_moderator_desc' },
    3: { role_level: 3, role_name: 'roles.editor', role_desc: 'roles.editor_desc' },
    4: { role_level: 4, role_name: 'roles.user', role_desc: 'roles.dep_user_desc' },
  },
  userStatuses: {
    1: { status: 1, status_name: 'roles.active_account' },
    0: { status: 0, status_name: 'roles.disabled_account'},
    2: { status: 2, status_name: 'roles.banned_account' },
    3: { status: 3, status_name: 'roles.pending_account' },
  },


  systemCurrentConfig:{},
  axDepartments: [{label: "Nevybraté" , id: "0"}],
  axCompanies: [{label: "Nevybraté" , id: "0"}],
  axVillages: [{"id": "0", "name": "Neuvedené", "code": "0"}],
  axMotivational: [{text: "" , author: ""}],
  axEventDepConfig: [],
  axUserDepConfig:{themeColorPresets: "purple",themeContrast: "default",themeDirection: "ltr", themeLayout: "vertical",themeMode: "light",themeStretch: false},
  axUserMenu: [],
  axDepLastArticles: [],
  axCurrentDepartment: {},
  axCurrentUser:{},
  axDepAccessData: {
      db:{},
      ftp:{}
  },
  axAcceptedFileTypes:{
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
    'image/bmp': ['.bmp'],
    'image/webp': ['.webp'],
    'application/msword': ['.doc'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.rar': ['.rar'],
    'application/zip': ['.zip'],
    'text/html': ['.htm', '.html'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav'],
    'audio/x-ms-wma': ['.wma'],
    'application/postscript': ['.eps'],
    'image/svg+xml': ['.svg'],
    'application/pdf': ['.pdf'],
    'application/rtf': ['.rtf'],
  },
  openModalEditArticle:false,
  updateRemainingPixcoins: (newAmount) => set((state) => ({
    axCurrentDepartment: {
      ...state.axCurrentDepartment,
      remaining_pixcoins: newAmount,
    },
  })),
  // articleIdToEdit:0,
  axCurrentDepartmentDashData: {articles_count:0,files_count:0,pixme_stat:{quota:0}},
  setAxDepAccessData: (newAccessData) => set((state) => ({ axDepAccessData: {...newAccessData} })),
  // setSystemCurrentConfig: (newConfigData) => set((state) => ({ systemCurrentConfig: {...newConfigData} })),
  setSystemCurrentConfig: (newConfigData) => set((state) => ({
    systemCurrentConfig: { ...state.systemCurrentConfig, ...newConfigData }
  })),
  setAxDepartments: (newDeps) => set((state) => ({ axDepartments: {...newDeps} })),
  /* setAxCompanies: (newComps) => set((state) => ({ axCompanies: {...newComps} })), */
  setAxCompanies: (newComps) => set({ axCompanies: newComps }),
  setIsPixmeEmpty: (newPixmeEmptyStatus) => set({ isPixmeEmpty: newPixmeEmptyStatus }),
  setSystemAuthState: (newState) => set({ systemAuthState: newState }),
  setAxVillages: (newVillages) => set({ axVillages: newVillages }),
  setAxDepLastArticles: (newArticles) => set((state) => ({ axDepLastArticles: {...newArticles} })),
  setAxUserMenu: (newMenu) => set((state) => ({ axUserMenu: {...newMenu} })),
  setAxEventDepConfig: (newConf) => set((state) => ({ axEventDepConfig: {...newConf} })),
  setCloseModalEditArticle: () => set((state) => ({ openModalEditArticle: false })),
  setOpenModalEditArticle: (artid=-1) => set((state) => ({ openModalEditArticle: true,articleIdToEdit:artid })),
  setCurrentDepartment: (curDep) => set((state) => ({ axCurrentDepartment: curDep })),
  // setCurrentUser: (curUser) => set((state) => ( currentUser: curUser )),
  setCurrentUser: (curUser) => set((state) => ({ axCurrentUser: {...curUser} })),

  setCurrentDepartmentDashData: (curDepDashData) => set((state) => ({ axCurrentDepartmentDashData: curDepDashData })),
  setAxMotivational: (curMot) => set((state) => ({ axMotivational: curMot })),

  setIsUploading: (isUploadingStatus) => set((state) => ({ isUploading: isUploadingStatus })),


  // ********* selected files
  selectedFiles: loadFromLocalStorage('selectedFiles'),

  // Function to insert a file ID
  selectedFiles_insert: (fileId) => set((state) => {
    if (!state.selectedFiles.includes(fileId)) {
      const updatedFiles = [...state.selectedFiles, fileId];
      saveToLocalStorage('selectedFiles', updatedFiles);
      return { selectedFiles: updatedFiles };
    }
    return state;
  }),
  selectedFiles_insert_multiple: (fileIds) => set((state) => {
    const updatedFiles = [...new Set([...state.selectedFiles, ...fileIds])];
    saveToLocalStorage('selectedFiles', updatedFiles);
    return { selectedFiles: updatedFiles };
  }),
  // Function to delete a file ID
  selectedFiles_delete: (fileId) => set((state) => {
    const updatedFiles = state.selectedFiles.filter(id => id !== fileId);
    saveToLocalStorage('selectedFiles', updatedFiles);
    return { selectedFiles: updatedFiles };
  }),

  selectedFiles_delete_multiple: (fileIds) => set((state) => {
    const updatedFiles = state.selectedFiles.filter(id => !fileIds.includes(id));
    saveToLocalStorage('selectedFiles', updatedFiles);
    return { selectedFiles: updatedFiles };
  }),

  // Function to delete all file IDs
  selectedFiles_deleteAll: () => set(() => {
    saveToLocalStorage('selectedFiles', []);
    return { selectedFiles: [] };
  }),

  // Function to check if a file ID exists
  selectedFiles_exists: (fileId) => get().selectedFiles.includes(fileId)



}))

