import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {useAxStore} from "../store/axStore";
import {RHFTextField} from "../components/hook-form";
import Iconify from "../components/iconify";
import {useSnackbar} from "../components/snackbar";
import {useBoolean} from "../hooks/use-boolean";
import axios from "../utils/axios";


const UnlinkUserFromDepartment = ({open, setOpen, userId,depId,setNeedReloadDepEditData}) => {
  const { t, onChangeLang } = useTranslation()
  const axStore=useAxStore();
  const [menus,setMenus]=useState([])
  const [userName,setUserName]=useState('')
  const [departmentName,setDepartmentName]=useState('')
  const { enqueueSnackbar } = useSnackbar();
  const ax5DepUserIdEdit = useRef(0)


  useEffect(()=> {
    if(open) {
      // console.log('axuserdep modal opened, user',userId)

      let headers={}
      const jwt = localStorage.getItem('axjwt');
      if(jwt) {
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        const params = {
          'action': 'get_unlink_modal_info',
          'uid': userId,
          'depid': depId,
        }
        axios.get('https://api.administrix.pro/v1/get/', {
          headers,
          params
        })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;

              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

setUserName(data.user_name)
                setDefaultPage(data.settings.default_page)
setDepartmentName(data.department_name)
              }
            else
            if (response.data.error) {
              const {error} = response.data;
              // enqueueSnackbar(`Chyba pri pripojení na web ${value.label}:  ${error.title}`,{ variant: `error` });
            }



          }))
          .catch(error => {

            // console.log("any force_select_department fetch api error");
            // console.log(error.title);

            // setErr(error);
            // setIsUploadedFilesQueueProcessing(false)
            // return (final_user)
          })
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])
  const [selectedRole, setSelectedRole] = useState(2); //
  const [selectedMenu, setSelectedMenu] = useState(0); //
  const [defaultPage, setDefaultPage] = useState(''); //
  const [formData, setFormData] = useState({
    default_page: '',

  });

  const handleChangeMenu = (event) => {
    const { value } = event.target;
    setSelectedMenu(value);

    // You can also dispatch or call an API to save the selected role, if needed.
    // Example: saveUserRole(value);
  };
  const handleChangeRole = (event) => {
    const { value } = event.target;
    setSelectedRole(value);

    // You can also dispatch or call an API to save the selected role, if needed.
    // Example: saveUserRole(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDefaultPage(value)
  };

  const handleDisconnect = async () => {


    const jwt = localStorage.getItem('axjwt');
    if (jwt) {

      const postData = new FormData();
      // Object.keys(formData).forEach(key => postData.append(key, formData[key]));
      postData.append('action','unlink_user_from_dep')
      postData.append('uid',userId)
      postData.append('depid',depId)

//      postData.append('role',selectedRole)


      axios.post('https://api.administrix.pro/v1/post/',
        postData, {
          headers: {'Authorization': `Bearer ${jwt}`}
        })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

            if (data.success === true) {

                enqueueSnackbar("Succesfully disconected !", {variant: `success`, autoHideDuration: 6000});
                setNeedReloadDepEditData(1);
                setOpen(false)

              } else {
                enqueueSnackbar("Error :/", {variant: `error`, autoHideDuration: 6000});

              }


          } else if (response.data.error) {
//            const {error} = response.data;
            enqueueSnackbar(`Error : ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});

          }

        }))
        .catch(error => {
          console.log("any user disconeccting  fetch api error");

        })

    }


//    setOpen(false)
  };






  return (

    <Dialog open={open}  maxWidth="sm" fullWidth >
        <DialogTitle>
          {t('user.disconnect_user_from_department')}
          <IconButton
            aria-label="close"
             onClick={()=>setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
  <Grid sm={12}>

    <Typography variant="body">{t('user.do_you_really_want_to_disconnect_user_from_department')} <strong>{userName}</strong> {t('from')} <strong>{departmentName}</strong> ?
      <br/><br/>
      {t('user.user_will_not_be_deleted')} </Typography>


    <Button variant="contained" color="primary" onClick={handleDisconnect} fullWidth sx={{marginBottom:"20px",marginTop:"20px"}}>
        {t('user.disconnect')}
      </Button>



  </Grid>
          </DialogContentText>
        </DialogContent>

      </Dialog>
  );
};
UnlinkUserFromDepartment.propTypes = {
  open: PropTypes.bool,
  setOpen:PropTypes.func,
  setNeedReloadDepEditData:PropTypes.func,
  userId:PropTypes.number,
  depId:PropTypes.number

};
export default UnlinkUserFromDepartment;
