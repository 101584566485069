import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import {useAxStore} from "../../store/axStore";

// ----------------------------------------------------------------------

const Logo2 = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;
  const axStore=useAxStore();

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo2 = (
    <Box
      ref={ref}
      component="img"
      sx={{
        width:"200px",
        maxWidth:"90%",
        display: 'inline-flex',

        ...sx,
      }}
      {...other}
      src={axStore.systemCurrentConfig.logo2}

    />


  );

  if (disabledLink) {
    return logo2;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo2}
    </Link>
  );
});

Logo2.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo2;
