
import React, {useEffect, useState, useCallback, useMemo} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SendIcon from '@mui/icons-material/Send';
import LinkIcon from '@mui/icons-material/Link';
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import {Autocomplete, CircularProgress, ImageList, ImageListItem, ImageListItemBar, InputAdornment, LinearProgress, OutlinedInput, Tooltip, Typography} from "@mui/material";
import axios from "axios";
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import {Delete, Visibility, VisibilityOff} from "@mui/icons-material";
import InputIcon from '@mui/icons-material/Input';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import {enqueueSnackbar} from "notistack";

import Paper from "@mui/material/Paper";
import {m} from "framer-motion";
import Alert from "@mui/material/Alert";
import {useTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import {useAxStore} from "../store/axStore";
import Image from "../components/image";
import PixmeFolderItem from "../sections/pixme/view/pixme-folder-item";
import {useSettingsContext} from "../components/settings";
import Lightbox from "../components/lightbox";
import {fData} from "../utils/format-number";
import {MotionViewport, varFade} from "../components/animate";
import TextMaxLine from "../components/text-max-line";
import {useBoolean} from "../hooks/use-boolean";
import {useCopyToClipboard} from "../hooks/use-copy-to-clipboard";
import Iconify from "../components/iconify";
import TagFilesModal from "./pixTagSelectedModal";




export default function PixmeShowSelectedModal({setSelectedModalIsOpen,selectedModalIsOpen}) {

  /* useEffect(() => {
    console.log(' PixmeShowSelectedModal component re-rendered');
  }); */

  const axStore=useAxStore()
  const user=axStore.axCurrentUser;
  const {
    selectedFiles,
    selectedFiles_delete_multiple,
    selectedFiles_delete,
    selectedFiles_deleteAll
  } = useAxStore()
  const settings = useSettingsContext();
  const { t, onChangeLang } = useTranslation()
  const [curindex, setIndex] = useState(-1);
  const [curSignature, setCurSignature] = useState('');
  const [gotSendModalData, setGotSendModalData] = useState(0);
  const[tagListAutocompleteOptions,setTagListAutocompleteOptions]=useState( [])
  const [addNewTagIsOpen, setAddNewTagIsOpen] = useState(0);
  const [emailError, setEmailError] = useState('');
  const [autocompleteTagValue, setAutocompleteTagValue] = useState('');
  const [slides, setSlides] = useState([]);
  const [mergedTags, setMergedTags] = useState([]);
  const [intersectedTags, setIntersectedTags] = useState([]);
  // const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
  const showSelectedThumbnails=useBoolean(false)
  const needLoadWholeTags=useBoolean(false)
  const needLoadSelectedFilesTags=useBoolean(false)
  const getGridTemplateColumns = () => {
    if (settings.themeStretch) {
      // If themeStretch is true, use these settings
      return {
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(6, 1fr)',
        md: 'repeat(7, 1fr)',
        lg: 'repeat(9, 1fr)',
        xl: 'repeat(12, 1fr)',
      };
    }
    // If themeStretch is false, use different settings
    return {
      xs: 'repeat(1, 1fr)',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: 'repeat(4, 1fr)',
      xl: 'repeat(5, 1fr)',
    };

  };
  const [files, setFiles] = useState([]);
  const [langFiles, setLangFiles] = useState(t('files_nom'));
  const [langYouHaveSelected, setLangYouHaveSelected] = useState(t('px.you_have_selected'));

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i+=1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const removeImageFromSelected = (imageId) => {
    // console.log(`Remove image with ID: ${imageId}`);
    selectedFiles_delete(imageId)
    removeFileById(imageId)
    needLoadSelectedFilesTags.onTrue()
    // Implement your logic to remove the image from the array or database
  };
  const [actionOption, setActionOption] = useState(0);
  const [actionStatus, setActionStatus] = useState(1);// 1-ready, 2-in progress, 3-done-succ, 4-done-error
  const [statusMessage, setStatusMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const { copy } = useCopyToClipboard();
  const [linkValue, setLinkValue] = useState('');
  const [sharingLinkValue, setSharingLinkValue] = useState('');
  const [showWatermark, setShowWatermark] = useState(0);
  const [languages, setLanguages] = useState([])
  const [watermarks, setWatermarks] = useState([
    {
      "id": 0,
      "name":"neaplikovať watermark"
    }]);

  const [downloadCompleteValues, setDownloadCompleteValues] = useState({
    finalText:'',
    downloadLink:'',
    succEmails:'',
    failedEmails:'',
    immDwnldHasBegun:false
  })
  const [fileSizes, setfileSizes] = useState({
    filesize_1318:0,
    filesize_a4:0,
    filesize_full:0,
    filesize_preview:0
  })
  const [sharingValues, setSharingValues] = useState({
    sharingName: `Zdieľaná galéria ${generateRandomString(15)}`,
    // downloadSizeWeb: false,
    dwnld1318: true,
    dwnldA4: true,
    dwnldFull: false,
    applyWatermark: 0,
    allwDwnldFiles:false,
    dwnldEmail: '',
    sharingExpiration: '9999',
    dwnldPassword: '',
    showPassword: false,

    sendByEmailCheckbox:false,

    showPasswordInput:false,
  });

  const [values, setValues] = useState({
    downloadName: '',
    downloadSizeWeb: false,
    downloadSize1318: true,
    downloadSizeA4: false,
    downloadSizeMax: false,
    applyWatermark: 0,
    dwnldEmail: '',
    dwnldMessage:'',
    dwnldAddSignature:true,
    dwnldLang: axStore.axCurrentDepartment.lang_iso_code||"en",
    expiration: '14',
    dwnldPassword: '',
    showPassword: false,
    dwnldImmCheckbox:false,
    sendByEmail:false,
    zipFiles:false,
    showPasswordInput:false,
  });

  function forceDownload(href, filename) {
    const anchor = document.createElement("a");
    anchor.href = href;
    anchor.download = filename;
    // document.body.appendChild(anchor);
    anchor.click();
  }

  const handleClickOpen = () => {
    setSelectedModalIsOpen(true);
  };
  const handleClose = () => {
    setSelectedModalIsOpen(false);
    setGotSendModalData(0)
    // setActionStatus(1);
  };
  const onCopy = useCallback(
    (text) => {
      if (text) {
        enqueueSnackbar('Skopírované!');
        copy(text);
      }
    },
    [copy]
  );

  const getAllTags = () => {
    const jwt = localStorage.getItem('axjwt')
      let headers = {}
      headers = {
        'Authorization': `Bearer ${jwt}`
      }

      axios.get('https://api.administrix.pro/v1/get/pixme_search.php', {
        headers,
        params: {
          'action': 'get_all_tags_to_autocomplete',
          // 'files':selectedFiles,
           'user_id':user.id,
           'cur_dep_id':axStore.axCurrentDepartment.id
        }
      })
        .then((response => {
          if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
          if (response.data.data.tags) {
            setTagListAutocompleteOptions(response.data.data.tags);
          }

        }))
        .catch(error => {
          console.log("any get_all_tags fetch api error");

        })
    needLoadWholeTags.onFalse()

  }
  const getFilesTags = () => {
    const jwt = localStorage.getItem('axjwt')
      let headers = {}
      headers = {
        'Authorization': `Bearer ${jwt}`
      }

      axios.get('https://api.administrix.pro/v1/get/pixme_search.php', {
        headers,
        params: {
          'action': 'get_filetags_to_tags_modal',
           'files':selectedFiles,
           'user_id':user.id,
           'cur_dep_id':axStore.axCurrentDepartment.id
        }
      })
        .then((response => {
          if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
          if (response.data.data.all_files_tags)
            setMergedTags(response.data.data.all_files_tags);else setMergedTags([])

          if (response.data.data.intersected_tags)
            setIntersectedTags(response.data.data.intersected_tags);else setIntersectedTags([])



        }))
        .catch(error => {
          console.log("any get_files_tags fetch api error");

        })
    needLoadSelectedFilesTags.onFalse()

  }

  useEffect(() => {
    if(needLoadWholeTags.value) {
      // console.log('idem nacitat vsetky tagy')
      getAllTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[needLoadWholeTags.value])

  useEffect(() => {
    if(needLoadSelectedFilesTags.value) {
      // console.log('idem nacitat vsetky tagy')
      getFilesTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[needLoadSelectedFilesTags.value])

  useEffect(() => {
    if(selectedModalIsOpen) {
      if (((actionOption === 1) || (actionOption === 2))&&(gotSendModalData===0)) {
        getSendModalData();
        setCurSignature(`${user.displayName}, ${axStore.axCurrentDepartment.name}, ${user.email}`)
      }

    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[actionOption,selectedModalIsOpen])


  useEffect(() => {
    if(selectedModalIsOpen) {
      if(actionStatus!==2)setActionStatus(1)

      if (selectedFiles.length === 1) {
        setLangFiles(t('file_nom'));
        setLangYouHaveSelected(t('px.you_have_selected'));
      }else if (selectedFiles.length > 1 && selectedFiles.length < 5) {
        setLangFiles(t('files_nom'));
        setLangYouHaveSelected(t('px.you_have_selected_1'));
      }else if (selectedFiles.length > 4) {
        setLangYouHaveSelected(t('px.you_have_selected_2'));
        setLangFiles(t('files_gen'));
      }else if (selectedFiles.length === 0) {
        setLangYouHaveSelected(t('px.you_have_selected_2'));
        setLangFiles(t('files_gen'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedFiles.length,selectedModalIsOpen])

  useEffect(() => {
    const jwt = localStorage.getItem('axjwt')
    if (selectedModalIsOpen&&jwt&&showSelectedThumbnails.value) {
      let headers = {}
      headers = {
        'Authorization': `Bearer ${jwt}`
      }

      axios.get('https://api.administrix.pro/v1/get/pixme_search.php', {
        headers,
        params: {
          'action': 'get_selected_files',
          'files':selectedFiles,
          'user_id':user.id,
          'cur_dep_id':axStore.axCurrentDepartment.id
        }
      })
        .then((response => {

          // console.log('get_departments_for_move_modal')
          // console.log(response.data.data.departments)
          if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
          if (response.data.data.files) {
            setFiles(response.data.data.files);
            const formattedSlides = response.data.data.files.map(item => ({
              src: `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}${item.filename}`
            }));
            setSlides(formattedSlides);
          }

        }))
        .catch(error => {
          console.log("any get_selected_files fetch api error");

        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedModalIsOpen,showSelectedThumbnails.value])

  useEffect(() => {
    if(actionOption===1){
      setValues(prevValues => ({
        ...prevValues,
        dwnldImmCheckbox: true,
      }));
    }
    if(actionOption===2){

      setValues(prevValues => ({
        ...prevValues,
        dwnldImmCheckbox: false,
      }));

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[actionOption])


  const getSendModalData = () => {
    // setMovingStatus(2);
    if (selectedFiles.length) {
      const jwt = localStorage.getItem('axjwt')
      if (jwt) {
        let headers = {}
        headers = {
          'Authorization': `Bearer ${jwt}`
        }
        values.action = "get_send_modal_data"
        values.files = selectedFiles
        axios.get('https://api.administrix.pro/v1/get/index.php', {
          headers,
          params: values
        })
          .then((response => {
            // console.log(response)
            if (response.data.meta.jwt) localStorage.setItem('axjwt', response.data.meta.jwt);
            setGotSendModalData(1)
            if (response.data.data.download_name) {
              setValues({...values, 'downloadName': response.data.data.download_name});
            }
            if (response.data.data.watermarks) {
              const watermarks_from_api = response.data.data.watermarks;
              watermarks_from_api.unshift({
                "id": 0,
                "name": "neaplikovať watermark"
              })
              setWatermarks(response.data.data.watermarks)
            }
             if (response.data.data.languages) {
              const languages_from_api = response.data.data.languages;
              setLanguages(response.data.data.languages)
            }


            if (response.data.data.filesizes && response.data.data.filesizes[0]) {
              setfileSizes(response.data.data.filesizes[0]);

            }

            // setMovingStatus(1)
          }))
          .catch(error => {
            /*  console.log("any getSendModalData fetch api error");
              console.log(error);
              setActionStatus(4)

             */
          })


      } else {
        // setActionStatus(1)
      }
    }// else setActionStatus(0)
  }

  const removeFileById = (fileid) => {
    const updatedFiles = files.filter(file => file.fileid !== fileid);
    setFiles(updatedFiles);
  };
  const removeFileByIds = (fileIds) => {
    const updatedFiles = files.filter(file => !fileIds.includes(file.fileid));
    setFiles(updatedFiles);
  };

  const handleBlurEmail = () => {const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = values.dwnldEmail.split(',').map(email => email.trim());
    let hasInvalidEmail = false;
    let hasValidEmail = false;

    emails.forEach(email => {
      if (emailRegex.test(email)) {
        hasValidEmail = true;
      } else if (email.length > 0) {
        hasInvalidEmail = true;
      }
    });

    if (emails.length === 1 && emails[0] === '') {
      setEmailError('Nezadali ste žiadnu validnú emailovú adresu !');
    } else if (hasInvalidEmail) {
      setEmailError('');
    } else if (!hasValidEmail) {
      setEmailError('Nezadali ste žiadnu validnú emailovú adresu !!');
    } else {
      setEmailError('');
    }
  };
  const deleteFiles = () => {

    setActionStatus(2)
    setStatusMessage('Odstraňujeme súbory...')
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      values.files=selectedFiles
      values.action='delete_pixme_files'
      const form_data = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        form_data.append(key, value);
      });
      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              setActionStatus(3)

              setAlertMessage('Súbory sme úspešne odstránili z piXme.')
              setStatusMessage('')
              // console.log(selectedFiles)
              if(response.data.data.deletedFiles)
                selectedFiles_delete_multiple(response.data.data.deletedFiles)
              // removeFileByIds(response.data.data.deletedFiles)
              // console.log('got deleted:')
              // console.log(response.data.data.deletedFiles)

            }else{
              setActionStatus(4);
              setAlertMessage('Súbory sa nepodarilo odstrániť z piXme.')
            }
          }

        }))
        .catch(error => {
          if (error.response) {
//            setActionStatus(4)
//            setStatusMessage(t('px.we_are_sorry_an_error_occured'))
            /*
            if(error.response.status===401){ // unauthentificated

            };

            if(error.response.status===403){ // unauthorized to make request

            };

             */
            // console.log(error.response.data.error);
          }
          setActionStatus(4)
          setStatusMessage(t('px.we_are_sorry_an_error_occured'))
          // console.log("any createdownload fetch api error");
          // setSendingStatus(4);
          // setSendingStatus(1);
          // console.log(error);
          // return (final_user)
        })


    }


  }
  const sendPhotos = () => {

    setActionStatus(2)
    setStatusMessage(t('px.preparing_files_for_download'))
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      values.files=selectedFiles
      values.action='create_download'
      const form_data = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        form_data.append(key, value);
      });
      const addOrRewriteKey = (key, value) => {
        if (form_data.has(key)) {
          form_data.delete(key);
        }
        form_data.append(key, value);
      };
      if(actionOption===1)
      addOrRewriteKey("sendByEmail", false);
      if(actionOption===2)
        addOrRewriteKey("sendByEmail", true);


      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.sucess) {
            if(response.data.data.sucess===1){
              setActionStatus(3)
              if(values.dwnldImmCheckbox) {
                setAlertMessage(t('px.files_prepared_and_download_started'))
                setStatusMessage('')
              }
              else {
                setAlertMessage(t('px.download_is_preparing'))
              }
              // let statusText=`Súbory sme pripravili na stiahnutie: ${response.data.data.download_link}`;
              // if(response.data.data.sent_to)statusText+=` Poslali sme ich na emailové adresy ${response.data.data.sent_to}.<br />`
              let download_imm=false;
              if(response.data.data.download_imm) {
                const link_to_download_imm = `https://downloads.pixme.sk/${response.data.data.download_id}/${response.data.data.link_to_download_file}`;
                // statusText += ` Poslali sme ich na emailové adresy ${  response.data.data.sent_to  }.<br /><br />Začínam sťahovanie...`
                forceDownload(link_to_download_imm, response.data.data.link_to_download_file);
                download_imm=true;
              }
              setLinkValue(response.data.data.download_link)
              setDownloadCompleteValues({
                finalText:'',
                downloadLink:response.data.data.download_link,
                succEmails:response.data.data.sent_to,
                failedEmails:response.data.data.not_sent_to,
                immDwnldHasBegun:download_imm
              })

            }else{
              setActionStatus(4);
            }
          }

        }))
        .catch(error => {
          if (error.response) {
//            setActionStatus(4)
//            setStatusMessage(t('px.we_are_sorry_an_error_occured'))
            /*
            if(error.response.status===401){ // unauthentificated

            };

            if(error.response.status===403){ // unauthorized to make request

            };

             */
            // console.log(error.response.data.error);
          }
          setActionStatus(4)
          setStatusMessage(t('px.we_are_sorry_an_error_occured'))
          // console.log("any createdownload fetch api error");
          // setSendingStatus(4);
          // setSendingStatus(1);
          // console.log(error);
          // return (final_user)
        })


    }


  }
  const createSharing = () => {

    setActionStatus(2)
    setStatusMessage(t('px.sharing_is_being_created'))
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      // values.files=selectedFiles
      // values.action='create_sharing'
      // values.dwnldServr=piXmeDownloadApiUrl;
      const form_data = new FormData();
      /*
      for (const file of selectedPhotos){
        values.files.push(file.filename)
      } */
      Object.entries(sharingValues).forEach(([key, value]) => {
        form_data.append(key, value);
      });
      form_data.append('action', 'create_sharing');
      form_data.append('files', selectedFiles);
      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              setActionStatus(3)

              setAlertMessage(t('px.sharing_created_successfuly'))
              setStatusMessage('')


              setSharingLinkValue(response.data.data.download_link)
              /* setDownloadCompleteValues({
                finalText:'',
                downloadLink:response.data.data.download_link,
                succEmails:response.data.data.sent_to,
                failedEmails:response.data.data.not_sent_to,
                immDwnldHasBegun:download_imm
              }
              ) */

            }else{
              setActionStatus(4);
            }
          }

        }))
        .catch(error => {
          if (error.response) {
//            setActionStatus(4)
//            setStatusMessage(t('px.we_are_sorry_an_error_occured'))
            /*
            if(error.response.status===401){ // unauthentificated

            };

            if(error.response.status===403){ // unauthorized to make request

            };

             */
            // console.log(error.response.data.error);
          }
          setActionStatus(4)
          setStatusMessage(t('px.we_are_sorry_an_error_occured'))
          // console.log("any createdownload fetch api error");
          // setSendingStatus(4);
          // setSendingStatus(1);
          // console.log(error);
          // return (final_user)
        })


    }


  }


  const handleChange = (prop) => (event) => {
    /* if(prop==="downloadSizeWeb"||prop==="downloadSize1318"||prop==="downloadSizeA4"||prop==="downloadSizeMax"||prop==="dwnldImmCheckbox"||prop==="sendByEmailCheckbox"||prop==="zipFiles"||prop==="showPasswordInput") { */
    if(prop==="downloadSizeWeb"||prop==="downloadSize1318"||prop==="downloadSizeA4"||prop==="downloadSizeMax"||prop==="zipFiles"||prop==="showPasswordInput"||prop==="dwnldAddSignature") {
      setValues({...values, [prop]: event.target.checked});
    }
    else {
      setValues({...values, [prop]: event.target.value});
    }
    if (prop === 'dwnldEmail') {
      setEmailError('');
    }

  };

  const handleSharingChange = (prop) => (event) => {

    if(prop==="dwnld1318"||prop==="dwnldA4"||prop==="dwnldFull"||prop==="allwDwnldFiles") {
      setSharingValues({...sharingValues, [prop]: event.target.checked});
    }
    else {
      setSharingValues({...sharingValues, [prop]: event.target.value});
    }
    if (prop === 'dwnldEmail') {
      setEmailError('');
    }
    console.log(sharingValues.sharingName)
    console.log(sharingValues.sharingName.length)
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const renderDownloadLayout = (
    <>
      <CardHeader  title={`${actionOption === 1 ? t('px.download2') : t('px.send')} ${selectedFiles.length} ${langFiles}`}   sx={{textAlign:"center"}}/>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth  sx={{marginBottom:2}}>
              <TextField id="download_name_input"
                         label={t('px.download_name')}
                         variant="outlined"

                         value={values.downloadName}
                         onChange={handleChange('downloadName')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                {t('px.choose_resolution')}
              </Typography>

              <FormGroup sx={{marginBottom: 1}}>
                <FormControlLabel  control={<Checkbox onChange={handleChange('downloadSizeWeb')} checked={values.downloadSizeWeb} />} label={`${t('px.web_resolution')} (${fData(fileSizes.filesize_preview)})`} />

                <Collapse in={values.downloadSizeWeb}>
                  <FormControl fullWidth sx={{marginBottom: 2,marginTop:1}}>
                    <InputLabel id="apply_watermark_select_label">{t('px.select_watermark_for_preview')}</InputLabel>
                    <Select
                      labelId="apply_watermark_select_label"
                      id="apply_watermark_select"
                      name="apply_watermark_select"
                      value={values.applyWatermark}
                      label={t('px.select_watermark_for_preview')}
                      onChange={handleChange('applyWatermark')}

                    >
                      {watermarks.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                </Collapse>

                {fileSizes.filesize_1318>0&&<FormControlLabel  control={<Checkbox onChange={handleChange('downloadSize1318')} checked={values.downloadSize1318} />} label={`${t('px.web_resolution_13x18')}  (${fData(fileSizes.filesize_1318)})`} />}
                {fileSizes.filesize_a4>0&&<FormControlLabel  control={<Checkbox title="test" onChange={handleChange('downloadSizeA4')} checked={values.downloadSizeA4} />} label={`${t('px.a4_resolution')}  (${fData(fileSizes.filesize_a4)})`} />}
                {fileSizes.filesize_full>0&&<FormControlLabel  control={<Checkbox onChange={handleChange('downloadSizeMax')} checked={values.downloadSizeMax} />} label={`${t('px.max_resolution')}  (${fData(fileSizes.filesize_full)})`} />}


              </FormGroup>
            </Paper>
          </Grid>


          <Grid item xs={12} sm={6}>
            <Paper sx={{ padding: 2 }}>

              <Typography variant="subtitle2"
 sx={{marginBottom:3}}                         gutterBottom>
                {t('setting_for_email')}
              </Typography>

              {actionOption===2&&<>
                <FormControl fullWidth  >
                  {/* <FormControlLabel control={<Checkbox onChange={handleChange('sendByEmailCheckbox')} checked={values.sendByEmailCheckbox} />} label="Pošli link na stiahnutie emailom" /> */}

                    <TextField id="notification_input"
                               label={t('px.email_for_download_link')}
                               variant="outlined"
                               helperText={
                                 emailError || t('px.more_emails_divide_by_comma')
                               }
                               value={values.dwnldEmail}
                               onChange={handleChange('dwnldEmail')}
                               onBlur={handleBlurEmail}
                               sx={{marginBottom:2}}
                               fullWidth
                               error={!!emailError}
                    />

                </FormControl>
                <FormControl fullWidth  >
                  <TextField id="download_message_for_email_label"
                             label={t('download_message_for_email_label')}
                             variant="outlined"

                             value={values.dwnldMessage}
                             onChange={handleChange('dwnldMessage')}
                             sx={{marginBottom:1}}
                             fullWidth
                             multiline
                             rows={2}

                  />
                </FormControl>
                <FormControlLabel sx={{marginBottom: 2}} control={<Checkbox onChange={handleChange('dwnldAddSignature')} checked={values.dwnldAddSignature}/>} label={`${t('add_download_signature')} ${curSignature}`} />

                <FormGroup>
                  {/*  <FormControlLabel sx={{marginBottom: 2}} control={<Checkbox onChange={handleChange('dwnldImmCheckbox')}  checked={values.dwnldImmCheckbox} />} label="Stiahni hneď - súbory sa začnú sťahovať automaticky" />
*/}

                  {selectedFiles.length===1&&<FormControlLabel sx={{marginBottom: 2}} control={<Checkbox onChange={handleChange('zipFiles')} checked={values.zipFiles} />} label={t('px.compress_to_zip')} />}
                </FormGroup>



              </>
              }
              <FormControl fullWidth sx={{marginBottom: 2}}>
                <InputLabel id="expiration_select_label">{t('px.download_validity')}</InputLabel>
                <Select
                  labelId="expiration_select_label"
                  id="expiration_select"
                  name="expiration_select"
                  value={values.expiration}
                  label={t('px.download_validity')}
                  onChange={handleChange('expiration')}
                >
                  <MenuItem key="3" value="3">{t('px.3_days')}</MenuItem>
                  <MenuItem key="14" value="14">{t('px.14_days')}</MenuItem>
                  <MenuItem key="30" value="30">{t('px.30_days')}</MenuItem>
                  <MenuItem key="180" value="180">{t('px.6_months')}</MenuItem>
                  <MenuItem key="9999" value="9999" title="Tento download ">{t('px.permanent_download')}</MenuItem>
                </Select>
              </FormControl>
              {languages.length > 0 && (
              <FormControl fullWidth sx={{marginBottom: 2}}>
                <InputLabel id="lang_select_label">{t('download_lang')}</InputLabel>
                <Select
                  labelId="lang_select_label"
                  id="lang_select"
                  name="lang_select"
                  value={values.dwnldLang}
                  label={t('px.download_lang')}
                  onChange={handleChange('dwnldLang')}
                >
                  {languages.map((option) => (
                    <MenuItem key={option.iso_code2} value={option.iso_code2}>
                      {option.name_origin}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>)}

              {/* actionOption===2&&<FormGroup>
              <FormControl fullWidth variant="outlined">
                <FormControlLabel control={<Checkbox onChange={handleChange('showPasswordInput')} checked={values.showPasswordInput} />} label="Zabezpeč sťahovanie heslom" />
                <>
                  <Collapse in={values.showPasswordInput}>
                    <OutlinedInput
                      sx={{marginTop:1}}
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.dwnldPassword}

                      onChange={handleChange('dwnldPassword')}
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }

                    />
                  </Collapse>
                </>
              </FormControl>
            </FormGroup> */}

            </Paper>
          </Grid>
          <Grid item xs={12}>


            {((values.downloadSizeWeb||values.downloadSize1318||values.downloadSizeA4||values.downloadSizeMax)&&actionStatus===1&&actionOption===2)&&<Button color="primary" variant="contained" onClick={sendPhotos} startIcon={<SendIcon/>} fullWidth>{t('send')}</Button>}
            {((values.downloadSizeWeb||values.downloadSize1318||values.downloadSizeA4||values.downloadSizeMax)&&actionStatus===1&&actionOption===1)&&<Button variant="contained" color="primary" onClick={sendPhotos} startIcon={<DownloadIcon/>} fullWidth>{t('do_download')}</Button>}

          </Grid>
        </Grid>
      </Box>
    </>
  )

  const renderShareAsGallery = (
    <>
      <CardHeader  title={t('px.share_as_gallery')}   sx={{textAlign:"center"}}/>
      <div style={{padding:"10px",fontSize:"0.8em",textAlign:"center"}}>{t('px.share_as_gallery_desc')}</div>
      <Box
        component="form"

        noValidate
        autoComplete="off"
      >
        <div>

          <FormControl fullWidth sx={{marginBottom: 2}}>      <TextField
            id="outlined-helperText"
            defaultValue={sharingValues.sharingName}
            label={t('px.shared_gallery_name')}
            onChange={handleSharingChange('sharingName')}
            sx={{width: "100%"}}
          />
          </FormControl>

          <FormControl  fullWidth sx={{marginBottom: 4,paddingLeft:1 }}><FormGroup>
            <FormControlLabel control={<Checkbox onChange={handleSharingChange('allwDwnldFiles')} checked={sharingValues.allwDwnldFiles}/>} label={t('px.allow_download')} />
            {sharingValues.allwDwnldFiles&&<div style={{paddingLeft:"20px"}}>
              <FormControlLabel control={<Checkbox onChange={handleSharingChange('dwnld1318')} checked={sharingValues.dwnld1318} disabled/>} label={t('px.downloadable_resolution_1318')} />
              <FormControlLabel control={<Checkbox onChange={handleSharingChange('dwnldA4')} checked={sharingValues.dwnldA4}/>} label={t('px.downloadable_resolution_a4')} />
              <FormControlLabel control={<Checkbox onChange={handleSharingChange('dwnldFull')} checked={sharingValues.dwnldFull}/>}label={t('px.downloadable_resolution_max')} />


            </div>}
            {/*
            <FormControlLabel control={<Checkbox onChange={handleChange('allwPrintFiles')} checked={values.allwPrintFiles} />} label="Umožniť poslať do tlače" />
            <FormControlLabel control={<Checkbox onChange={handleChange('allwSendBack')} checked={values.allwSendBack} />} label="Umožniť poslať nám od užívateľa označené fotografie naspäť" />
            <FormControlLabel control={<Checkbox onChange={handleChange('unpublicFolder')} checked={values.unpublicFolder} />} label="Neverejná galéria" />
            */}
          </FormGroup></FormControl>




          <FormControl fullWidth sx={{marginBottom: 4}}>
            <InputLabel id="expiration_select_label">{t('px.shared_folder_validity')}</InputLabel>
            <Select
              labelId="expiration_select_label"
              id="expiration_select"
              name="expiration_select"
              value={sharingValues.sharingExpiration}
              label={t('expiration')}
              onChange={handleChange('sharingExpiration')}

            >
              <MenuItem key="3" value="3">{t('px.3_days')}</MenuItem>
              <MenuItem key="14" value="14">{t('px.14_days')}</MenuItem>
              <MenuItem key="30" value="30">{t('px.30_days')}</MenuItem>
              <MenuItem key="180" value="180">{t('px.6_months')}</MenuItem>
              <MenuItem key="9999" value="9999">{t('px.no_expiration')}</MenuItem>
            </Select>
          </FormControl>

          {((sharingValues.sharingName.length>0)&&actionStatus===1&&actionOption===3)&&<Button color="primary" variant="contained" onClick={createSharing} startIcon={<LinkIcon/>} fullWidth>{t('px.create_sharing')}</Button>}

        </div>
      </Box>
    </>
  )
  const renderCreateFolderFromSelected = (
    <>
      <CardHeader  title={t('px.create_folder_from_selected_files')}  sx={{textAlign:"center"}}/>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        Doesnt work yet:/
      </Box>
    </>
  )
  const renderDeleteFromPixme = (
    <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{display:"flex",justifyContent:"center"}}
      >
        <Button variant="contained" color="primary" onClick={()=>deleteFiles()} endIcon={<DeleteForeverIcon />}>
          {t('px.delete_selected_files')}
        </Button>
      </Box>
  )

  function handleDeleteTag(tid) {
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      const form_data = new FormData();
      form_data.append('tid', tid);
      form_data.append('files', selectedFiles);
      form_data.append('action', 'delete_tag_from_files');

      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              enqueueSnackbar(t('px.tag_successfuly_deleted'), { variant: 'success' });
              needLoadWholeTags.onTrue()
              needLoadSelectedFilesTags.onTrue()
            }else{
              enqueueSnackbar('Any error :/', { variant: 'error' });
              // console.log(error);
            }
          }else{enqueueSnackbar('Any error :/', { variant: 'error' });}

        }))
        .catch(error => {

          enqueueSnackbar('Server error at tag deletion!', { variant: 'error' });
          console.log(error);
        })


    }

  }

  const renderChipsFromTagArray = (tags = [], forceNotToDelete = false) => (
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
        {tags?.map((tag, index) => {
          let shouldRenderOnDelete = forceNotToDelete;

          if (!forceNotToDelete) {
            shouldRenderOnDelete = user.ax_role < 1 || user.department_role < 3;
          } else {
            shouldRenderOnDelete = false;
          }

          return (
            <Chip
              key={tag.id} // Use tag.id as the key
              label={tag.name.trim()} // Use tag.name for the label
              variant="outlined"
              {...(shouldRenderOnDelete && { onDelete: () => handleDeleteTag(tag.id) })} // Use tag.id for the onDelete handler
            />
          );
        })}
      </Stack>
    );


  function assignTag() {
// console.log('idem priradit tag ',autocompleteTagValue)
// console.log('suborom ',selectedFiles)


    setActionStatus(2)
    setStatusMessage(t('px.assigning_tag'))
    const jwt=localStorage.getItem('axjwt')
    if(jwt){
      const form_data = new FormData();
      form_data.append('files', selectedFiles);
      form_data.append('action', 'assign_tag');
      form_data.append('tag_name', autocompleteTagValue);

      axios.post('https://api.administrix.pro/v1/post/index.php',form_data,{
        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${  jwt}`}
      } )
        .then((response => {

          if(response.data.meta.jwt)
            localStorage.setItem('axjwt', response.data.meta.jwt);
          if(response.data.data.success) {
            if(response.data.data.success===1){
              setActionStatus(1)

              // setAlertMessage('Tag sme úspešne priradili.')
              // setStatusMessage('')
              enqueueSnackbar(t('px.tag_successfuly_assigned'));
              needLoadWholeTags.onTrue()
              needLoadSelectedFilesTags.onTrue()
            }else{
              setActionStatus(1);
              // setAlertMessage('Tag sa nepodarilo priradiť.')
              enqueueSnackbar(t('px.tag_assigning_failed'));
            }
          }

        }))
        .catch(error => {
          if (error.response) {
//            setActionStatus(4)
//            setStatusMessage(t('px.we_are_sorry_an_error_occured'))
            /*
            if(error.response.status===401){ // unauthentificated

            };

            if(error.response.status===403){ // unauthorized to make request

            };

             */
            // console.log(error.response.data.error);
          }
          setActionStatus(4)
          setStatusMessage(t('px.we_are_sorry_an_error_occured'))
        })


    }


  }
  const cssTag='.MuiAutocomplete-noOptions {display:none}'
  const handleOptionFileTagChange = (event,value, reason) => {
    // console.log("onchange")
    // console.log(event)
    // console.log(value)
    // console.log(reason)
    setAutocompleteTagValue( value)
    // if (reason === 'clear') {}
    // if (reason === 'clear') {}
    if (reason === 'selectOption') {
      // alert('vybrali ste '+value.label+' a idem smerovat do '+value.id);
      //   Router.push('/gallery/?slug='+value.id);
      // setToGall(value.id);
      // setToGallName(value.label);
      // setAutocompleteTagValue( value)
      // setGallValue({"label":value.label,"id":value.id})
    }
  };
  const renderManageTags = (
    <>
    <CardHeader  title={t('px.manage_tags_of_selected')}   sx={{textAlign:"center",marginBottom:"15px"}}/>

    <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{display:"flex",justifyContent:"center"}}
      >

      {!addNewTagIsOpen&&<Button variant="outlined" color="primary" endIcon={<AddIcon />} onClick={()=>{setAutocompleteTagValue('');setAddNewTagIsOpen(!addNewTagIsOpen)}}>{t('px.add_new_tag_to_selected_files')}</Button>}
      {!!addNewTagIsOpen&&(<>
        <style>{cssTag}</style>
        <Grid container  sx={{display:"flex",justifyContent:"center"}}>
          <Grid item xs={12}>
        <Autocomplete
          freeSolo
          fullWidth
          size="small"
          disablePortal
          id="galleryListComboBox"
          options={tagListAutocompleteOptions}
          autoComplete
          value={autocompleteTagValue}
          renderInput={(params) => <TextField {...params} label={t('px.add_tag_select_or_create_new')
          }  />}
          onInputChange={handleOptionFileTagChange}

          onKeyDown={e => {
            // console.log(e)
            if (e.code.toLowerCase() === 'enter') {
              // console.log("on enter 0")
              // console.log(e)
              // console.log(e.target.defaultValue)
              // setAutocompleteTagValue(autocompleteTagValue.concat(e.target.defaultValue));
              // setAutocompleteTagValue(e.target.defaultValue);
              // console.log("enter")
              // handleEnterAutocompleteInputValue(e.target.defaultValue)
            }}}

        />
          </Grid>
          <Grid item xs={12} md={6}>
        {autocompleteTagValue&&<div><Button sx={{marginTop:"10px"}}  fullWidth small="true" color="primary" variant="contained" size="small" startIcon={<AddIcon />} onClick={()=>{
          assignTag()}}>{t('px.assign_tag_to_files')}: {autocompleteTagValue}</Button></div>}
          </Grid>
          </Grid>
      </>)}


      </Box>

    <Box>
      <Grid container  sx={{display:"flex",justifyContent:"center",padding:"20px"}}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{textAlign:"center"}}>{t('px.common_tags')}</Typography>
        </Grid>

        { renderChipsFromTagArray(intersectedTags) }

      </Grid>
    </Box>

    <Box>
      <Grid container  sx={{display:"flex",justifyContent:"center",padding:"20px"}}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{textAlign:"center"}}>{t('px.at_least_one_file_tags')}</Typography>
        </Grid>
        { renderChipsFromTagArray(mergedTags) }
      </Grid>
    </Box>

    </>
  )
  const renderDeselect = (
    <Box
        component="form"
        noValidate
        sx={{display:"flex",justifyContent:"center"}}

      >
        <Button variant="contained" color="primary" onClick={()=>selectedFiles_deleteAll()}> {t('px.deselect_all_selected_files')}</Button>
      </Box>
  )



  function CardDesktop({ category }) {
    const { label, icon,option } = category;

    return (
      <Paper
        variant="outlined"
        sx={{
          p: 3,
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          textAlign: 'center',
          '&:hover': {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          },
        }}
        onClick={()=>{
          if(option===7&&actionOption!==7) { // tags
            needLoadWholeTags.onTrue()
            needLoadSelectedFilesTags.onTrue()
          }

          if(actionStatus!==2){
              setActionOption(option);
              setActionStatus(1)
          }}

      }
      >
        <img

          alt={icon}
          src={`${axStore.systemCurrentConfig.s3.s3_endpoint_system}system/${icon}`}
          style={{ mb: 2, width: 80, height: 80, mx: 'auto' }}

        />

        <TextMaxLine variant="subtitle2" persistent>
          {label}
        </TextMaxLine>
      </Paper>
    );
  }
  CardDesktop.propTypes = {
    category: PropTypes.object,
  };
  const CATEGORIES = [
    // https://www.freepik.com/icon/folder_7875448#fromView=resource_detail&position=10
    {
      label: t("download1"),
      icon: 'sel_files_download.png',
      href: '#',
      option: 1,
      maxRole:3
    },
    {
      label: t("px.send_to_download"),
      icon: 'sel_files_send.png',
      option: 2,
      maxRole:3
    },
    {
      label: t("px.share_as_gallery"),
      icon: 'sel_files_gallery.png',
      option: 3,
      maxRole:3
    },    {
      label: t("tagy"),
      icon: 'sel_files_gallery.png',
      option: 7,
      maxRole:3
    },
    {
      label: t("px.create_folder_from_files"),
      icon: 'sel_files_copy_to_folder.png',
      option: 4,
      maxRole:2
    },
    {
      label: t('px.delete_from_pixme'),
      icon: 'sel_files_delete.png',
      option: 5,
      maxRole:1
    },
    {
      label: t('deselect'),
      icon: 'sel_files_deselect.png',
      option: 6,
      maxRole:10
    },
  ];
  const mappedCategories = useMemo(() => CATEGORIES.map((category) => {

      // eslint-disable-next-line react/prop-types
      if ((user.ax_role < 2) || (user.department_role <= category.maxRole)) {
        return (
          // eslint-disable-next-line react/prop-types
          <CardDesktop category={category} key={category.label} />
        );
      }
      return null;


// eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
  return (
    <>
      {/*
      <TagFilesModal tagFilesModalIsOpen={tagModalIsOpen} setTagFilesModalIsOpen={setTagModalIsOpen} selectedFiles={selectedFiles}/>
      */}
      <Dialog open={selectedModalIsOpen} onClose={handleClose}   fullWidth
              maxWidth="xl">
        <DialogTitle>{langYouHaveSelected} {`${selectedFiles.length} ${langFiles}`}</DialogTitle>

        <DialogContent>

          {!!selectedFiles.length&&<Box
            component={MotionViewport}
            gap={3}
            display="grid"
            gridTemplateColumns={{
              md: 'repeat(3, 1fr)',
              lg: 'repeat(7, 1fr)',
            }}
          >
            {mappedCategories}

          </Box>}

          {actionOption>0&&
            <Stack spacing={5} >
              <Card sx={{p:5}}>
                {actionStatus===1&&  // ready
                  <>
                    {(actionOption===1||actionOption===2)&&renderDownloadLayout}
                    {actionOption === 3 && renderShareAsGallery}
                    {actionOption === 4 && renderCreateFolderFromSelected}
                    {actionOption === 5 && renderDeleteFromPixme}
                    {(actionOption === 6 && selectedFiles.length)&&renderDeselect}
                    {(actionOption === 7 && selectedFiles.length)&&renderManageTags}
                  </>}

                {actionStatus===2&& // in progress
                  <>
                    <LinearProgress/>
                    <Typography variant="caption">{statusMessage}</Typography>

                  </>
                }

                {actionStatus===3&& // done succ
                  <>
                    <Alert severity="success">{alertMessage}</Alert>

                    {(actionOption===1||actionOption===2)&&
                      <Stack spacing={2} sx={{mt:2,p:2}}>
                        <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                          {t('px.link_to_download')}:
                        </Typography>

                        <TextField
                          fullWidth
                          value={linkValue}
                          readOnly
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title={t('copy')}>
                                  <IconButton onClick={() => onCopy(linkValue)}>
                                    <Iconify icon="eva:copy-fill" width={24} />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>}

                    {(actionOption===3||actionOption===3)&&
                      <Stack spacing={2} sx={{mt:2,p:2}}>
                        <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                          {t('px.link_to_share')}:
                        </Typography>

                        <TextField
                          fullWidth
                          value={sharingLinkValue}
                          readOnly
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title={t('copy')}>
                                  <IconButton onClick={() => onCopy(sharingLinkValue)}>
                                    <Iconify icon="eva:copy-fill" width={24} />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>}

                  </>
                }


                {actionStatus===4&& // done error
                  <>
                    <Alert severity="error">{alertMessage}</Alert>
                    {statusMessage}
                  </>
                }


              </Card>
            </Stack>
          }
          {!!selectedFiles.length&&
            <Stack  sx={{display:"flex",justifyContent:"center",p:2}}>

              {showSelectedThumbnails.value?
                <Button variant="outlined" onClick={()=>showSelectedThumbnails.onFalse()} endIcon={<ExpandLessIcon />}>
                  {t('px.hide_selected_files')}
                </Button>
                :
                <Button variant="outlined" onClick={()=>showSelectedThumbnails.onTrue()} endIcon={<ExpandMoreIcon />}>
                  {t('px.show_selected_files')}
                </Button>}
            </Stack>}

          {showSelectedThumbnails.value&&
            <Stack spacing={5}>
              <Card>
                <CardHeader title={`${t("px.selected_files")} (${selectedFiles.length})`} />
                <Box
                  gap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(4, 1fr)',
                    md: 'repeat(6, 1fr)',
                  }}
                  sx={{ p: 3 }}
                >
                  {files.map((image,idx) => (
                    <Box sx={{position:"relative"}}>
                      <Image
                        key={image.fileid}
                        alt={image.ratio}
                        src={`${axStore.systemCurrentConfig.s3.s3_endpoint_preview}t_${image.filename}`}
                        sx={{ borderRadius: 2,cursor:"pointer" }}
                        onClick={()=>setIndex(idx)}
                        ratio="1/1"
                      />


                      <IconButton aria-label="delete" onClick={() => removeImageFromSelected(image.fileid)}
                                  style={{ position: 'absolute', top: 5, right: 5,backgroundColor: 'rgba(0, 0, 0, 0.4)',color:"white"  }}>
                        <Tooltip title={t("px.remove_from_selection")}>
                          <ClearIcon/>
                        </Tooltip>

                      </IconButton>

                    </Box>
                  ))}
                </Box>
              </Card>


            </Stack>
          }

        </DialogContent>

        <DialogActions>

          <Button onClick={handleClose} startIcon={<CloseIcon/>}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
      <Lightbox
        slides={slides}
        open={curindex >= 0}
        index={curindex}
        close={() => setIndex(-1)}
        disabledThumbnails

      />
    </>

  );
}


PixmeShowSelectedModal.propTypes = {
  setSelectedModalIsOpen: PropTypes.func,
  selectedModalIsOpen: PropTypes.bool,
};
