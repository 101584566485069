import { Helmet } from 'react-helmet-async';
// sections
import { AboutView } from 'src/sections/about/view';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import {CircularProgress, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import StorageIcon from '@mui/icons-material/Storage';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Alert from "@mui/material/Alert";
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import MenuItem from "@mui/material/MenuItem";
import {enqueueSnackbar} from "notistack";
import {useRouter} from "../routes/hooks";
import {fData} from "../utils/format-number";
import PixmeFilesGridView from "../sections/pixme/pixme-files-grid-view";
import Image from "../components/image";
import Lightbox from "../components/lightbox";

import Iconify from "../components/iconify";
import ImageInSharing from "../components/imageInSharing/imageInSharing";

const useQuery = () => new URLSearchParams(useLocation().search);
// ----------------------------------------------------------------------

async function forceDownload(imageSrc, nameOfDownload = 'my-image.png') {
  try {
    // Fetch the image
    const response = await fetch(imageSrc);
    const blobImage = await response.blob();

    // Create a URL for the image
    const imageUrl = URL.createObjectURL(blobImage);

    // Create an anchor element
    const anchorElement = document.createElement('a');
    anchorElement.href = imageUrl;
    anchorElement.download = nameOfDownload;

    // Append the anchor element to the DOM
    document.body.appendChild(anchorElement);

    // Trigger a click event to initiate the download
    anchorElement.click();
  } catch (error) {
    console.error('Error downloading image:', error);
  }
}
export default function SharePage() {

  const [files, setFiles] = useState(null);
  const [filesLiked, setFilesLiked] = useState([]);
  const [curindex, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [sharingMeta, setSharingMeta] = useState(null);
  const[isLoading,setIsLoading]=useState(true) // getting download data
  const [downLoadingStatus, setDownLoadingStatus] = useState(0);// 0 - not downloading, 1-downloading has begin succesfuly, 2-downloading didn't started beacuse any problem on server side
  const [gettingPreDownloadData, setGettingPreDownloadData] = useState(false);
  const query = useQuery();
  // const did = query.get('did');
  const fid = query.get('fid'); // folder id
  const shid = query.get('shid'); // shareid id
  const cls = query.get('cls'); // columns
  const vt = query.get('vt'); // view typ
  const [sizeId, setSizeId] = useState(null);
  const handleSetSizeId = (newSizeId) => {
    setSizeId(newSizeId);
  };

  // const auth = query.get('auth');
  const handleDownload = (fileid, filesize) => {
    //  filesie: 1=preview, 2=1318, 3=a4, 4=full
    if(!filesize){
      enqueueSnackbar(`filesize not set ...`);return
    }
    enqueueSnackbar(`Downloading ...`);
    const headers={}

      const params = {
        'action': 'get_pixme_file_download_link_from_sharing',
        'fileid': fileid,
        'dwnld_size': filesize
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            // if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if (data.file_link) {
              forceDownload(data.file_link,`${data.format}_${data.file_name}`)
            }
          }else
          if (response.data.error) {
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri sťahovaní súboru:  ${error.title}`,{ variant: `error` });
          }



        }))
        .catch(error => {
          enqueueSnackbar(`fckn error ...`);
          // console.log("any force_select_department fetch api error");
          // console.log(error.title);

          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
        })

  };


  useEffect(() => {
    setIsLoading(true);
// console.log('share useeff')
// console.log('fid:',fid)
// console.log('shid:',shid)
      if(fid||shid) {
        axios.get('https://api.administrix.pro/v1/get/pixme_search.php', {
          params: {'action': 'get_pixme_sharing', 'fid': fid, 'shid': shid, 'cls':cls, 'vt':vt,'user_id':-1,'cur_dep_id':-1}
        })
          .then((response => {
        // console.log(response.data.data.files)
            setFiles(response.data.data.files)
            setSharingMeta(response.data.data.sharing_meta)

            const formattedSlides = response.data.data.files.map(item => ({
              src: `${response.data.data.sharing_meta.s3_endpoint_preview}${item.filename}`
            }));
            setSlides(formattedSlides);

          }))
          .catch(error => {

          })
      }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fid,shid])

  if (files) {

    return(
      <>
      <Helmet>
        <title> Fotografie</title>
      </Helmet>
          <Container style={{marginTop: 40, textAlign: "center"}}>
            <Stack spacing={5}>
              <Card>
                <CardHeader title={sharingMeta.name} />
                {`${files.length} súborov`}
                <Box
                  gap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(4, 1fr)',
                    md: 'repeat(6, 1fr)',
                  }}
                  sx={{ p: 3 }}
                >
              {files.map((image,idx) => (
                  <ImageInSharing
                    image={image}
                    idx={idx}
                    sharingMeta={sharingMeta}
                    setIndex={()=>setIndex(idx)}
                    // handleDownload={()=>handleDownload(image.fileid,sizeId)} // aktualne nefunguje to, ze ked sa vyberie v <ImageInSharing velksto stiahnutia, tak sa neprenesie do handleDownload. A tiez nastavit allowDownload velkosti z url

                    handleDownload={handleDownload} // aktualne nefunguje to, ze ked sa vyberie v <ImageInSharing velksto stiahnutia, tak sa neprenesie do handleDownload. A tiez nastavit allowDownload velkosti z url
                    fData={fData}
                    key={idx}
                    setFilesLiked={setFilesLiked}
                    filesLiked={filesLiked}
                    setSizeId={handleSetSizeId}
                    // allowDownload={[1,3]}  // 1= 1318, 2=a4, 3=full
                    // allowDownload={sharingMeta.allowDownload}  // 1= 1318, 2=a4, 3=full

                  />
                ))}
                </Box>
              </Card>

            </Stack>
          </Container>
        <Lightbox
          slides={slides}
          open={curindex >= 0}
          index={curindex}
          close={() => setIndex(-1)}
          disabledThumbnails

        />



        </>
    )
  }
  if (!files) {

    return(
      <>
      <Helmet>
        <title> Fotografie</title>
      </Helmet>
          <Container style={{marginTop: 40, textAlign: "center"}}>
            <div>
              {isLoading?<CircularProgress />:<Alert severity="error">Je nám ľúto, nenašli sme žiadne súbory.</Alert>}
            </div>
          </Container>
        </>
    )
  }







}
