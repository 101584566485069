import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../utils/axios";
import {useSnackbar} from "../../components/snackbar";

// eslint-disable-next-line react/prop-types
function ConfirmCompanyDelete({ compId, compName,deleteCompConfirmModalIsOpen,setDeleteCompConfirmModalIsOpen,handleCloseModal }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = () => {
    if(!isDeleting&&compId) {
      setIsDeleting(true)
      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'delete_company');
        postValues.append('compid', compId);
        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

              if (data.success === 1) {
                enqueueSnackbar("Firmu sme úspešne odstránili.", {variant: `success`, autoHideDuration: 6000});
                handleCloseModal()
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri odstraňovaní departmentu: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }
            setIsDeleting(false)

          }))
          .catch(error => {
            console.log("any deleteting comp fetch api error");
            setIsDeleting(false)
          })
      }
      setDeleteCompConfirmModalIsOpen(false)
    }
  };

  return (
    <Dialog open={deleteCompConfirmModalIsOpen}>
      <DialogTitle>Vymazanie firmy {compName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Z databázy departmentu odstránime záznamy pre túto firmu: Informácie o firme, adresy a kontakty<br /><br />
          Skutočne chcete vymazať túto firmu? ?<br />

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="primary">
          Áno
        </Button>
        <Button onClick={()=>setDeleteCompConfirmModalIsOpen(false)} color="primary">
          Nie
        </Button>

      </DialogActions>
    </Dialog>
  );
}

export default ConfirmCompanyDelete;
